/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useFormatMessage } from 'hooks';
import { checkAccess } from 'permissions';
import { getSynonyms, setSynonyms } from 'state/actions/settings';
import ClipLoader from 'react-spinners/ClipLoader';
import './SynonymsForm.scss';

const SynonymsForm = () => {
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  const defaultValues = { synonyms: '' };
  const { register, handleSubmit, setValue} = useForm({
    defaultValues
  });

  useEffect(() => {
    const fetchSynonymsData = async () => {
      setLoading(true);
      const synonymsResponse = await getSynonyms();
      if(synonymsResponse.data.data){
        setValue('synonyms', synonymsResponse.data.data);
      }
      setLoading(false);
    };
    fetchSynonymsData();
  }, []);


  const AccessToEditSettings = checkAccess('edit settings');

  const onSubmitHandler = async(value) => {
    const { synonyms } = value;
    setSaving(true);
    await setSynonyms(synonyms);
    setSaving(false);
  };

  return (
    <>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <div className="card tile is-child">
            <div className="card-content">
              <form onSubmit={handleSubmit(onSubmitHandler)}>
                <div className="mb-6">
                  <h3 className="title is-6">{useFormatMessage(`Settings.synonyms` )}</h3>
                
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        {useFormatMessage(`Settings.text` )}
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          {loading &&
                            <div className="loader-container">
                              <ClipLoader />
                            </div>
                          }
                          
                          <textarea
                            className="textarea" 
                            name="synonyms"
                            id="synonyms"
                            ref={register}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <hr />
                { AccessToEditSettings && 
                  <div className="field is-horizontal">
                    <div className="field-label" />
                    <div className="field-body">
                      <div className="field">
                        <div className="field is-grouped">
                          <div className="control">
                            <button
                              type="submit"
                              disabled={saving}
                              className={`button blue-button ${
                                saving && 'is-loading'
                              }`}
                            >
                              <span>{useFormatMessage('Settings.submit')}</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SynonymsForm;