import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Loader from 'components/Loader';
import { checkAccess } from 'permissions';
import { useFormatMessage } from 'hooks';
import { convertDateZeroHour } from 'utils';
import moment from 'moment';
import Table from 'components/Table';
import { deleteCoupon } from 'state/actions/coupons';
import DatePicker from 'react-datepicker';
import ConfirmationModal from 'components/ConfirmationModal';
import classes from './CouponList.module.scss';

const CouponList = ({coupons}) => {
  const { error, loading, deleted, usersCoupons} = useSelector(
    (state) => ({
      error: state.coupons.error,
      loading: state.coupons.loading,
      deleted: state.coupons.deleted,
      usersCoupons: state.coupons.usersCoupons,
    }),
    shallowEqual
  );

  const [filterStartDate, setFilterStartDate] = useState();
  const [filterEndDate, setFilterEndDate] = useState();
  
  const onChangeStartDate = (value) => {
    const startDate = value ? new Date(value) : '';
    setFilterStartDate(startDate);
  };

  const onChangeEndDate = (value) => {
    const endDate = value ? new Date(value) : '';
    setFilterEndDate(endDate);
  };

  const [deleteModal, setDeleteModal] = useState({
    couponId: null,
    isOpen: false,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        couponId: null,
        isOpen: !prevState.isOpen,
      }));
    }
  }, [deleted, loading]);


  const onRemoveButtonClickHandler = (couponId) => {
    setDeleteModal((prevState) => ({
      couponId,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ couponId: null, isOpen: false });
  };

  const onDeleteCouponHandler = () => {
    dispatch(deleteCoupon(deleteModal.couponId));
  };

  const columns = [
    {
      Header: useFormatMessage('Coupons.name'),
      accessor: 'name',
      Cell: ({ row }) => (
        <>
          { checkAccess('view coupon') ? (
            <Link to={`/coupons/${row.original.id}`}>
              { row.original.name }
            </Link>
            ) : row.original.name 
          }
        </>
      )
    },
    {
      Header: useFormatMessage('Coupons.usedCount'),
      accessor: 'usedCount',
    },
    {
      Header: useFormatMessage('Coupons.startDate'),
      accessor: 'startDate',
      sortType: (a, b) => {
        return new Date(b.values.startDate) - new Date(a.values.startDate);
      },
      Cell: ({ row }) => (
        <>
          {moment(row.original.startDate).format("DD.MM.YYYY")}
        </>
      ),
    },
    {
      Header: useFormatMessage('Coupons.endDate'),
      accessor: 'endDate',
      sortType: (a, b) => {
        return new Date(b.values.startDate) - new Date(a.values.startDate);
      },
      Cell: ({ row }) => (
        <>
          {moment(row.original.endDate).format("DD.MM.YYYY")}
        </>
      ),
    },
  ];

  const actionCell = {
    Header: 'Actions',
    id: 'actions',
    accessor: 'id',
    Cell: ({ row }) => (
      <>
        <div className="buttons is-right">
          <Link
            to={`/coupons/${row.original.id}`}
            className="button is-small is-primary"
          >
            <span className="icon is-small">
              <i className="mdi mdi-pencil" />
            </span>
          </Link>

          {checkAccess(`delete coupon`) && (
            <button
              type="button"
              className="button is-small is-danger"
              onClick={() => onRemoveButtonClickHandler(row.original.id)}
            >
              <span className="icon is-small">
                <i className="mdi mdi-trash-can" />
              </span>
            </button>
          )}
        </div>
      </>
    ),
    disableSortBy: true,
  };

  if (checkAccess('view coupon')) columns.push(actionCell);

  const deleteMessage = useFormatMessage('Coupons.delete');
  const confirmMessage = useFormatMessage('Coupons.confirm');
  const permDeleteMessage = useFormatMessage('Coupons.permDelete');
  const cancelMessage = useFormatMessage('Coupons.cancel');
  const title = useFormatMessage('Coupons.coupons');

  let data = filterStartDate
  ? coupons.map((el) => {
      const count = usersCoupons.reduce((activationsSum, userActivations) => {
        const countByUser = userActivations.coupons.reduce((userActivationCount, activation) => {
          if(activation.couponId === el.id && convertDateZeroHour(filterStartDate) <= convertDateZeroHour(activation.activateDate)){
            if(filterEndDate){
              if(convertDateZeroHour(filterEndDate) >= convertDateZeroHour(activation.activateDate)){
                return userActivationCount + 1;
              }
            }
            else{
              return userActivationCount + 1;
            }
          }

          return userActivationCount;
        }, 0);

        return activationsSum + countByUser;
      }, 0);

      return ({
        ...el,
        usedCount: count
      });
    })
  : coupons;

  data = filterEndDate
  ? data.map((el) => {
      const count = usersCoupons.reduce((activationsSum, userActivations) => {
        const countByUser = userActivations.coupons.reduce((userActivationCount, activation) => {
          if(activation.couponId === el.id && convertDateZeroHour(filterEndDate) >= convertDateZeroHour(activation.activateDate))
            if(filterStartDate){
              if(convertDateZeroHour(filterStartDate) <= convertDateZeroHour(activation.activateDate)){
                return userActivationCount + 1;
              }
            }
            else{
              return userActivationCount + 1;
            }

          return userActivationCount;
        }, 0);

        return activationsSum + countByUser;
      }, 0);

      return ({
        ...el,
        usedCount: count
      });
    })
  : data;

  const activationsCount = data.reduce((partialSum, a) => partialSum + a.usedCount, 0);

  return (
    <>
      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={loading}
          confirmButtonMessage={deleteMessage}
          title={confirmMessage}
          body={permDeleteMessage}
          cancelButtonMessage={cancelMessage}
          onConfirmation={onDeleteCouponHandler}
          onCancel={onCloseModalHandler}
        />
      )}

      <section className="tile is-ancestor">
        <div className="tile is-parent">
          <div className="card has-table has-mobile-sort-spaced tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-ticket default" />
                </span>
                {title}
              </p>

              <div className='card-header-icon has-text-weight-normal'>
                <div className="field is-horizontal">
                  <div className="field-body">

                    <div className="field is-horizontal is-align-items-center">
                      <div className="field-body">
                        <div className="control">
                          <DatePicker
                            id="start-date"
                            name='filterStartDate'
                            onChange={value => onChangeStartDate(value)}
                            selected={filterStartDate}
                            placeholderText="Start Date"
                            className="input"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal is-align-items-center">
                      <div className="field-body">
                        <div className="control">
                          <DatePicker
                            id="end-date"
                            name='filterEndDate'
                            onChange={value => onChangeEndDate(value)}
                            selected={filterEndDate}
                            placeholderText="End Date"
                            className="input"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal is-align-items-center">
                      <div className="field-body">
                        <p className={classNames('control', classes.textNoBreak)}>{`Count: ${ activationsCount }`}</p>
                      </div>
                    </div>

                  </div>  
                </div>
              </div>
            </header>
            <div className="b-table">
              {loading ? <Loader /> : <Table columns={columns} data={data} />}
              {error && 'Show error'}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CouponList;
