/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { checkAccess } from 'permissions';
import { yupResolver } from '@hookform/resolvers';
import { settingsCleanUp } from 'state/actions/settingsKumen';
import { useFormatMessage } from 'hooks';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ErrorMessage from 'components/ErrorMessage';
import classNames from 'classnames';

const SettingsForm = ({ settings, onSubmitHandler, schema}) => {
  const { loading, success } = useSelector(
    (state) => ({
      loading: state.settingsKumen.loading,
      success: state.settingsKumen.success,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const defaultValues = [];
  const socialType = {
    'facebook-f': 'Facebook',
    instagram: 'Instagram',
    twitter: 'Twitter',
    youtube: 'Youtube',
    tiktok: 'TikTok'
  };

  if(settings && settings.length > 0){
    settings.forEach(settingItem => {
      defaultValues[settingItem.id] = settingItem.value;
    });
  };

  const { register, handleSubmit, errors, setValue, control } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const {
    fields,
    remove,
    append
  } = useFieldArray({
    control,
    name: "socialLinks"
  });

  useEffect(() => {
    return () => dispatch(settingsCleanUp());
  }, [dispatch, success, setValue]);

  const deleteLink = (index) =>{
    if(fields.length === 1){
      setValue('socialLinks[0].type', '');
      setValue('socialLinks[0].url', '');
    }
    else{
      remove(index);
    }    
  };

  const AccessToEditSettings = checkAccess('edit settings');
  const titleTop = useFormatMessage("SettingsKumen.top");
  const labelHeadline = useFormatMessage("SettingsKumen.headline");
  const labelText = useFormatMessage("SettingsKumen.text");
  const titleBottom = useFormatMessage("SettingsKumen.bottom");
  const labelLinks = useFormatMessage("SettingsKumen.links");
  const labelLink = useFormatMessage("SettingsKumen.link");
  const labelDeleteLink = useFormatMessage("SettingsKumen.deleteLink");
  const labelAddLink = useFormatMessage("SettingsKumen.addLink");
  const labelSelectType = useFormatMessage("SettingsKumen.selectType");
  const labelUrl = useFormatMessage("SettingsKumen.url");
  const titleMiddle = useFormatMessage("SettingsKumen.middle");
  const titleRight = useFormatMessage("SettingsKumen.right");
  const labelSubmit = useFormatMessage("Settings.submit");

  return (
    <>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <div className="card tile is-child">
            <div className="card-content">
              {settings && settings.length > 0 &&
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                  <div className="mb-6">
                    <h3 className="title is-5">{titleTop}</h3>
                    <hr />
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">
                          {labelHeadline}
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              name="headline"
                              id="headline"
                              className={classNames('input', {
                                'is-danger': errors.headline,
                              })}
                              ref={register}
                              type="text"
                            />
                          </div>
                          {errors.headline && (
                            <div className="field is-horizontal">
                              <div className="field-body">
                                <ErrorMessage />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">
                          {labelText}
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <Controller
                            control={control}
                            name="topText"
                            render={({ onChange, value }) => (
                              <CKEditor
                                editor={ ClassicEditor }
                                config={
                                  {
                                    toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ]
                                  }
                                }
                                data={value}
                                onChange={ ( event, editor ) => {
                                    const data = editor.getData();
                                    onChange(data);
                                } }
                            />
                            )}
                          />
                          {errors.topText && (
                            <div className="field is-horizontal">
                              <div className="field-body">
                                <ErrorMessage />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <h3 className="title is-5">{titleBottom}</h3>
                    <hr />
                    <h3 className="title is-6">{labelLinks}</h3>

                    {fields.map((item, index) => {
                      return (
                        <div key={item.id} className="field is-horizontal">
                          <div className="field-label is-normal">
                            <label className="label">
                              {labelLink}
                            </label>
                          </div>
                          <div className="field-body">
                            <div className="field is-grouped">
                              <div className="control">
                                <div
                                  className={classNames('select', {
                                    'is-danger': errors?.socialLinks?.[index]?.type,
                                  })}>
                                  <select
                                    name={`socialLinks[${index}].type`}
                                    ref={register()}
                                    defaultValue={item.type}
                                  >
                                    <option key="none" value="">{labelSelectType}</option>
                                    {Object.keys(socialType).map((typeItem) => <option key={typeItem} value={typeItem}>{socialType[typeItem]}</option>)}
                                  </select>
                                </div>
                              </div>
                              <div className="control is-expanded">
                                <input
                                  name={`socialLinks[${index}].url`}
                                  ref={register()}
                                  defaultValue={item.url}
                                  className={classNames('input', {
                                    'is-danger': errors?.socialLinks?.[index]?.url,
                                  })}
                                  placeholder={labelUrl}
                                />
                              </div>

                              <div className="control">
                                <button type="button" className="button is-danger" onClick={() => deleteLink(index)}>
                                  {labelDeleteLink}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    <div className="field is-horizontal">
                      <div className="field-label" />
                      <div className="field-body">
                        <div className="field">
                          <div className="field is-grouped">
                            <div className="control">
                              <button type="button" className="button is-primary" onClick={() => append({type:"", url:""})}>
                                {labelAddLink}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr />
                  <h3 className="title is-6">{titleMiddle}</h3>
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        {labelHeadline}
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <input
                            name="bottomSecondBlockHealine"
                            id="bottomSecondBlockHealine"
                            className={classNames('input', {
                              'is-danger': errors.bottomSecondBlockHealine,
                            })}
                            ref={register}
                            type="text"
                          />
                        </div>
                        {errors.bottomSecondBlockHealine && (
                          <div className="field is-horizontal">
                            <div className="field-body">
                              <ErrorMessage />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        {labelText}
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <Controller
                          control={control}
                          name="bottomSecondBlockText"
                          render={({ onChange, value }) => (
                            <CKEditor
                              editor={ ClassicEditor }
                              config={
                                {
                                  toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ]
                                }
                              }
                              data={value}
                              onChange={ ( event, editor ) => {
                                  const data = editor.getData();
                                  onChange(data);
                              } }
                          />
                          )}
                        />
                        {errors.bottomSecondBlockText && (
                          <div className="field is-horizontal">
                            <div className="field-body">
                              <ErrorMessage />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <hr />
                  <h3 className="title is-6">{titleRight}</h3>
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        {labelHeadline}
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <input
                            name="bottomThirdBlockHealine"
                            id="bottomThirdBlockHealine"
                            className={classNames('input', {
                              'is-danger': errors.bottomThirdBlockHealine,
                            })}
                            ref={register}
                            type="text"
                          />
                        </div>
                        {errors.bottomThirdBlockHealine && (
                          <div className="field is-horizontal">
                            <div className="field-body">
                              <ErrorMessage />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        {labelText}
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <Controller
                          control={control}
                          name="bottomThirdBlockText"
                          render={({ onChange, value }) => (
                            <CKEditor
                              editor={ ClassicEditor }
                              config={
                                {
                                  toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ]
                                }
                              }
                              data={value}
                              onChange={ ( event, editor ) => {
                                  const data = editor.getData();
                                  onChange(data);
                              } }
                          />
                          )}
                        />
                        {errors.bottomThirdBlockText && (
                          <div className="field is-horizontal">
                            <div className="field-body">
                              <ErrorMessage />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <hr />
                  { AccessToEditSettings && 
                    <div className="field is-horizontal">
                      <div className="field-label" />
                      <div className="field-body">
                        <div className="field">
                          <div className="field is-grouped">
                            <div className="control">
                              <button
                                type="submit"
                                className={`button blue-button ${
                                  loading && 'is-loading'
                                }`}
                              >
                                <span>{labelSubmit}</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </form>
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingsForm;