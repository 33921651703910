import { createReducer } from 'redux-act';

import {
  SETTINGS_KUMEN_FETCH_DATA_INIT,
  SETTINGS_KUMEN_FETCH_DATA_SUCCESS,
  SETTINGS_KUMEN_FETCH_DATA_FAIL,
  SETTINGS_KUMEN_MODIFY_INIT,
  SETTINGS_KUMEN_MODIFY_SUCCESS,
  SETTINGS_KUMEN_MODIFY_FAIL,
  SETTINGS_KUMEN_CLEAN_UP,
  SETTINGS_KUMEN_CLEAR_DATA_LOGOUT,
} from 'state/actions/settingsKumen';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
};

export const settingsKumenReducer = createReducer(
  {
    [SETTINGS_KUMEN_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [SETTINGS_KUMEN_FETCH_DATA_SUCCESS]: (state, { data }) => ({
      ...state,
      data,
      loading: false,
      error: null,
    }),
    [SETTINGS_KUMEN_FETCH_DATA_FAIL]: (state, { error }) => ({
      ...state,
      loading: false,
      error,
    }),
    [SETTINGS_KUMEN_MODIFY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [SETTINGS_KUMEN_MODIFY_SUCCESS]: (state, { settings }) => {
      return ({
        ...state,
        data: settings,
        loading: false,
        error: null,
        success: true,
      });
    },
    [SETTINGS_KUMEN_MODIFY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [SETTINGS_KUMEN_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false
    }),
    [SETTINGS_KUMEN_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
  },
  initialState
);
