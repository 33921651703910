/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import classNames from 'classnames';
import { yupResolver } from '@hookform/resolvers';
import { checkAccess } from 'permissions';
import Select from "react-select";
import { useFormatMessage } from 'hooks';
import { nestCategories } from 'utils';
import moment from 'moment';
import ErrorMessage from 'components/ErrorMessage';

import './CategoryForm.scss';

const CategoryForm = ({ category, onSubmitHandler, schema }) => {
  const { loading, categories } = useSelector(
    (state) => ({
      loading: state.categories.loading,
      categories: state.categories.data,
    }),
    shallowEqual
  );

  const categoriesData = nestCategories(categories);

  const { register, handleSubmit, errors, watch, control } = useForm({
    defaultValues: {
      ...category
    },
    resolver: yupResolver(schema),
  });

  const infoLabel = useFormatMessage('CategoryForm.categoryInfo');
  const previewLabel = useFormatMessage('CategoryForm.categoryPreview');

  const permissionToEditCategory = checkAccess('edit category');

  return (
    <>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-information default" />
                </span>
                { infoLabel }
              </p>
            </header>
            <div className="card-content">
              <form onSubmit={handleSubmit(onSubmitHandler)}>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('CategoryForm.name')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          name="name"
                          id="name"
                          className={classNames('input', {
                            'is-danger': errors.name,
                          })}
                          ref={register}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {errors.name && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage text={errors.name.message}/>
                    </div>
                  </div>
                )}
                
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">{ useFormatMessage('CategoryForm.parentCategory') }</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <div className="is-block">
                          <Controller                            
                            name="parentCategory"
                            control={control}
                            render={({ onChange, ref, value }) => (
                              <Select
                                isClearable
                                inputRef={ref}
                                options={categoriesData?.map((item) => ({value: item.id, label: `${('—').repeat(item.prefixDepth)}${item.name}`, isDisabled: category.id && item.id === category.id }))}
                                onChange={val => onChange(val?.value || '')}
                                value={categoriesData?.filter((item) => item.id === value).map((item) => ({value: item.id, label: `${('—').repeat(item.prefixDepth)}${item.name}`}))}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {errors.parentCategory && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage text={errors.parentCategory.message} />
                    </div>
                  </div>
                )}

                <div className="field has-check is-horizontal">
                  <div className="field-label">
                    <label className="label">{useFormatMessage('CategoryForm.showOnMenu')}</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="field">
                        <div className="control">
                          <label className="b-checkbox checkbox">
                            <input
                              type="checkbox"
                              name="showOnMenu"
                              ref={register}
                            />
                            <span className="check is-primary" />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <hr />
                 
                <div className="field is-horizontal">
                  <div className="field-label" />
                  <div className="field-body">
                    <div className="field">
                      <div className="field is-grouped">
                        { permissionToEditCategory &&
                          <div className="control">
                            <button
                              type="submit"
                              className={`button blue-button ${
                                loading && 'is-loading'
                              }`}
                            >
                              <span>{useFormatMessage('CategoryForm.submit')}</span>
                            </button>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <input 
                  type="hidden"
                  name="createdAt"
                  ref={register} />
              </form>
            </div>
          </div>
        </div>
        <div className="tile is-parent preview">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-information-outline default" />
                </span>
                { previewLabel }
              </p>
            </header>
            <div className="card-content">
              <div className="field">
                <label className="label">
                  {useFormatMessage('CategoryForm.name')}
                </label>
                <div className="control is-clearfix">
                  <input
                    data-testid="name"
                    type="text"
                    readOnly="readOnly"
                    className="input is-static"
                    value={watch('name')}
                  />
                </div>
              </div>

              <hr />

              <div className="field">
                <label className="label">
                  {useFormatMessage('CategoryForm.created')}
                </label>
                <div className="control is-clearfix" data-testid="date">
                  <p className="date">
                    {moment(new Date(watch('createdAt'))).format("DD.MM.YYYY")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

CategoryForm.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    createdAt: PropTypes.string.isRequired,
  }).isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  schema: PropTypes.object.isRequired,
};

export default CategoryForm;