import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';
import Loader from 'components/Loader';
import { checkAccess } from 'permissions';
import { useFormatMessage } from 'hooks';
import moment from 'moment';
import Table from 'components/Table';
import { fetchRestaurants, deleteRestaurant, restaurantsCleanUp } from 'state/actions/restaurants';
import paths from 'pages/Router/paths';
import ConfirmationModal from 'components/ConfirmationModal';

const Restaurants = ({ onParamsChange }) => {
  const { restaurantsList, loading, deleted } = useSelector(
    (state) => ({
      restaurantsList: state.restaurants.data,
      loading: state.restaurants.loading,
      deleted: state.restaurants.deleted,
    }),
    shallowEqual
  );

  const [deleteModal, setDeleteModal] = useState({
    restaurantId: null,
    isOpen: false,
  });

  const dispatch = useDispatch();

  const [search, setSearch] = useState('');

  useEffect(() => {
    dispatch(fetchRestaurants());

    return () => dispatch(restaurantsCleanUp());
  }, [dispatch]);

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        restaurantId: null,
        isOpen: !prevState.isOpen,
      }));
    }
  }, [deleted, loading]);


  const onRemoveButtonClickHandler = (restaurantId) => {
    setDeleteModal((prevState) => ({
      restaurantId,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ restaurantId: null, isOpen: false });
  };

  const onDeleteRestaurantHandler = () => {
    dispatch(deleteRestaurant(deleteModal.restaurantId));
  };

  const columns = [
    {
      Header: useFormatMessage('Restaurants.name'),
      accessor: 'name',
      Cell: ({ row }) => (
        <>
          {checkAccess(`edit restaurant`) ? (
            <Link to={`/restaurants/${row.original.id}`}>
              {row.original.name}
            </Link>
          )
          :
            row.original.name
          }
        </>
      )
    },
    {
      Header: useFormatMessage('Restaurants.created'),
      accessor: 'createdAt',
      sortType: (a, b) => {
        return new Date(b.values.createdAt) - new Date(a.values.createdAt);
      },
      Cell: ({ row }) => (
        <>
          {moment(new Date(row.original.createdAt)).format("DD.MM.YYYY")}
        </>
      ),
    },
    {
      Header: '',
      id: 'actions',
      accessor: 'actions',
      Cell: ({ row }) => (
        <div className="buttons is-right">
          {checkAccess(`delete restaurant`) && (
            <button
              type="button"
              className="button is-small is-danger"
              onClick={() => onRemoveButtonClickHandler(row.original.id)}
            >
              <span className="icon is-small">
                <i className="mdi mdi-trash-can" />
              </span>
            </button>
          )}
        </div>
      ),
      disableSortBy: true,
    },
  ];

  const data = search
    ? restaurantsList.filter((el) => {
        const clonedElem = { ...el };
        delete clonedElem.id;
        delete clonedElem.menu;
        delete clonedElem.bgUrl;
        delete clonedElem.logoUrl;
        delete clonedElem.order;

        return Object.values(clonedElem).some((field) =>
          field.toString().toLowerCase().includes(search.toLowerCase())
        );
      })
    : restaurantsList;

  const deleteMessage = useFormatMessage('Restaurants.delete');

  const confirmMessage = useFormatMessage('Restaurants.confirm');

  const permDeleteMessage = useFormatMessage('Restaurants.permDelete');

  const cancelMessage = useFormatMessage('Restaurants.cancel');

  const accessToAddRestaurant = checkAccess('create restaurant');

  const titleSection = useFormatMessage('Restaurants.restaurants');

  useEffect(() => {
    if (onParamsChange) {
      onParamsChange({ title: titleSection });
    }
  }, []);


  return (
    <>
      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={loading}
          confirmButtonMessage={deleteMessage}
          title={confirmMessage}
          body={permDeleteMessage}
          cancelButtonMessage={cancelMessage}
          onConfirmation={onDeleteRestaurantHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      {accessToAddRestaurant && (
        <section className="hero is-hero-bar">
          <div className="hero-body">
            <div className="level">
              <div className="level-right">
                <div className="level-item">
                  <Link to={paths.ADD_RESTAURANT} className="button">
                    {useFormatMessage('Restaurants.newRestaurant')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      <section className="section is-main-section">
        <div className="card has-table has-mobile-sort-spaced">
          <header className="card-header">
            <div className="is-flex is-flex-direction-row is-flex-wrap-wrap">
              <div className="field is-horizontal mb-0 p-3">
                <div className="field-label is-normal">
                  <label htmlFor='inputSearch' className="label">{useFormatMessage('Restaurants.search')}</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <input
                        id="inputSearch"
                        type="text"
                        className="input"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
          <div className="b-table">
            {loading ? <Loader /> : <Table columns={columns} data={data} />}
          </div>
        </div>
      </section>
    </>
  );
};

export default Restaurants;
