import ROLES from 'permissions/roles';

export default {
	[ROLES.ADMIN]: [
		'view order list',
		'view order board',
		'view order item',
		'edit order item',
		'edit order',
		'view user list',
		'view all users',
		'create user',
		`create ${ROLES.MALL_MANAGER} user`,
		`create ${ROLES.SERVICE_DESK} user`,
		`create ${ROLES.STORE} user`,
		`create ${ROLES.CUSTOMER} user`,
		'edit user',
		`edit ${ROLES.ADMIN} user`,
		`edit ${ROLES.MALL_MANAGER} user`,
		`edit ${ROLES.SERVICE_DESK} user`,
		`edit ${ROLES.STORE} user`,
		`edit ${ROLES.CUSTOMER} user`,
		`delete ${ROLES.ADMIN} user`,
		`delete ${ROLES.MALL_MANAGER} user`,
		`delete ${ROLES.SERVICE_DESK} user`,
		`delete ${ROLES.STORE} user`,
		`delete ${ROLES.CUSTOMER} user`,
		'view order comments',
		'add new comment to order',
		'delete any comment',
		'view stores list',
		'create store',
		'view store',
		'edit store',
		'edit store collectionID',
		'delete store',
		'view coupons list',
		'create coupon',
		'view coupon',
		'edit coupon',
		'delete coupon',
		'view settings',
		'edit settings',
		'edit kumen settings',
		'view restaurant list',
		'create restaurant',
		'edit restaurant',
		'delete restaurant',
		'view category list',
		'create category',
		'edit category',
		'delete category',
		'export order',
		'categories mapping',
		'tags mapping',
		'complex mapping'
	],
	[ROLES.MALL_MANAGER]: [
		'view order list',
		'view order board',
		'view order item',
		'view user list',
		'view all users',
		'create user',
		`create ${ROLES.SERVICE_DESK} user`,
		`create ${ROLES.STORE} user`,
		'edit user',
		`edit ${ROLES.SERVICE_DESK} user`,
		`edit ${ROLES.STORE} user`,
		`delete ${ROLES.SERVICE_DESK} user`,
		`delete ${ROLES.STORE} user`,
		'view stores list',
		'view store',
		'create store',
		'edit store',
		'view coupons list',
		'create coupon',
		'view coupon',
		'edit coupon',
		'delete coupon',
		'categories mapping',
		'tags mapping',
		'complex mapping'
	],
	[ROLES.STORE]: [
		'view order list',
		'view order board',
		'view order item',
		'view all users',
	],
	[ROLES.SERVICE_DESK]: [
		'view order list',
		'view order board',
		'view order item',
		'view all users',
		'view order comments',
		'add new comment to order',
		'delete own comment',
		'view stores list',
		'view coupons list'
	],
	[ROLES.CUSTOMER]: []
};