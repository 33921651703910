import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';
import { firebaseError } from 'utils';
import firebase from 'firebase.js';
import { getStorage, ref, deleteObject, uploadBytes, uploadString } from "firebase/storage";

import {
  fetchCollection,
  fetchDocument,
  addDocument,
  deleteDocument,
  updateDocument,
} from '../api';

export const COUPONS_FETCH_DATA_INIT = createAction('COUPONS_FETCH_DATA_INIT');
export const COUPONS_FETCH_DATA_SUCCESS = createAction(
  'COUPONS_FETCH_DATA_SUCCESS'
);
export const COUPONS_FETCH_DATA_FAIL = createAction('COUPONS_FETCH_DATA_FAIL');

export const COUPONS_DELETE_COUPON_INIT = createAction('COUPONS_DELETE_COUPON_INIT');
export const COUPONS_DELETE_COUPON_SUCCESS = createAction(
  'COUPONS_DELETE_COUPON_SUCCESS'
);
export const COUPONS_DELETE_COUPON_FAIL = createAction('COUPONS_DELETE_COUPON_FAIL');

export const COUPONS_CREATE_COUPON_INIT = createAction('COUPONS_CREATE_COUPON_INIT');
export const COUPONS_CREATE_COUPON_SUCCESS = createAction(
  'COUPONS_CREATE_COUPON_SUCCESS'
);
export const COUPONS_CREATE_COUPON_FAIL = createAction('COUPONS_CREATE_COUPON_FAIL');

export const COUPONS_MODIFY_ACTIVATIONS_INIT = createAction('COUPONS_MODIFY_ACTIVATIONS_INIT');
export const COUPONS_MODIFY_ACTIVATIONS_FAIL = createAction('COUPONS_MODIFY_ACTIVATIONS_FAIL');

export const COUPONS_MODIFY_COUPON_INIT = createAction('COUPON_MODIFY_COUPON_INIT');
export const COUPONS_MODIFY_COUPON_SUCCESS = createAction(
  'COUPONS_MODIFY_COUPON_SUCCESS'
);

export const COUPONS_MODIFY_ACTIVATIONS_SUCCESS = createAction('COUPONS_MODIFY_ACTIVATIONS_SUCCESS');

export const COUPONS_MODIFY_COUPON_FAIL = createAction('COUPON_MODIFY_COUPON_FAIL');

export const COUPONS_CLEAN_UP = createAction('COUPONS_CLEAN_UP');

export const COUPONS_CLEAR_DATA_LOGOUT = createAction('COUPONS_CLEAR_DATA_LOGOUT');

export const fetchCoupons = (couponId = '') => {
  return async (dispatch, getState) => {
    dispatch(COUPONS_FETCH_DATA_INIT());

    let { usersCoupons } = getState().coupons;

    if (couponId) {
      let coupon;
      
      try {
        coupon = await fetchDocument('coupons', couponId);
      } catch (error) {
        toastr.error('', error);
        return dispatch(COUPONS_FETCH_DATA_FAIL({ error }));
      }

      if (!coupon) {
        const errorMessage = 'Coupon not available';
        toastr.error('', errorMessage);
        return dispatch(COUPONS_FETCH_DATA_FAIL({ error: errorMessage }));
      }

      const coupons = getState().coupons.data;
      coupons.push(coupon);

      return dispatch(
        COUPONS_FETCH_DATA_SUCCESS({
          data: coupons,
          usersCoupons
        })
      );
    }

    let coupons;

    try {
      coupons = await fetchCollection('coupons');
      usersCoupons = await fetchCollection('user_coupon');
    } catch (error) {
      return dispatch(COUPONS_FETCH_DATA_FAIL({ error }));
    }

    if (!coupons) {
      const errorMessage = 'Coupons not found';
      toastr.info('', errorMessage);
      return dispatch(COUPONS_FETCH_DATA_FAIL({ error: errorMessage }));
    }

    return dispatch(
      COUPONS_FETCH_DATA_SUCCESS({
        data: coupons,
        usersCoupons
      })
    );
  };
};

const deleteLogo = (oldLogo) => {
  if (!oldLogo.includes('firebasestorage')) {
    return null;
  }
  const logoPath = oldLogo.split('coupons%2F').pop().split('?alt=media').shift();
  const storage = getStorage();
  const desertRef = ref(storage, `coupons/${logoPath}`);
  
  return deleteObject(desertRef);
};

export const deleteCoupon = (id) => {
  return async (dispatch, getState) => {
    dispatch(COUPONS_DELETE_COUPON_INIT());

    const { locale } = getState().preferences;
    const { logoUrl } = getState()
      .coupons.data.filter((coupon) => coupon.id === id)
      .pop();

    const deleteLogoTask = logoUrl ? deleteLogo(logoUrl) : null;

    const deleteCouponTask = deleteDocument('coupons', id);

    try {
      await Promise.all([deleteLogoTask, deleteCouponTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        COUPONS_DELETE_COUPON_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'The coupon was deleted.');
    return dispatch(COUPONS_DELETE_COUPON_SUCCESS({ id }));
  };
};

export const clearCouponsDataLogout = () => {
  return (dispatch) => {
    dispatch(COUPONS_CLEAR_DATA_LOGOUT());
  };
};

const uploadLogo = (id, file) => {
  const fileExtension = file.name.split('.').pop();
  const fileName = `${id}.${fileExtension}`;
  const storage = getStorage();
  const storageRef = ref(storage, `coupons/${fileName}`);

  return uploadBytes(storageRef, file);
};

const uploadLogoFromString = (id, data, logoUrl) => {
  const fileExtension = logoUrl.split(/[#?]/)[0].split('.').pop().trim();
  const fileName = `${id}.${fileExtension}`;
  const storage = getStorage();
  const storageRef = ref(storage, `coupons/${fileName}`);

  return uploadString(storageRef, data, 'base64');
};

const getLogoUrl = (id, file) => {
  const fileExtension = file.name.split('.').pop();

  const bucketUrl = `${process.env.REACT_APP_FIRE_BASE_STORAGE_API}`;

  return `${bucketUrl}/o/coupons%2F${id}.${fileExtension}?alt=media`;
};

const getLogoUrlFromUrl = (id, url) => {
  const fileExtension = url.split(/[#?]/)[0].split('.').pop().trim();

  const bucketUrl = `${process.env.REACT_APP_FIRE_BASE_STORAGE_API}`;

  return `${bucketUrl}/o/coupons%2F${id}.${fileExtension}?alt=media`;
};

const downloadLogo = async(url) => {
  let file;
  const downloadFile = firebase
    .functions()
    .httpsCallable('httpsDownloadFile');

  try{
    const response = await downloadFile({ url });
    if (response && response.data) {
      file = response.data;
    }
  }
  catch (error) {
    toastr.error('', error);
  }

  return file;
};

export const createCoupon = ({
  name,
  storeName,
  store,
  startDate,
  endDate,
  description,
  file,
  logoUrl = '',
  createdAt,
  unlimited
}) => {
  return async (dispatch, getState) => {
    dispatch(COUPONS_CREATE_COUPON_INIT());

    const { locale } = getState().preferences;

    const couponData = {
      name,
      storeName,
      store,
      startDate,
      endDate,
      description,
      createdAt,
      usedCount: 0,
      unlimited,
      logoUrl
    };

    let response;
    
    try {
      response = await addDocument('coupons', couponData);
      const { id } = response;

      let uploadLogoTask = null;
      let newLogoUrl = null;
      if (file) {
        newLogoUrl = getLogoUrl(id, file);
        uploadLogoTask = uploadLogo(id, file);
      }
      else if (logoUrl) {
        const newLogo = await downloadLogo(logoUrl);
        newLogoUrl = getLogoUrlFromUrl(id, logoUrl);
        uploadLogoTask = uploadLogoFromString(id, newLogo, newLogoUrl);
      }
      
      couponData.logoUrl = newLogoUrl;

      const createCouponDbTask = updateDocument('coupons', id, couponData);

      await Promise.all([
        uploadLogoTask,
        createCouponDbTask
      ]);

    } catch (error) {
      console.log(error);
      const errorMessage = firebaseError(error.message, locale);
      toastr.error('', errorMessage);
      return dispatch(
        COUPONS_CREATE_COUPON_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'Coupon created successfully');
    return dispatch(COUPONS_CREATE_COUPON_SUCCESS({ coupon: {
        id: response.id,
        ...couponData
      }
    }));
  };
};

export const removeCouponActivation = (
  userId,
  activationDate,
  couponId
) => {
  return async (dispatch, getState) => {
    dispatch(COUPONS_MODIFY_ACTIVATIONS_INIT());
    const { locale } = getState().preferences;
    const { usersCoupons } = getState().coupons;

    const userCoupons = usersCoupons.find((item) => item.id === userId);
    const userActivations = userCoupons?.coupons.filter(activation => !(activation.activateDate === activationDate && activation.couponId === couponId));

    const userCouponsData = {
      coupons: userActivations
    };

    try {
      await updateDocument('user_coupon', userId, userCouponsData);
      await updateDocument('coupons', couponId, {usedCount: firebase.firestore.FieldValue.increment(-1)});
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        COUPONS_MODIFY_ACTIVATIONS_FAIL({
          error: errorMessage,
        })
      );
    }

    return dispatch(COUPONS_MODIFY_ACTIVATIONS_SUCCESS({ userId,  userCouponsData}));
  };
};

export const modifyCoupon = ({
  name,
  storeName,
  store,
  startDate,
  endDate,
  description,
  file,
  id,
  unlimited
}) => {
  return async (dispatch, getState) => {
    dispatch(COUPONS_MODIFY_COUPON_INIT());
    const { locale } = getState().preferences;
    const coupons = getState().coupons.data;

    const coupon = coupons.find((thisCoupon) => thisCoupon.id === id);

    const { logoUrl, createdAt } = coupon;
    let deleteLogoTask;
    let uploadLogoTask;
    let newLogoUrl = null;
    if (file) {
      newLogoUrl = getLogoUrl(id, file);
      deleteLogoTask = logoUrl && deleteLogo(logoUrl);
      uploadLogoTask = uploadLogo(id, file);
    }

    const couponData = {
      name,
      storeName,
      store,
      createdAt,
      startDate,
      endDate,
      description,
      logoUrl: newLogoUrl || logoUrl,
      unlimited
    };

    const updateCouponDbTask = updateDocument('coupons', id, couponData);

    try {
      await Promise.all([deleteLogoTask, uploadLogoTask, updateCouponDbTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        COUPONS_MODIFY_COUPON_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'Coupon updated successfully');
    return dispatch(COUPONS_MODIFY_COUPON_SUCCESS({ coupon: couponData, id }));
  };
};

export const couponsCleanUp = () => (dispatch) => dispatch(COUPONS_CLEAN_UP());