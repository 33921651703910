import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';
import {
  fetchCollection,
  fetchDocument
} from '../api';

export const INTEGRATOR_SIZES_FETCH_DATA_INIT = createAction('INTEGRATOR_SIZES_FETCH_DATA_INIT');
export const INTEGRATOR_SIZES_FETCH_DATA_SUCCESS = createAction(
  'INTEGRATOR_SIZES_FETCH_DATA_SUCCESS'
);
export const INTEGRATOR_SIZES_FETCH_DATA_FAIL = createAction('INTEGRATOR_SIZES_FETCH_DATA_FAIL');
export const INTEGRATOR_SIZES_CLEAN_UP = createAction('INTEGRATOR_SIZES_CLEAN_UP');
export const INTEGRATOR_SIZES_CLEAR_DATA_LOGOUT = createAction('INTEGRATOR_SIZES_CLEAR_DATA_LOGOUT');

export const fetchIntegratorSizes = (sizeId = '') => {
  return async (dispatch, getState) => {
    dispatch(INTEGRATOR_SIZES_FETCH_DATA_INIT());

    if (sizeId) {
      let size;
      
      try {
        size = await fetchDocument('integrator_sizes',sizeId);
      } catch (error) {
        toastr.error('', error);
        return dispatch(INTEGRATOR_SIZES_FETCH_DATA_FAIL({ error }));
      }

      if (!size) {
        const errorMessage = 'Size not available';
        toastr.error('', errorMessage);
        return dispatch(INTEGRATOR_SIZES_FETCH_DATA_FAIL({ error: errorMessage }));
      }

      const sizes = getState().integratorSizes.data;
      sizes.push(size);

      return dispatch(
        INTEGRATOR_SIZES_FETCH_DATA_SUCCESS({
          data: sizes
        })
      );
    }

    const observeIntegratorSizes = (updatedIntegratorSizes) => {
      return dispatch(
        INTEGRATOR_SIZES_FETCH_DATA_SUCCESS({
          data: updatedIntegratorSizes,
        })
      );
    };

    let sizes;

    try {
      const queryOptions = {};

      sizes = await fetchCollection('integrator_sizes', queryOptions, observeIntegratorSizes);

    } catch (error) {
      return dispatch(INTEGRATOR_SIZES_FETCH_DATA_FAIL({ error }));
    }

    if (!sizes) {
      const errorMessage = 'Sizes not found';
      toastr.info('', errorMessage);
    }

    return dispatch(
      INTEGRATOR_SIZES_FETCH_DATA_SUCCESS({
        data: sizes
      })
    );
  };
};

export const clearIntegratorSizesDataLogout = () => {
  return (dispatch) => {
    dispatch(INTEGRATOR_SIZES_CLEAR_DATA_LOGOUT());
  };
};

export const integratorSizesCleanUp = () => (dispatch) => dispatch(INTEGRATOR_SIZES_CLEAN_UP());
