import React, { useEffect, useMemo, useState, Fragment } from 'react';
import { Link, useParams, Redirect, useLocation } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import Loader from 'components/Loader';
import * as yup from 'yup';
import { format } from 'date-fns';
import CouponForm from 'components/CouponForm';
import CouponActivations from 'components/CouponActivations';
import { createCoupon, modifyCoupon, fetchCoupons } from 'state/actions/coupons';
import paths from 'pages/Router/paths';
import { useFormatMessage } from 'hooks';

const Coupon = ({ onParamsChange }) => {
  const { id } = useParams();
  const { search } = useLocation();
  const isEditing = useMemo(() => !!id, [id]);

  const { success, couponData, usersCoupons, error, coupons} = useSelector(
    (state) => ({
      success: state.coupons.success,
      couponData: state.coupons.data.find((coupon) => coupon.id === id),
      usersCoupons: state.coupons.usersCoupons,
      error: state.coupons.error,
      coupons: state.coupons.data
    }),
    shallowEqual
  );

  const newCouponData = {
    name: '',
    storeName: '',
    store: '',
    startDate: new Date().toDateString(),
    endDate: new Date().toDateString(),
    description: '',
    unlimited: false,
    createdAt: new Date().toDateString()
  };

  const [defaultCoupon, setDefaultCoupon] = useState(newCouponData);

  if(search && !isEditing){
    const queryParams = new URLSearchParams(search);
    const clonedId = queryParams.get('clonedId');
    const clonedCouponData = coupons.find((coupon) => coupon.id === clonedId);
    if(clonedCouponData){
      delete(clonedCouponData.id);
      clonedCouponData.createdAt = new Date().toDateString();
      clonedCouponData.usedCount = 0;
      setDefaultCoupon(clonedCouponData);
    }
  }

  const parseDateString = (value) => {
    const date = new Date(value);
    const dateFormat = format(date, "yyyy-MM-dd");

    return dateFormat;
  };

  const schema = yup.object().shape({
    name: yup.string().required(),
    storeName: yup.string(),
    store: yup.string().when('storeName', {
      is: (val) => val !== '' ,
      otherwise: yup.string().required('Please set store name or store'),
    }),
    startDate: yup.date().required(),
    endDate: yup.date().min(yup.ref('startDate'), "End date can't be before start date").required(),
    description: yup.string(),
    unlimited: yup.boolean()
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEditing && !couponData) {
      dispatch(fetchCoupons(id));
    }
    if(search){
      dispatch(fetchCoupons());
    }
  }, [isEditing, id, dispatch]);

  const redirect = ((isEditing && error) || success) && (
    <Redirect to={paths.COUPONS} />
  );

  const goBackMessage = useFormatMessage('CouponForm.goBack');

  let titleSection = useFormatMessage('Coupon.newCoupon');
  if(isEditing){
    titleSection = useFormatMessage('Coupon.editCoupon');
  }

  useEffect(() => {
    if (onParamsChange) {
      onParamsChange({ title: titleSection });
    }
  }, []);

  const onSubmitHandler = (value) => {
    const newCoupon = {
      ...value,
      startDate: parseDateString(value.startDate),
      endDate: parseDateString(value.endDate),
      file: value?.file[0] || null,
      isEditing,
      id,
    };

    if (isEditing) {
      dispatch(modifyCoupon(newCoupon));
    } else {
      if(!newCoupon.file && defaultCoupon.logoUrl){
        newCoupon.logoUrl = defaultCoupon.logoUrl;
      }
      dispatch(createCoupon(newCoupon));
    }
  };

  return (
    <Fragment key={Math.random()}>
      {redirect}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <Link to={paths.COUPONS} className="button has-icon">
            <span className="icon">
              <i className="mdi mdi-arrow-left"/>
            </span>
            {goBackMessage}
          </Link>
        </div>
      </section>
      <section className="section is-main-section">
        {isEditing && !couponData ? (
          <Loader />
        ) : (
          <>
            <CouponForm
              isEditing={isEditing}
              coupon={
                isEditing
                  ? couponData
                  : 
                    defaultCoupon
              }
              onSubmitHandler={onSubmitHandler}
              schema={schema}
            />
            {usersCoupons && isEditing &&
              <CouponActivations couponIds={[id]} usersCoupons={usersCoupons} />
            }
          </>
        )}
      </section>
    </Fragment>
  );
};

export default Coupon;
