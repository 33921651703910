/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Loader from 'components/Loader';
import classes from './ModalBox.module.scss';

const ModalBox = (props) => {
  const {
    className,
    modalWidth,
    show,
    onClose,
    content,
    footer,
    header,
    closeClickOutside = false,
    modalDisabled = false
  } = props;

  const handleOnClick = () => {
    onClose();
  };

  const handleOnClickBackground = () => {
    if(closeClickOutside){
      onClose();
    }
  };

  return (
    <>
      {show ?  
        <div className={classNames(className, 'modal', 'is-active', classes.modal)}>
          <div onClick={handleOnClickBackground} className="modal-background"/>
          <div className={classNames({ [`${classes[modalWidth]}`]: modalWidth }, 'modal-card')}>
            {modalDisabled &&
              <Loader fullSize/>
            }
            <header className="modal-card-head">
              <div className="modal-card-title">
                <div className="modal-head-content is-flex is-flex-direction-row is-flex-wrap-wrap is-align-items-center">{ header }</div>
              </div>
              <button className="delete" onClick={handleOnClick} aria-label="close"/>
            </header>
            
            { content &&
              <section className="modal-card-body">{ content }</section>
            }

            { footer &&
              <footer className="modal-card-foot">{ footer }</footer>
            }

          </div>
        </div>
        : <></>
      }
    </>
  );
};


ModalBox.propTypes = {
  className: PropTypes.string,
  modalWidth: PropTypes.string,
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

ModalBox.defaultProps = {
  className: '',
  modalWidth: ''
};

export default ModalBox;
