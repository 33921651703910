import React, { useEffect, useMemo } from 'react';
import { Link, useParams, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import Loader from 'components/Loader';
import * as yup from 'yup';
import RestaurantForm from 'components/Kumen/RestaurantForm';
import { createRestaurant, modifyRestaurant, fetchRestaurants } from 'state/actions/restaurants';
import paths from 'pages/Router/paths';
import { useFormatMessage } from 'hooks';

const Restaurant = ({ onParamsChange }) => {
  const { id } = useParams();

  const isEditing = useMemo(() => !!id, [id]);

  const { success, restaurantData, error} = useSelector(
    (state) => ({
      success: state.restaurants.success,
      restaurantData: state.restaurants.data.find((restaurant) => restaurant.id === id),
      error: state.restaurants.error,
    }),
    shallowEqual
  );

  const schema = yup.object().shape({
    name: yup.string().required(),
    createdAt: yup.string().required(),
    order: yup.number().min(0).required(),
    menu: yup.array().of(
      yup.object().shape({
        name: yup.string().notRequired(),
        linkName: yup.string().when("linkUrl", {
          is: (value) => value && value.length > 0,
          then: yup.string().required()
        }),
        linkUrl: yup.string().when("linkName", {
          is: (value) => value && value.length > 0,
          then: yup.string().required()
        }),
      }, ['linkName', 'linkUrl']),
    ),
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEditing) {
      
      if (!restaurantData) {
        dispatch(fetchRestaurants(id));
      }
    }
  }, [isEditing, id, restaurantData, dispatch]);

  const redirect = ((isEditing && error) || success) && (
    <Redirect to={paths.RESTAURANTS} />
  );

  let titleSection = useFormatMessage('Restaurant.newRestaurant');
  if(isEditing){
    titleSection = useFormatMessage('Restaurant.editRestaurant');
  }

  useEffect(() => {
    if (onParamsChange) {
      onParamsChange({ title: titleSection });
    }
  }, []);

  const goBackMessage = useFormatMessage('RestaurantForm.goBack');

  const onSubmitHandler = (value) => {
    const newValue = { ...value };

    const newRestaurant = {
      ...newValue,
      logoFile: value?.logoFile[0] || null,
      bgFile: value?.bgFile[0] || null,
      isEditing,
      id,
    };

    if (isEditing) {
      dispatch(modifyRestaurant(newRestaurant));
    } else {
      dispatch(createRestaurant(newRestaurant));
    }
  };

  return (
    <>
      {redirect}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <Link to={paths.RESTAURANTS} className="button has-icon">
            <span className="icon">
              <i className="mdi mdi-arrow-left"/>
            </span>
            {goBackMessage}
          </Link>
        </div>
      </section>
      <section className="section is-main-section">
        {isEditing && !restaurantData ? (
          <Loader />
        ) : (
          <RestaurantForm
            isEditing={isEditing}
            restaurant={
              isEditing
                ? restaurantData
                : {
                    name: '',
                    order: 0,
                    createdAt: new Date().toDateString(),
                    menu: [{
                      name: "",
                      linkName: "",
                      linkUrl: "",
                    }]
                  }
            }
            onSubmitHandler={onSubmitHandler}
            schema={schema}
          />
        )}
      </section>
    </>
  );
};

export default Restaurant;
