import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';
import { firebaseError } from 'utils';
import firebase from 'firebase.js';

import {
  fetchCollection,
  fetchDocument,
  addDocument,
  deleteDocument,
  updateDocument,
} from '../api';

export const RESTAURANTS_FETCH_DATA_INIT = createAction('RESTAURANTS_FETCH_DATA_INIT');
export const RESTAURANTS_FETCH_DATA_SUCCESS = createAction(
  'RESTAURANTS_FETCH_DATA_SUCCESS'
);
export const RESTAURANTS_FETCH_DATA_FAIL = createAction('RESTAURANTS_FETCH_DATA_FAIL');

export const RESTAURANTS_DELETE_RESTAURANT_INIT = createAction('RESTAURANTS_DELETE_RESTAURANT_INIT');
export const RESTAURANTS_DELETE_RESTAURANT_SUCCESS = createAction(
  'RESTAURANTS_DELETE_RESTAURANT_SUCCESS'
);
export const RESTAURANTS_DELETE_RESTAURANT_FAIL = createAction('RESTAURANTS_DELETE_RESTAURANT_FAIL');

export const RESTAURANTS_CREATE_RESTAURANT_INIT = createAction('RESTAURANTS_CREATE_RESTAURANT_INIT');
export const RESTAURANTS_CREATE_RESTAURANT_SUCCESS = createAction(
  'RESTAURANTS_CREATE_RESTAURANT_SUCCESS'
);
export const RESTAURANTS_CREATE_RESTAURANT_FAIL = createAction('RESTAURANTS_CREATE_RESTAURANT_FAIL');

export const RESTAURANTS_MODIFY_RESTAURANT_INIT = createAction('RESTAURANT_MODIFY_RESTAURANT_INIT');
export const RESTAURANTS_MODIFY_RESTAURANT_SUCCESS = createAction(
  'RESTAURANTS_MODIFY_RESTAURANT_SUCCESS'
);

export const RESTAURANTS_MODIFY_RESTAURANT_FAIL = createAction('RESTAURANT_MODIFY_RESTAURANT_FAIL');

export const RESTAURANTS_CLEAN_UP = createAction('RESTAURANTS_CLEAN_UP');

export const RESTAURANTS_CLEAR_DATA_LOGOUT = createAction('RESTAURANTS_CLEAR_DATA_LOGOUT');

export const fetchRestaurants = (restaurantId = '') => {
  return async (dispatch, getState) => {
    dispatch(RESTAURANTS_FETCH_DATA_INIT());

    if (restaurantId) {
      let restaurant;
      
      try {
        restaurant = await fetchDocument('restaurants', restaurantId);
      } catch (error) {
        toastr.error('', error);
        return dispatch(RESTAURANTS_FETCH_DATA_FAIL({ error }));
      }

      if (!restaurant) {
        const errorMessage = 'Restaurant not available';
        toastr.error('', errorMessage);
        return dispatch(RESTAURANTS_FETCH_DATA_FAIL({ error: errorMessage }));
      }

      const restaurants = getState().restaurants.data;
      restaurants.push(restaurant);

      return dispatch(
        RESTAURANTS_FETCH_DATA_SUCCESS({
          data: restaurants
        })
      );
    }

    let restaurants;

    try {
      const queryOptions = {
        sort:[
          {
            'attribute': 'order',
            'order': 'asc'
          }
        ],
      };

      restaurants = await fetchCollection('restaurants', queryOptions);
    } catch (error) {
      return dispatch(RESTAURANTS_FETCH_DATA_FAIL({ error }));
    }

    if (!restaurants) {
      const errorMessage = 'Restaurants not found';
      toastr.info('', errorMessage);
      return dispatch(RESTAURANTS_FETCH_DATA_FAIL({ error: errorMessage }));
    }

    return dispatch(
      RESTAURANTS_FETCH_DATA_SUCCESS({
        data: restaurants
      })
    );
  };
};

const deleteImage = (oldImage) => {
  if (!oldImage.includes('firebasestorage')) {
    return null;
  }
  const imagePath = oldImage.split('restaurants%2F').pop().split('?alt=media').shift();
  return firebase.storage().ref(`restaurants/${imagePath}`).delete();
};

export const deleteRestaurant = (id) => {
  return async (dispatch, getState) => {
    dispatch(RESTAURANTS_DELETE_RESTAURANT_INIT());

    const { locale } = getState().preferences;
    const { logoUrl, bgUrl } = getState()
      .restaurants.data.filter((restaurant) => restaurant.id === id)
      .pop();

    const deleteLogoTask = logoUrl ? deleteImage(logoUrl) : null;
    const deleteBgTask = bgUrl ? deleteImage(bgUrl) : null;

    const deleteRestaurantTask = deleteDocument('restaurants', id);

    try {
      await Promise.all([deleteLogoTask, deleteBgTask, deleteRestaurantTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        RESTAURANTS_DELETE_RESTAURANT_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'The restaurant was deleted.');
    return dispatch(RESTAURANTS_DELETE_RESTAURANT_SUCCESS({ id }));
  };
};

export const clearRestaurantsDataLogout = () => {
  return (dispatch) => {
    dispatch(RESTAURANTS_CLEAR_DATA_LOGOUT());
  };
};

const uploadImage = (id, file, type) => {
  const storageRef = firebase.storage().ref();

  const fileExtension = file.name.split('.').pop();

  const fileName = `${id}-${type}.${fileExtension}`;

  return storageRef.child(`restaurants/${fileName}`).put(file);
};

const getImageUrl = (id, file, type) => {
  const fileExtension = file.name.split('.').pop();

  const bucketUrl = `${process.env.REACT_APP_FIRE_BASE_STORAGE_API}`;

  return `${bucketUrl}/o/restaurants%2F${id}-${type}.${fileExtension}?alt=media`;
};

export const createRestaurant = ({
  name,
  menu,
  order,
  logoFile,
  bgFile,
  createdAt
}) => {
  return async (dispatch, getState) => {
    dispatch(RESTAURANTS_CREATE_RESTAURANT_INIT());

    const { locale } = getState().preferences;

    const restaurantData = {
      name,
      menu,
      order,
      createdAt
    };

    let response;
    
    try {
      response = await addDocument('restaurants', restaurantData);
      const { id } = response;

      let uploadImageTask = null;
      let logoUrl = null;
      if (logoFile) {
        logoUrl = getImageUrl(id, logoFile, 'logo');
        uploadImageTask = uploadImage(id, logoFile, 'logo');
      }

      restaurantData.logoUrl = logoUrl;

      let uploadBgTask = null;
      let bgUrl = null;
      if (bgFile) {
        bgUrl = getImageUrl(id, bgFile, 'bg');
        uploadBgTask = uploadImage(id, bgFile, 'bg');
      }

      restaurantData.bgUrl = bgUrl;

      const createRestaurantDbTask = updateDocument('restaurants', id, restaurantData);

      await Promise.all([
        uploadImageTask,
        uploadBgTask,
        createRestaurantDbTask
      ]);

    } catch (error) {
      const errorMessage = firebaseError(error.message, locale);
      toastr.error('', errorMessage);
      return dispatch(
        RESTAURANTS_CREATE_RESTAURANT_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'Restaurant created successfully');
    return dispatch(RESTAURANTS_CREATE_RESTAURANT_SUCCESS({ restaurant: {
        id: response.id,
        ...restaurantData
      }
    }));
  };
};

export const modifyRestaurant = ({
  name,
  menu,
  order,
  logoFile,
  deleteLogo = false,
  bgFile,
  deleteBg = false,
  id
}) => {
  return async (dispatch, getState) => {
    dispatch(RESTAURANTS_MODIFY_RESTAURANT_INIT());
    const { locale } = getState().preferences;
    const restaurants = getState().restaurants.data;

    const restaurant = restaurants.find((thisRestaurant) => thisRestaurant.id === id);

    const { logoUrl, bgUrl, createdAt } = restaurant;
    let deleteLogoTask;
    let uploadLogoTask;
    let newLogoUrl = null;
    if (logoFile) {
      newLogoUrl = getImageUrl(id, logoFile, 'logo');
      deleteLogoTask = logoUrl && deleteImage(logoUrl);
      uploadLogoTask = uploadImage(id, logoFile, 'logo');
    }

    let deleteBgTask;
    let uploadBgTask;
    let newBgUrl = null;
    if (bgFile) {
      newBgUrl = getImageUrl(id, bgFile, 'bg');
      deleteBgTask = bgUrl && deleteImage(bgUrl);
      uploadBgTask = uploadImage(id, bgFile, 'bg');
    }

    const restaurantData = {
      name,
      menu,
      order,
      createdAt,
      logoUrl: newLogoUrl || logoUrl,
      bgUrl: newBgUrl || bgUrl,
    };

    if (deleteLogo === 'true') {
      deleteLogoTask = logoUrl && deleteImage(logoUrl);
      restaurantData.logoUrl = null;
    }

    if (deleteBg === 'true') {
      deleteBgTask = bgUrl && deleteImage(bgUrl);
      restaurantData.bgUrl = null;
    }

    const updateRestaurantDbTask = updateDocument('restaurants', id, restaurantData);

    try {
      await Promise.all([deleteLogoTask, uploadLogoTask, deleteBgTask, uploadBgTask, updateRestaurantDbTask]);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        RESTAURANTS_MODIFY_RESTAURANT_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'Restaurant updated successfully');
    return dispatch(RESTAURANTS_MODIFY_RESTAURANT_SUCCESS({ restaurant: restaurantData, id }));
  };
};

export const restaurantsCleanUp = () => (dispatch) => dispatch(RESTAURANTS_CLEAN_UP());