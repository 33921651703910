import React, { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import Loader from 'components/Loader';
import * as yup from 'yup';
import SettingsForm from 'components/Kumen/SettingsForm';
import { fetchSettings, modifySettings } from 'state/actions/settingsKumen';
import { useFormatMessage } from 'hooks';

const Settings = ({ onParamsChange }) => {
  const { settingsData } = useSelector(
    (state) => ({
      settingsData: state.settingsKumen.data,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const schema = yup.object().shape({
    headline: yup.string().notRequired(),
    topText: yup.string().notRequired(),
    socialLinks: yup.array().of(
      yup.object().shape({
        type: yup.string().when("url", {
          is: (value) => value && value.length > 0,
          then: yup.string().required()
        }),
        url: yup.string().when("type", {
          is: (value) => value && value.length > 0,
          then: yup.string().required()
        }),
      }, ['type', 'url']),
    ),
    bottomSecondBlockHealine: yup.string().notRequired(),
    bottomSecondBlockText: yup.string().notRequired(),
    bottomThirdBlockHealine: yup.string().notRequired(),
    bottomThirdBlockText: yup.string().notRequired(),
  });

  useEffect(() => {
    dispatch(fetchSettings());
  }, [dispatch]);

  const onSubmitHandler = (value) => {
    const updatedSettings = {
      ...value
    };

    dispatch(modifySettings(updatedSettings));
  };

  const titleSection = useFormatMessage('SettingsKumen.title');

  useEffect(() => {
    if (onParamsChange) {
      onParamsChange({ title: titleSection });
    }
  }, []);

  return (
    <>
      <section className="section is-main-section">
        {!settingsData ? (
          <Loader />
        ) : (
          <SettingsForm
            settings={settingsData}
            onSubmitHandler={onSubmitHandler}
            schema={schema}
          />
        )}
      </section>
    </>
  );
};

export default Settings;