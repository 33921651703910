import { createReducer } from 'redux-act';

import {
  ORDERS_FETCH_DATA_INIT,
  ORDERS_FETCH_DATA_SUCCESS,
  ORDERS_FETCH_DATA_FAIL,
  ORDERS_MODIFY_ORDER_INIT,
  ORDERS_MODIFY_ORDER_SUCCESS,
  ORDERS_MODIFY_ORDER_FAIL,
  ORDERS_DELETE_ORDER_SUCCESS,
  ORDERS_CLEAN_UP,
  ORDERS_CLEAR_DATA_LOGOUT,
  ORDERS_PAY_ORDER_INIT,
} from 'state/actions/orders';

const initialState = {
  data: [],
  loading: false,
  error: null,
};

export const ordersReducer = createReducer(
  {
    [ORDERS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [ORDERS_FETCH_DATA_SUCCESS]: (state, {data}) => ({
      ...state,
      data,
      loading: false,
      error: null,
    }),
    [ORDERS_FETCH_DATA_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [ORDERS_MODIFY_ORDER_INIT]: (state) => ({
      ...state,
      loading: true,
    }),

    [ORDERS_PAY_ORDER_INIT]: (state, {id, order}) => {
      const { data } = state;
      
      return ({
          ...state,
          data: !data
            ? []
            : data.map((elem) => {
                if (elem.id === id) {
                  return {
                    id,
                    Billing: order.Billing,
                    CompaniesIds: order.CompaniesIds,
                    CustomFields: order.CustomFields,
                    Items: order.Items,
                    Payments: order.Payments,
                    Shipping: order.Shipping,
                    ShippingLine: order.ShippingLine,
                    StandardFields: order.StandardFields,
                    ServiceData: order.ServiceData,
                    StoreStatuses: order.StoreStatuses,
                    Comments: order.Comments
                  };
                }
                return elem;
              }),
          loading: false,
        });
    },
    [ORDERS_MODIFY_ORDER_SUCCESS]: (state, {id, order, error}) => {
      const { data } = state;
      
      return ({
          ...state,
          data: !data
            ? []
            : data.map((elem) => {
                if (elem.id === id) {
                  return {
                    id,
                    Billing: order.Billing,
                    CompaniesIds: order.CompaniesIds,
                    CustomFields: order.CustomFields,
                    Items: order.Items,
                    Payments: order.Payments,
                    Shipping: order.Shipping,
                    ShippingLine: order.ShippingLine,
                    StandardFields: order.StandardFields,
                    ServiceData: order.ServiceData,
                    StoreStatuses: order.StoreStatuses,
                    Comments: order.Comments
                  };
                }
                return elem;
              }),
          loading: false,
          error: error || null,
          success: true,
        });
    },
    [ORDERS_MODIFY_ORDER_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [ORDERS_DELETE_ORDER_SUCCESS]: (state, { id }) => {
      const { data } = state;

      return ({
        ...state,
        data: data.filter((elem) => elem.id !== id),
        loading: false,
        error: null,
        deleted: true,
      });
    },
    [ORDERS_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
    }),
    [ORDERS_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
  },
  initialState
);
