/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { checkAccess } from 'permissions';
import { yupResolver } from '@hookform/resolvers';
import { settingsCleanUp, modifySettings } from 'state/actions/settings';
import { useFormatMessage } from 'hooks';
import classNames from 'classnames';
import moment from "moment";
import DatePicker from 'react-datepicker';

const OpeningHoursForm = ({ settings }) => {
  const settingsDefaultValue = [];

  if(settings && settings.length > 0){
    settings.forEach(settingItem => {
      settingsDefaultValue[settingItem.id] = settingItem?.value;
    });
  };

  const convertTimeToDateTime = (time = '') => {
    let dateTime = '';

    if(time){
      const timeArr = time.split(':');
      dateTime = moment().set('hour', timeArr[0]).set('minute', timeArr[1] ? timeArr[1] : 0).set('second', 0);
    }

    return dateTime;
  };

  const { openingHoursWeekDays = [], openingHoursSpecialDays = [], outsideOpeningHoursText = ''} = settingsDefaultValue;
  const data = {
    openingHoursWeekDays: openingHoursWeekDays.map(item => ({...item, timeFrom: convertTimeToDateTime(item.timeFrom), timeTo: convertTimeToDateTime(item.timeTo)})),
    openingHoursSpecialDays: openingHoursSpecialDays.map(item => ({...item, timeFrom: convertTimeToDateTime(item.timeFrom), timeTo: convertTimeToDateTime(item.timeTo)})),
    outsideOpeningHoursText
  };

  const { loading } = useSelector(
    (state) => ({
      loading: state.settings.loading
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const schema = yup.object().shape({ 
    openingHoursWeekDays: yup.array().of(
      yup.object().shape({
        day: yup.string().required(),
        timeFrom: yup.string().required(),
        timeTo: yup.string().required()
      })
    ),
    openingHoursSpecialDays: yup.array().of(
      yup.object().shape({
        title: yup.string().required(),
        singleTitle: yup.string().required(),
        date: yup.string().required()
      })
    )
  });

  const { register, handleSubmit, errors, control } = useForm({
    defaultValues: data,
    resolver: yupResolver(schema),
  });
  
  const { fields: weekDayFields, append: appendWeekDay, remove: removeWeekDay } = useFieldArray({
    control,
    name: "openingHoursWeekDays"
  });

  const { fields: specialDayFields, append: appendSpecialDay, remove: removeSpecialDay } = useFieldArray({
    control,
    name: "openingHoursSpecialDays"
  });

  useEffect(() => {
    return () => dispatch(settingsCleanUp());
  }, [dispatch]);


  const weekDays = [
    {
      value: 'monday',
      label: useFormatMessage("WeekDays.monday")
    },
    {
      value: 'tuesday',
      label: useFormatMessage("WeekDays.tuesday")
    },
    {
      value: 'wednesday',
      label: useFormatMessage("WeekDays.wednesday")
    },
    {
      value: 'thursday',
      label: useFormatMessage("WeekDays.thursday")
    },
    {
      value: 'friday',
      label: useFormatMessage("WeekDays.friday")
    },
    {
      value: 'saturday',
      label: useFormatMessage("WeekDays.saturday")
    },
    {
      value: 'sunday',
      label: useFormatMessage("WeekDays.sunday")
    }
  ];

  const openingHoursLabel = useFormatMessage("Settings.openingHours");
  const specialOpeningHoursLabel = useFormatMessage("Settings.specialOpeningHours");
  const chooseDayWeekLabel = useFormatMessage("Settings.chooseDayWeek");
  const titlePlaceholder = useFormatMessage("Settings.specialName");
  const singleNamePlaceholder = useFormatMessage("Settings.singleName");
  const datePlaceholder = useFormatMessage("Settings.date");
  const timeFromPlaceholder = useFormatMessage("Settings.timeFrom");
  const timeToPlaceholder = useFormatMessage("Settings.timeTo");
  const addLabel = useFormatMessage("Settings.add");
  const outsideOpeningHoursTextLabel = useFormatMessage("Settings.outsideOpeningHoursText");

  const AccessToEditSettings = checkAccess('edit settings');

  const onSubmitHandler = (values) => {
    const updatedSettings = {
      ...values
    };

    if(updatedSettings.openingHoursWeekDays && updatedSettings.openingHoursWeekDays.length > 0){
      updatedSettings.openingHoursWeekDays = updatedSettings.openingHoursWeekDays
      .map(item => ({...item, timeFrom: moment(item.timeFrom).format("HH:mm"), timeTo: moment(item.timeTo).format("HH:mm")}));
    }

    if(updatedSettings.openingHoursSpecialDays && updatedSettings.openingHoursSpecialDays.length > 0){
      updatedSettings.openingHoursSpecialDays = updatedSettings.openingHoursSpecialDays
      .map(item => ({...item, date: moment(item.date).format("YYYY-MM-DD"), timeFrom: item.timeFrom ? moment(item.timeFrom).format("HH:mm") : '', timeTo: item.timeTo ? moment(item.timeTo).format("HH:mm") : ''}));
    }

    dispatch(modifySettings(updatedSettings));
  };

  return (
    <>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <div className="card tile is-child">
            <div className="card-content">
              <form onSubmit={handleSubmit(onSubmitHandler)}>

                <div className="field mb-6">
                  <label className="label">
                    {outsideOpeningHoursTextLabel}
                  </label>

                  <div className="control">
                    <input
                      name="outsideOpeningHoursText"
                      id="outsideOpeningHoursText"
                      className={classNames('input', {
                        'is-danger': errors.outsideOpeningHoursText,
                      })}
                      ref={register}
                      type="text"
                    />
                  </div>
                </div>

                <hr />

                <div className="mb-6">
                  <h3 className="title is-6">{openingHoursLabel}</h3>

                  {weekDayFields.map((item, index) => (
                    <div key={item.id} className="field is-grouped is-align-items-center">
                      <div className="control">
                        <div className={classNames('select', {
                            'is-danger': errors?.openingHoursWeekDays?.[index]?.day,
                          })}>
                          <select
                            name={`openingHoursWeekDays[${index}].day`}
                            ref={register()}
                            defaultValue={item.day}
                          >
                            <option value="">{chooseDayWeekLabel}</option>
                            { weekDays.map(day => (
                                <option key={day.value} value={day.value}>{day.label}</option>
                              ))
                            }
                          </select>
                        </div>
                      </div>

                      <div className="control">
                        <Controller
                          control={control}
                          name={`openingHoursWeekDays[${index}].timeFrom`}
                          defaultValue={item.timeFrom}
                          render={({ onChange, name, value }) => (
                            <DatePicker
                              className={classNames('input', {
                                'is-danger': errors?.openingHoursWeekDays?.[index]?.timeFrom,
                              })}
                              placeholderText={timeFromPlaceholder}
                              name={name}
                              onChange={onChange}
                              selected={value ? new Date(value) : ''}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={10}
                              dateFormat="HH:mm"
                              timeFormat="HH:mm"
                            />
                          )}
                        />
                      </div>

                      <div className="control">
                        <Controller
                          control={control}
                          name={`openingHoursWeekDays[${index}].timeTo`}
                          defaultValue={item.timeTo}
                          render={({ onChange, name, value }) => (
                            <DatePicker
                              className={classNames('input', {
                                'is-danger': errors?.openingHoursWeekDays?.[index]?.timeTo,
                              })}
                              placeholderText={timeToPlaceholder}
                              name={name}
                              onChange={onChange}
                              selected={value ? new Date(value) : ''}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={10}
                              dateFormat="HH:mm"
                              timeFormat="HH:mm"
                            />
                          )}
                        />
                      </div>                   

                      <div className="control">
                        <button
                          type="button"
                          className="button is-danger"
                          onClick={() => removeWeekDay(index)}
                        >
                          <span className="icon">
                            <i className="mdi mdi-trash-can" />
                          </span>
                        </button>
                      </div>
                    </div>
                  ))}

                  <div className="field">
                    <button
                      className="button"
                      type="button"
                      onClick={() => appendWeekDay({ day: "", timeFrom: "", timeTo: "" })}
                    >
                      {addLabel}
                    </button>
                  </div>
                </div>

                <hr />

                <div className="mb-6">
                  <h3 className="title is-6">{specialOpeningHoursLabel}</h3>

                  {specialDayFields.map((item, index) => (
                    <div key={item.id} className="field is-grouped is-align-items-center">
                      <div className="control">
                        <input
                          name={`openingHoursSpecialDays[${index}].title`}
                          ref={register()}
                          placeholder={titlePlaceholder}
                          defaultValue={item.title}
                          className={classNames('input', {
                            'is-danger': errors?.openingHoursSpecialDays?.[index]?.title,
                          })}
                        />
                      </div>
                      <div className="control">
                        <input
                          name={`openingHoursSpecialDays[${index}].singleTitle`}
                          ref={register()}
                          placeholder={singleNamePlaceholder}
                          defaultValue={item.singleTitle}
                          className={classNames('input', {
                            'is-danger': errors?.openingHoursSpecialDays?.[index]?.singleTitle,
                          })}
                        />
                      </div>

                      <div className="control">
                        <Controller
                          control={control}
                          name={`openingHoursSpecialDays[${index}].date`}
                          defaultValue={item.date}
                          render={({ onChange, name, value }) => (
                            <DatePicker
                              className={classNames('input', {
                                'is-danger': errors?.openingHoursSpecialDays?.[index]?.date,
                              })}
                              placeholderText={datePlaceholder}
                              name={name}
                              onChange={onChange}
                              selected={value ? new Date(value) : ''}
                              minDate={new Date()}
                            />
                          )}
                        />
                      </div> 

                      <div className="control">
                        <Controller
                          control={control}
                          name={`openingHoursSpecialDays[${index}].timeFrom`}
                          defaultValue={item.timeFrom}
                          render={({ onChange, name, value }) => (
                            <DatePicker
                              className={classNames('input', {
                                'is-danger': errors?.openingHoursSpecialDays?.[index]?.timeFrom,
                              })}
                              placeholderText={timeFromPlaceholder}
                              name={name}
                              onChange={onChange}
                              selected={value ? new Date(value) : ''}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={10}
                              dateFormat="HH:mm"
                              timeFormat="HH:mm"
                            />
                          )}
                        />
                      </div>

                      <div className="control">
                        <Controller
                          control={control}
                          name={`openingHoursSpecialDays[${index}].timeTo`}
                          defaultValue={item.timeTo}
                          render={({ onChange, name, value }) => (
                            <DatePicker
                              className={classNames('input', {
                                'is-danger': errors?.openingHoursSpecialDays?.[index]?.timeTo,
                              })}
                              placeholderText={timeToPlaceholder}
                              name={name}
                              onChange={onChange}
                              selected={value ? new Date(value) : ''}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={10}
                              dateFormat="HH:mm"
                              timeFormat="HH:mm"
                            />
                          )}
                        />
                      </div>                        

                      <div className="control">
                        <button
                          type="button"
                          className="button is-danger"
                          onClick={() => removeSpecialDay(index)}
                        >
                          <span className="icon">
                            <i className="mdi mdi-trash-can" />
                          </span>
                        </button>
                      </div>
                    </div>
                  ))}

                  <div className="field">
                    <button
                      className="button"
                      type="button"
                      onClick={() => appendSpecialDay({ title: "", singleTitle:"", date: "", timeFrom: "", timeTo: "" })}
                    >
                      {addLabel}
                    </button>
                  </div>
                </div>

                <hr />

                { AccessToEditSettings && 
                  <div className="field is-horizontal">
                    <div className="field-body">
                      <div className="field">
                        <div className="field is-grouped">
                          <div className="control">
                            <button
                              type="submit"
                              className={`button blue-button ${
                                loading && 'is-loading'
                              }`}
                            >
                              <span>{useFormatMessage('Settings.submit')}</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OpeningHoursForm;