import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Loader from 'components/Loader';
import { checkAccess } from 'permissions';
import { useFormatMessage } from 'hooks';
import { convertDateZeroHour } from 'utils';
import moment from 'moment';
import Table from 'components/Table';
import { fetchCoupons, couponsCleanUp, deleteCoupon } from 'state/actions/coupons';
import paths from 'pages/Router/paths';
import DatePicker from 'react-datepicker';
import ConfirmationModal from 'components/ConfirmationModal';
import classes from './Coupons.module.scss';

const Coupons = ({ onParamsChange }) => {
  const { couponsList, isAdmin, error, loading, deleted, stores, usersCoupons} = useSelector(
    (state) => ({
      couponsList: state.coupons.data,
      isAdmin: state.auth.userData.isAdmin,
      error: state.coupons.error,
      loading: state.coupons.loading,
      deleted: state.coupons.deleted,
      stores: state.stores.data,
      usersCoupons: state.coupons.usersCoupons,
    }),
    shallowEqual
  );

  const [filterStartDate, setFilterStartDate] = useState();
  const [filterEndDate, setFilterEndDate] = useState();
  const [filterStartDateCount, setFilterStartDateCount] = useState();
  const [filterEndDateCount, setFilterEndDateCount] = useState();
  const [filterStore, setFilterStore] = useState();
  
  const onChangeStartDate = (value) => {
    const startDate = value ? new Date(value) : '';
    setFilterStartDate(startDate);
  };

  const onChangeEndDate = (value) => {
    const endDate = value ? new Date(value) : '';
    setFilterEndDate(endDate);
  };

  const onChangeStartDateCount = (value) => {
    const startDate = value ? new Date(value) : '';
    setFilterStartDateCount(startDate);
  };

  const onChangeEndDateCount = (value) => {
    const endDate = value ? new Date(value) : '';
    setFilterEndDateCount(endDate);
  };

  const [deleteModal, setDeleteModal] = useState({
    couponId: null,
    isOpen: false,
  });

  const dispatch = useDispatch();

  const [search, setSearch] = useState('');

  useEffect(() => {
    dispatch(fetchCoupons());

    return () => dispatch(couponsCleanUp());
  }, [dispatch, isAdmin]);

  useEffect(() => {
    if (deleted && !loading) {
      setDeleteModal((prevState) => ({
        couponId: null,
        isOpen: !prevState.isOpen,
      }));
    }
  }, [deleted, loading]);


  const onRemoveButtonClickHandler = (couponId) => {
    setDeleteModal((prevState) => ({
      couponId,
      isOpen: !prevState.isOpen,
    }));
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ couponId: null, isOpen: false });
  };

  const onDeleteCouponHandler = () => {
    dispatch(deleteCoupon(deleteModal.couponId));
  };

  const columns = [
    {
      Header: useFormatMessage('Coupons.name'),
      accessor: 'name',
      Cell: ({ row }) => (
        <>
          { checkAccess('view coupon') ? (
            <Link to={`/coupons/${row.original.id}`}>
              { row.original.name }
            </Link>
            ) : row.original.name 
          }
        </>
      )
    },
    {
      Header: useFormatMessage('Coupons.store'),
      accessor: 'store',
      Cell: ({ row }) => (
        <span>
          {row.original.store ?
            <Link to={`/stores/${row.original.store}`}>
              { stores.filter((store) => store.id === row.original.store).map((store) => (<span key={store.id}>{store.name}</span>)
              ) }
            </Link>          
          :
            row.original.storeName
          }
        </span>
      )
    },
    {
      Header: useFormatMessage('Coupons.usedCount'),
      accessor: 'usedCount',
    },
    {
      Header: useFormatMessage('Coupons.startDate'),
      accessor: 'startDate',
      sortType: (a, b) => {
        return new Date(b.values.startDate) - new Date(a.values.startDate);
      },
      Cell: ({ row }) => (
        <>
          {moment(row.original.startDate).format("DD.MM.YYYY")}
        </>
      ),
    },
    {
      Header: useFormatMessage('Coupons.endDate'),
      accessor: 'endDate',
      sortType: (a, b) => {
        return new Date(b.values.endDate) - new Date(a.values.endDate);
      },
      Cell: ({ row }) => (
        <>
          {moment(row.original.endDate).format("DD.MM.YYYY")}
        </>
      ),
    },
  ];

  const actionCell = {
    Header: 'Actions',
    id: 'actions',
    accessor: 'id',
    Cell: ({ row }) => (
      <>
        <div className="buttons is-right">
          <Link
            to={`/coupons/${row.original.id}`}
            className="button is-small is-primary"
          >
            <span className="icon is-small">
              <i className="mdi mdi-pencil" />
            </span>
          </Link>

          {checkAccess(`delete coupon`) && (
            <button
              type="button"
              className="button is-small is-danger"
              onClick={() => onRemoveButtonClickHandler(row.original.id)}
            >
              <span className="icon is-small">
                <i className="mdi mdi-trash-can" />
              </span>
            </button>
          )}
        </div>
      </>
    ),
    disableSortBy: true,
  };

  if (checkAccess('view coupon')) columns.push(actionCell);

  const accessToAddCoupon = checkAccess('create coupon');
  const deleteMessage = useFormatMessage('Coupons.delete');
  const confirmMessage = useFormatMessage('Coupons.confirm');
  const permDeleteMessage = useFormatMessage('Coupons.permDelete');
  const cancelMessage = useFormatMessage('Coupons.cancel');

  let data = search
    ? couponsList.filter((el) => {
        const clonedElem = { ...el };
        delete clonedElem.id;
        delete clonedElem.logoUrl;
        return Object.values(clonedElem).some((field) =>
          field.toString().toLowerCase().includes(search.toLowerCase())
        );
      })
    : couponsList;

  data = filterStore
    ? data.filter((el) => el.store === filterStore)
    : data;

  function convertDateToUTC(date) { 
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()); 
  }

  data = filterStartDate
    ? data.filter((el) => new Date(el.startDate) >= filterStartDate)
    : data;

  data = filterStartDateCount
  ? data.map((el) => {
      const count = usersCoupons.reduce((activationsSum, userActivations) => {
        const countByUser = userActivations.coupons.reduce((userActivationCount, activation) => {
          if(activation.couponId === el.id && convertDateZeroHour(filterStartDateCount) <= convertDateZeroHour(activation.activateDate)){
            if(filterEndDateCount){
              if(convertDateZeroHour(filterEndDateCount) >= convertDateZeroHour(activation.activateDate)){
                return userActivationCount + 1;
              }
            }
            else{
              return userActivationCount + 1;
            }
          }

          return userActivationCount;
        }, 0);

        return activationsSum + countByUser;
      }, 0);

      return ({
        ...el,
        usedCount: count
      });
    })
  : data;

  data = filterEndDate
    ? data.filter((el) => convertDateToUTC(new Date(el.endDate)) <= filterEndDate)
    : data;

  data = filterEndDateCount
  ? data.map((el) => {
      const count = usersCoupons.reduce((activationsSum, userActivations) => {
        const countByUser = userActivations.coupons.reduce((userActivationCount, activation) => {
          if(activation.couponId === el.id && convertDateZeroHour(filterEndDateCount) >= convertDateZeroHour(activation.activateDate))
            if(filterStartDateCount){
              if(convertDateZeroHour(filterStartDateCount) <= convertDateZeroHour(activation.activateDate)){
                return userActivationCount + 1;
              }
            }
            else{
              return userActivationCount + 1;
            }

          return userActivationCount;
        }, 0);

        return activationsSum + countByUser;
      }, 0);

      return ({
        ...el,
        usedCount: count
      });
    })
  : data;

  const activationsCount = data.reduce((partialSum, a) => partialSum + a.usedCount, 0);

  const titleSection = useFormatMessage('Coupons.coupons');

  useEffect(() => {
    if (onParamsChange) {
      onParamsChange({ title: titleSection });
    }
  }, []);

  return (
    <>
      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={loading}
          confirmButtonMessage={deleteMessage}
          title={confirmMessage}
          body={permDeleteMessage}
          cancelButtonMessage={cancelMessage}
          onConfirmation={onDeleteCouponHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      {accessToAddCoupon &&
        <section className="hero is-hero-bar">
          <div className="hero-body">
            <div className="level">
              <div className="level-right">
                <div className="level-item">
                  <Link to={paths.ADD_COUPON} className="button">
                    {useFormatMessage('Coupons.newCoupon')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      }
      <section className="section is-main-section">
        <div className="card has-table has-mobile-sort-spaced">
          <header className="card-header">
            <div className='card-header-title has-text-weight-normal is-flex-direction-column is-align-items-flex-start'>

                <div className="field is-horizontal">
                  <div className="field-body">
                    <div className="field is-horizontal is-align-items-center">
                      <div className="field-label">
                        <label htmlFor="search" className="label">
                          {useFormatMessage('Coupons.search')}
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="control">  
                          <input
                            id="search"
                            type="text"
                            className="input"
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal is-align-items-center">
                      <div className="field-body">
                        <div className="control">
                          <div className="select">
                            <select
                              id="store"
                              value={filterStore}
                              onChange={(e) => setFilterStore(e.target.value)}
                            >
                              <option value="">Select Store</option>
                              { stores && stores.length > 0 &&
                                stores.map(item => (
                                  <option key={item.id} value={item.id}>{item.name}</option>
                                ))
                              }
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal is-align-items-center">
                      <div className="field-body">
                        <div className="control">
                          <DatePicker
                            id="start-date"
                            name='filterStartDate'
                            onChange={value => onChangeStartDate(value)}
                            selected={filterStartDate}
                            placeholderText="Start Date"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="field is-horizontal is-align-items-center">
                      <div className="field-body">
                        <div className="control">
                          <DatePicker
                            id="end-date"
                            name='filterEndDate'
                            onChange={value => onChangeEndDate(value)}
                            selected={filterEndDate}
                            placeholderText="End Date"
                          />
                        </div>
                      </div>
                    </div>
                  </div>  
                </div>

                <div className="field is-horizontal is-align-items-center">
                  <div className="field-label">
                    <p className={classNames('label', classes.textNoBreak)}>Count activations in period:</p>
                  </div>

                  <div className="field-body is-align-items-center">
                    <div className="field">
                      <div className="control">
                        <DatePicker
                          id="start-date-count"
                          name='filterStartDateCount'
                          onChange={value => onChangeStartDateCount(value)}
                          selected={filterStartDateCount}
                          placeholderText="Start Date"
                        />
                      </div>
                    </div>

                    <div className="field">
                      <div className="control">
                        <DatePicker
                          id="end-date-count"
                          name='filterEndDateCount'
                          onChange={value => onChangeEndDateCount(value)}
                          selected={filterEndDateCount}
                          placeholderText="End Date"
                        />
                      </div>
                    </div>

                    <div className="field">
                      <p className={classNames('control', classes.textNoBreak)}>{`Count: ${ activationsCount }`}</p>
                    </div>

                  </div>


              </div>
            </div>
          </header>
          <div className="b-table">
            {loading ? <Loader /> : <Table columns={columns} data={data} />}
            {error && 'Show error'}
          </div>
        </div>
      </section>
    </>
  );
};

export default Coupons;
