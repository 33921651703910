import React, { useEffect, useState, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { updateDocument } from 'state/api';
import { useLocation, Link } from "react-router-dom";
import { useSelector, shallowEqual } from 'react-redux';
import { fetchNotifications, getNumberReadNotifications, getNumberNotifications, allRead } from 'state/actions/notifications';
import moment from 'moment';
import classNames from 'classnames';
import classes from './NotificationMenu.module.scss';

const NotificationMenu = () => {
  const { user } = useSelector(
    (state) => ({
      user: state.auth.userData
    }),
    shallowEqual
  );

  const { pathname } = useLocation();
  const [hasMore, setHasMore] = useState(true);
  const [readProcess, setReadProcess] = useState(false);
  const [notReadNoteCount, setNotReadNoteCount] = useState(0);
  const [showNoteMenu, setShowNoteMenu] = useState(false);
  const [showMarkAllRead, setShowMarkAllRead] = useState(false);
  const [data, setData] = useState([]);
  const [numberNotifications, setNumberNotifications] = useState(0);
  const itemsCount = 20;

  const readNote = async (note) => {
    const { read, id } = note;
    const noteUpdate = {...note};
    delete noteUpdate.id;

    if(!read || !read.includes(user.id)){
      if(!read){
        noteUpdate.read = [user.id];
      }
      else if(!read.includes(user.id)){
        noteUpdate.read.push(user.id);
      }

      setData(prevState => {
        const indexNote = prevState.findIndex(el => el.id === id);
        const updatedNotes = [...prevState];
        updatedNotes[indexNote].read = noteUpdate.read;

        return updatedNotes;
      });

      try{
        await updateDocument('comments', id, noteUpdate);

        setNotReadNoteCount(prevState => {
          return prevState - 1;
        });
      }
      catch(err){
        console.log('error');
      }
    }
  };


  const getNotifications = useCallback(async () => {
    const notificationsResponse = await fetchNotifications({limit: itemsCount});

    setData(notificationsResponse);
  }, []);


  const getNumberUnreadNotifications = useCallback(async () => {
    const numberReadNotificationsResponse = await getNumberReadNotifications({uid: user.id});
    const numberNotificationsResponse = await getNumberNotifications();

    setNotReadNoteCount(numberNotificationsResponse - numberReadNotificationsResponse);
    setNumberNotifications(numberNotificationsResponse);

  }, []);

  const markAllReadHandler = async () => {
    setReadProcess(true);

    await allRead({ uid:user.id });
    await getNotifications();
    await getNumberUnreadNotifications();

    setReadProcess(false);
  };

  useEffect(() => {
    getNotifications().catch(console.error);

  }, [getNotifications]);

  useEffect(() => {
    getNumberUnreadNotifications().catch(console.error);
      
  }, [getNumberUnreadNotifications]);

  useEffect(() => {
    if(data.length <= numberNotifications){
      setHasMore(true);
    }
    else{
      setHasMore(false);
    }

    if(notReadNoteCount === 0){
      setShowMarkAllRead(false);
    }
    else{
      setShowMarkAllRead(true);
    }
  }, [data, pathname]);

  const loadMore = async() => {
    const lastNote = data[data.length - 1];
    const newNotes = await fetchNotifications({limit: itemsCount, cursorId: lastNote.id, direction: 'next'});

    if(newNotes.length > 0 && data.length < numberNotifications){
      setData(data.concat(newNotes));
    }
    else{
      setHasMore(false);
    }
  };

  const onClickNote = async (note) => {
    setShowNoteMenu(false);
    await readNote(note);
  };

  return (
    <div 
      className='navbar-item has-dropdown has-divider'
      onMouseEnter={() => {
        setShowNoteMenu(true);
      }}
      onMouseLeave={() => {
        setShowNoteMenu(false);
      }}
    >
      <a className="navbar-link is-arrowless">
        <span className={classNames(classes.noteIconContainer)}>
          <span className={classNames('icon', classes.noteIcon)}>
            <i className="mdi mdi-bell-ring" />
          </span>
          {notReadNoteCount > 0 &&
            <span className={classNames(classes.noteCounter, 'has-background-danger', 'is-size-7', 'has-text-white')}>{ notReadNoteCount }</span>
          }
        </span>
      </a>
      <div className={classNames('navbar-dropdown is-right py-0', classes.noteDropDown, {"is-block": showNoteMenu, [classes.readProcess]: readProcess})}>
        {showMarkAllRead &&
          <div className={classNames(classes.allReadConatiner)}>
            <button 
              className="button is-small is-info"
              onClick={markAllReadHandler}
            >
                Mark all read
            </button>
          </div>
        }
        
        <InfiniteScroll
          key={pathname}
          dataLength={data.length}
          next={loadMore}
          hasMore={hasMore}
          height={300}
          loader={<h4 className="has-text-centered">Loading...</h4>}
          className={classNames(classes.NotificationsContainer)}
        >
          {data.length > 0 ?
            <ul>
              {data.map(el => (
                <li key={el.id}>
                  <Link
                    className={classNames({ [classes.read]: el.read && el.read.includes(user.id) })} 
                    to={`/orders/${el.entityId}#comment-${el.id}`}
                    onClick={() => onClickNote(el)}
                    >
                      { el.commentType === 'missing_product' ?
                        <>
                          Missing Product. Order <span>#{el.shortEntityId}</span> on {moment(el.createdAt).format("DD.MM.YYYY - HH.mm")}
                        </>
                      :
                        <>
                          New comment was added to order <span>#{el.shortEntityId}</span> on {moment(el.createdAt).format("DD.MM.YYYY - HH.mm")}
                        </>
                      }
                    </Link>
                </li>
              ))}
            </ul>
          :
            <div className={classNames(classes.emptyNoteContainer)}>
              <p className="title is-6 is-spaced">Notifications not found</p>
            </div>
          }
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default NotificationMenu;