import { toastr } from 'react-redux-toastr';

import {
  fetchCollection,
  fetchDocument,
  addDocument,
  deleteDocument,
  updateDocument,
} from '../api';

export const fetchComments = async({ entityId = '', commentId = '', commentType = [] }) => {
  let comments = [];

  if (commentId) {
    let comment;
    try {
      comment = await fetchDocument('comments', commentId);
      comments.push(comment);
    } catch (error) {
      toastr.error('', error);
    }

    if (!comment) {
      const errorMessage = 'Comment not available';
      toastr.error('', errorMessage);
    }
  }
  else{
    try {
      const queryOptions = {
        sort: [
          {
            'attribute': 'createdAt',
            'order': 'desc'
          }
        ],
        queries: []
      };

      if (commentType.length) {
        queryOptions.queries.push({
          'attribute': 'commentType',
          'operator': 'in',
          'value': commentType
        });
      }

      if (entityId) {
        queryOptions.queries.push({
          'attribute': 'entityId',
          'operator': '==',
          'value': entityId
        });
      }

      comments = await fetchCollection('comments', queryOptions);
    } catch (error) {
      toastr.error('', error);
    }
  }

  return comments;
};

export const deleteComment = async(id) => {
  try {
    await deleteDocument('comments', id);
  } catch (error) {
    toastr.error('', error);
  }

  return id;
};

export const createComment = async({
  content,
  createdAt,
  uid,
  commentType,
  entityId,
  shortEntityId,
  read = []
}) => {
  const commentData = {
    content,
    createdAt,
    uid,
    commentType,
    entityId,
    shortEntityId,
    read
  };

  let commentId;
  
  try {
    const response = await addDocument('comments', commentData);
    if (response.id) {
      commentId = response.id;
    }
  } catch (error) {
    toastr.error('', error.message);
  }

  return commentId;
};

export const modifyComment = async({
  content,
  createdAt,
  uid,
  commentType,
  entityId,
  id,
  shortEntityId,
  read = []
}) => {
  const commentData = {
    content,
    createdAt,
    uid,
    commentType,
    entityId,
    shortEntityId,
    read
  };
  
  try {
    await updateDocument('comments', id, commentData);
    toastr.success('', 'Comment updated successfully');
  } catch (error) {
    toastr.error('', error.message);
  }
};