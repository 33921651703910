import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';
import {
  fetchCollection,
  fetchDocument
} from '../api';

export const INTEGRATOR_COLORS_FETCH_DATA_INIT = createAction('INTEGRATOR_COLORS_FETCH_DATA_INIT');
export const INTEGRATOR_COLORS_FETCH_DATA_SUCCESS = createAction(
  'INTEGRATOR_COLORS_FETCH_DATA_SUCCESS'
);
export const INTEGRATOR_COLORS_FETCH_DATA_FAIL = createAction('INTEGRATOR_COLORS_FETCH_DATA_FAIL');
export const INTEGRATOR_COLORS_CLEAN_UP = createAction('INTEGRATOR_COLORS_CLEAN_UP');
export const INTEGRATOR_COLORS_CLEAR_DATA_LOGOUT = createAction('INTEGRATOR_COLORS_CLEAR_DATA_LOGOUT');

export const fetchIntegratorColors = (colorId = '') => {
  return async (dispatch, getState) => {
    dispatch(INTEGRATOR_COLORS_FETCH_DATA_INIT());

    if (colorId) {
      let color;
      
      try {
        color = await fetchDocument('integrator_colors', colorId);
      } catch (error) {
        toastr.error('', error);
        return dispatch(INTEGRATOR_COLORS_FETCH_DATA_FAIL({ error }));
      }

      if (!color) {
        const errorMessage = 'Color not available';
        toastr.error('', errorMessage);
        return dispatch(INTEGRATOR_COLORS_FETCH_DATA_FAIL({ error: errorMessage }));
      }

      const colors = getState().integratorColors.data;
      colors.push(color);

      return dispatch(
        INTEGRATOR_COLORS_FETCH_DATA_SUCCESS({
          data: colors
        })
      );
    }

    const observeIntegratorColors = (updatedIntegratorColors) => {
      return dispatch(
        INTEGRATOR_COLORS_FETCH_DATA_SUCCESS({
          data: updatedIntegratorColors,
        })
      );
    };

    let colors;

    try {
      const queryOptions = {};

      colors = await fetchCollection('integrator_colors', queryOptions, observeIntegratorColors);

    } catch (error) {
      return dispatch(INTEGRATOR_COLORS_FETCH_DATA_FAIL({ error }));
    }

    if (!colors) {
      const errorMessage = 'Colors not found';
      toastr.info('', errorMessage);
    }

    return dispatch(
      INTEGRATOR_COLORS_FETCH_DATA_SUCCESS({
        data: colors
      })
    );
  };
};

export const clearIntegratorColorsDataLogout = () => {
  return (dispatch) => {
    dispatch(INTEGRATOR_COLORS_CLEAR_DATA_LOGOUT());
  };
};

export const integratorColorsCleanUp = () => (dispatch) => dispatch(INTEGRATOR_COLORS_CLEAN_UP());