import React, { useEffect, useMemo } from 'react';
import { Link, useParams, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import Loader from 'components/Loader';
import * as yup from 'yup';

import UserForm from 'components/UserForm';
import { createUser, modifyUser, fetchUsers } from 'state/actions/users';
import { fetchStores } from 'state/actions/stores';
import paths from 'pages/Router/paths';
import { useFormatMessage } from 'hooks';

const User = ({ onParamsChange }) => {
  const { id } = useParams();
  const isEditing = useMemo(() => !!id, [id]);

  const { success, userData, error, stores, auth} = useSelector(
    (state) => ({
      success: state.users.success,
      userData: state.users.data.find((user) => user.id === id),
      error: state.users.error,
      stores: state.stores.data,
      auth: state.auth.userData
    }),
    shallowEqual
  );

  const schema = yup.object().shape({
    email: yup.string().email().required(),
    name: yup.string().required(),
    role: yup.string().when('isAdmin', {
      is: true,
      then: yup.string().notRequired(),
      otherwise: yup.string().required(),
    }),
    isAdmin: yup.boolean().notRequired(),
    location: yup.string().notRequired(),
    createdAt: yup.string().required(),
    password: yup.string().when([], {
      is: () => !isEditing,
      then: yup.string().min(6).required("Password must be at least 6 characters"),
      otherwise: yup.string().notRequired(),
    }),
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEditing) {
      
      if (!userData) {
        dispatch(fetchUsers(id));
      }
    }
  }, [isEditing, id, userData, dispatch]);

  useEffect(() => {
    if (!stores) {
      dispatch(fetchStores());
    }
  }, [dispatch]);

  let redirectPath = null;

  if(auth && auth.id === id) {
    redirectPath = paths.PROFILE;
  }

  if((isEditing && error) || success) {
    redirectPath = paths.USERS;
  }

  const redirect = redirectPath && (
    <Redirect to={redirectPath} />
  );

  let titleSection = useFormatMessage('User.newUser');

  if(isEditing){
    if ( userData && userData?.role === 'Store'){
      titleSection = useFormatMessage('User.editStore');
    }
    else{
      titleSection = useFormatMessage('User.editUser');
    }
  }

  useEffect(() => {
    if (onParamsChange) {
      onParamsChange({ title: titleSection });
    }
  }, []);

  const goBackMessage = useFormatMessage('UserForm.goBack');

  const onSubmitHandler = (value) => {
    const newValue = { ...value };

    const additionalData = { ...userData?.additionalData } || {};
    additionalData.stores = newValue.stores?.map((store) => store.value) || [];

    const newUser = {
      ...newValue,
      createdAt: new Date(newValue.createdAt).toDateString(),
      additionalData,
      file: value?.file[0] || null,
      isEditing,
      id,
    };

    if (isEditing) {
      dispatch(modifyUser(newUser));
    } else {
      dispatch(createUser(newUser));
    }
  };

  return (
    <>
      {redirect}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <Link to={paths.USERS} className="button has-icon">
            <span className="icon">
              <i className="mdi mdi-arrow-left"/>
            </span>
            {goBackMessage}
          </Link>
        </div>
      </section>
      <section className="section is-main-section">
        {isEditing && !userData ? (
          <Loader />
        ) : (
          <UserForm
            isEditing={isEditing}
            user={
              isEditing
                ? userData
                : {
                    name: '',
                    email: '',
                    location: '',
                    createdAt: new Date().toDateString(),
                    isAdmin: false,
                    role: '',
                    stores: []
                  }
            }
            onSubmitHandler={onSubmitHandler}
            schema={schema}
          />
        )}
      </section>
    </>
  );
};

export default User;
