/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import classNames from 'classnames';
import { yupResolver } from '@hookform/resolvers';
import { checkAccess } from 'permissions';
import ROLES from 'permissions/roles';
import Select from "react-select";
import { usersCleanUp } from 'state/actions/users';
import { useFormatMessage } from 'hooks';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import ErrorMessage from 'components/ErrorMessage';
import UpdatePassword from 'components/UpdatePassword';
import {
  authByUid
} from 'state/actions/auth';

import './UserForm.scss';

const UserForm = ({ isEditing, isProfile, user, onSubmitHandler, schema }) => {
  const { loading, success, isAdmin, stores, authLoading } = useSelector(
    (state) => ({
      loading: state.users.loading,
      success: state.users.success,
      isAdmin: state.auth.userData.isAdmin,
      authLoading: state.auth.loading,
      stores: state.stores.data,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const defaultValues = {
    createdAt: user.createdAt || new Date().toDateString(),
    location: user.location || '',
    name: user.name || '',
    email: user.email || '',
    isAdmin: user.isAdmin || false,
    role: user.role || '',
    stores: user?.additionalData?.stores?.map((storeID) => ({value: storeID, label: stores.find(item => item.id === storeID)?.name})) || []
  };

  const { register, handleSubmit, errors, control, watch, setValue, reset } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (success) {
      setValue('file', null);
    }
    return () => dispatch(usersCleanUp());
  }, [dispatch, success, setValue]);

  useEffect(() => {
    reset(defaultValues);
  }, [user]);


  const invalidEmailMessage = useFormatMessage('UserForm.invalidEmail');

  let imagePreviewUrl =
    watch('file') && watch('file')[0]
      ? URL.createObjectURL(watch('file')[0])
      : user.logoUrl;

  if(imagePreviewUrl){
    const imagePreviewUrlObj = new URL(imagePreviewUrl);
    if(imagePreviewUrlObj.protocol !== 'blob:'){
      const urlSearchParams = imagePreviewUrlObj.searchParams;
      urlSearchParams.set('version', Date.now());
      imagePreviewUrlObj.search = urlSearchParams.toString();
      imagePreviewUrl = imagePreviewUrlObj.toString();
    }
  }

  const userRole = watch('role', '');
  const isAdminCurrentValue = watch('isAdmin', '');

  const pickAnotherFileMessage = useFormatMessage('UserForm.pickAnotherFile');
  const pickFileMessage = useFormatMessage('UserForm.pickFile');

  const emailMessage = useFormatMessage('UserForm.email');
  const roleMessage = useFormatMessage('UserForm.role');
  const adminMessage = useFormatMessage('UserForm.admin');

  const infoLabel = userRole === 'Store' ? useFormatMessage('UserForm.storeInfo') : useFormatMessage('UserForm.userInfo');
  const previewLabel = userRole === 'Store' ? useFormatMessage('UserForm.storePreview') : useFormatMessage('UserForm.userPreview');
  const selectStoreLabel = useFormatMessage('UserForm.store');
  const loginAsLabel = useFormatMessage('UserForm.loginAs');

  const accessCreateAdmin = checkAccess(`create ${ROLES.ADMIN} user`);
  const accessCreateMallManager = checkAccess(`create ${ROLES.MALL_MANAGER} user`);
  const accessCreateStore = checkAccess(`create ${ROLES.STORE} user`);
  const accessCreateServiceDesk = checkAccess(`create ${ROLES.SERVICE_DESK} user`);
  const accessCreateCustomer = checkAccess(`create ${ROLES.CUSTOMER} user`);

  const accessEditStore = checkAccess(`edit ${ROLES.STORE} user`);

  const roles = {};

  if (accessCreateStore) roles[ROLES.STORE] = ROLES.STORE;
  if (accessCreateMallManager) roles[ROLES.MALL_MANAGER] = ROLES.MALL_MANAGER;
  if (accessCreateServiceDesk) roles[ROLES.SERVICE_DESK] = ROLES.SERVICE_DESK;
  if (accessCreateCustomer) roles[ROLES.CUSTOMER] = ROLES.CUSTOMER;

  const loginAs = (e) => {
    e.preventDefault();
    dispatch(authByUid(user.id));
  };

  return (
    <>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-account-edit default" />
                </span>
                { infoLabel }
              </p>
            </header>
            <div className="card-content">
              <form onSubmit={handleSubmit(onSubmitHandler)}>
                {isEditing ? (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">{emailMessage}</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <input
                            type="text"
                            readOnly="readOnly"
                            className="input is-static"
                            name="email"
                            ref={register}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">{emailMessage}</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              className={classNames(`input`, {
                                'is-danger': errors.email,
                              })}
                              ref={register}
                              name="email"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {errors.email && (
                      <div className="field is-horizontal">
                        <div className="field-label is-normal" />
                        <div className="field-body">
                          <ErrorMessage text={invalidEmailMessage} />
                        </div>
                      </div>
                    )}

                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">{useFormatMessage('UserForm.password')}</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <input
                              className={classNames(`input`, {
                                'is-danger': errors.password,
                              })}
                              type="password"
                              ref={register}
                              name="password"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {errors.password && (
                      <div className="field is-horizontal">
                        <div className="field-label is-normal" />
                        <div className="field-body">
                          <ErrorMessage text={useFormatMessage('UserForm.invalidPassword')} />
                        </div>
                      </div>
                    )}

                  </>
                )}

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('UserForm.name')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          name="name"
                          id="name"
                          className={classNames('input', {
                            'is-danger': errors.name,
                          })}
                          ref={register}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {errors.name && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('UserForm.location')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          className="input"
                          type="text"
                          ref={register}
                          name="location"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {!isProfile && accessCreateAdmin && (
                  <div className="field has-check is-horizontal">
                    <div className="field-label">
                      <label className="label">{adminMessage}</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="field">
                          <div className="control">
                            <label className="b-checkbox checkbox">
                              <input
                                type="checkbox"
                                name="isAdmin"
                                ref={register}
                              />
                              <span className="check is-primary" />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {!isProfile && !isAdminCurrentValue && (
                  <>
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">
                        { roleMessage }
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <select
                            name="role"
                            className={classNames('select', {
                              'is-danger': errors.name,
                            })}
                            ref={register}
                          >
                            { Object.keys(roles).map((role) => (
                              <option key={role} value={role}>
                                {role}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  {errors.role && (
                    <div className="field is-horizontal">
                      <div className="field-label is-normal" />
                      <div className="field-body">
                        <ErrorMessage />
                      </div>
                    </div>
                  )}
                  </>
                )}

                {isProfile && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal">
                      <label className="label">{roleMessage}</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="control">
                          <input
                            type="text"
                            readOnly="readOnly"
                            className="input is-static"
                            name="role"
                            ref={register}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                { userRole === 'Store' && (
                  <>
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">{ selectStoreLabel }</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <div className="select is-multiple is-block">
                              <Controller
                                as={Select}
                                options={stores?.map((store) => ({value: store.id, label: store.name}))}
                                name="stores"
                                isClearable
                                isDisabled={isProfile}
                                isMulti
                                control={control}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {errors.stores && (
                      <div className="field is-horizontal">
                        <div className="field-label is-normal" />
                        <div className="field-body">
                          <ErrorMessage />
                        </div>
                      </div>
                    )}
                  </>
                )}

                {isEditing ? (
                  <input 
                    type="hidden"
                    name="createdAt"
                    ref={register} />
                ) : (
                  <>
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">
                          {useFormatMessage('UserForm.created')}
                        </label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <Controller
                            control={control}
                            name="createdAt"
                            render={({ onChange, name, value }) => (
                              <DatePicker
                                selected={value ? new Date(value) : ''}
                                onChange={onChange}
                                className="input"
                                name={name}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>

                    {errors.createdAt && (
                      <div className="field is-horizontal">
                        <div className="field-label is-normal" />
                        <div className="field-body">
                          <ErrorMessage />
                        </div>
                      </div>
                    )}
                  </>
                )}

                <hr />

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('UserForm.logo')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="file has-name">
                        <label className="file-label">
                          <input
                            className="file-input"
                            type="file"
                            name="file"
                            ref={register}
                            accept="image/*"
                          />
                          <span className="file-cta">
                            <span className="file-icon">
                              <i className="mdi mdi-upload" />
                            </span>
                            <span className="file-label">
                              {watch('file') && watch('file').file
                                ? pickAnotherFileMessage
                                : pickFileMessage}
                            </span>
                          </span>
                          <span className="file-name">
                            {watch('file') && watch('file')[0]?.name}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <hr />
                <div className="field is-horizontal">
                  <div className="field-label" />
                  <div className="field-body">
                    <div className="field">
                      <div className="field is-grouped">
                        <div className="control">
                          <button
                            type="submit"
                            className={`button blue-button ${
                              loading && 'is-loading'
                            }`}
                          >
                            <span>{useFormatMessage('UserForm.submit')}</span>
                          </button>
                        </div>
                        {!isProfile &&
                          <>
                            {isEditing && userRole === 'Store' && accessEditStore &&
                              <div className="control">
                                <button 
                                  className={`button ${
                                    authLoading && 'is-loading'
                                  }`}
                                  onClick={loginAs}
                                >
                                  <span>{ loginAsLabel }</span>
                                </button>
                              </div>
                            }
                          </>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="tile is-parent preview">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-account default" />
                </span>
                { previewLabel }
              </p>
            </header>
            <div className="card-content">
              {imagePreviewUrl && (
                <>
                  <div className="is-user-avatar image has-max-width is-aligned-center">
                    <img
                      className="user-avatar"
                      src={imagePreviewUrl}
                      alt="User profile logo preview"
                    />
                  </div>
                  <hr />
                </>
              )}

              {!isEditing && (
                <div className="field">
                  <label className="label">{emailMessage}</label>
                  <div className="control is-clearfix">
                    <input
                      data-testid="email"
                      type="text"
                      readOnly="readOnly"
                      className="input is-static"
                      value={watch('email')}
                    />
                  </div>
                </div>
              )}

              <div className="field">
                <label className="label">
                  {useFormatMessage('UserForm.name')}
                </label>
                <div className="control is-clearfix">
                  <input
                    data-testid="name"
                    type="text"
                    readOnly="readOnly"
                    className="input is-static"
                    value={watch('name')}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">
                  {useFormatMessage('UserForm.location')}
                </label>
                <div className="control is-clearfix">
                  <input
                    data-testid="location"
                    type="text"
                    readOnly="readOnly"
                    className="input is-static"
                    value={watch('location')}
                  />
                </div>
              </div>

              {!isProfile && (
                <div className="field">
                  <label className="label">
                    { roleMessage }
                  </label>
                  <div className="control is-clearfix">
                    <input
                      data-testid="role"
                      type="text"
                      readOnly="readOnly"
                      className="input is-static"
                      value={!isAdminCurrentValue && userRole === 'Admin' ? '' : watch('role')}
                    />
                  </div>
                </div>
              )}

              <div className="field">
                <label className="label">
                  {useFormatMessage('UserForm.created')}
                </label>
                <div className="control is-clearfix" data-testid="date">
                  <p className="date">
                    {moment(new Date(watch('createdAt'))).format("DD.MM.YYYY")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      { (isAdmin && !isProfile && isEditing) && 
        <UpdatePassword id={user.id} />
      }
    </>
  );
};

export default UserForm;