import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { checkAccess } from 'permissions';
import { useFormatMessage } from 'hooks';
import moment from 'moment';
import TableNew from 'components/TableNew';
import { getStoreList, deleteStore } from 'state/actions/stores';
import paths from 'pages/Router/paths';
import ConfirmationModal from 'components/ConfirmationModal';
import classes from './Stores.module.scss';

const Stores = ({ onParamsChange }) => {
  const [additionalFilter, setAdditionalFilter] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [key, setKey] = useState(moment().unix());

  const { users} = useSelector(
    (state) => ({
      users: state.users.data,
    }),
    shallowEqual
  );

  const [deleteModal, setDeleteModal] = useState({
    couponId: null,
    isOpen: false,
  });
  const [search, setSearch] = useState('');

  const applyFilters = (params) => {
    let queryOptions = null;

    const {searchValue = null} = params;
    const searchQueryOptions = [];

    if(searchValue && searchValue.length >= 3){
      searchQueryOptions.push(
        [{
          'attribute': 'name',
          'operator': '>=',
          'value': searchValue
        },
        {
          'attribute': 'name',
          'operator': '<=',
          'value': `${searchValue}~`
        }]
      );
    }

    if(searchQueryOptions.length > 0){
      queryOptions = {
        queries: [],
        relationship: 'or'
      };

      searchQueryOptions.forEach(el => {
        queryOptions.queries.push({ queries: el, relationship: 'and' });
      });
    }
    
    setAdditionalFilter(queryOptions);
  };

  const searchHandle = (e) => {
    const searchValue = e.target.value;
    setSearch(searchValue);

    applyFilters({searchValue}); 
  };

  useEffect(() => {
    setDeleteModal({
      storeId: null,
      isOpen: false,
    });
  }, []);

  const onRemoveButtonClickHandler = (storeId) => {
    const userStore = users.find(item => item?.additionalData?.stores?.includes(storeId));

    if(userStore){
      toastr.error('', 'Unable to delete this store. Please detach all store users from this store.');
    }
    else{
      setDeleteModal((prevState) => ({
        storeId,
        isOpen: !prevState.isOpen,
      }));
    }
  };

  const onCloseModalHandler = () => {
    setDeleteModal({ storeId: null, isOpen: false });
  };

  const onDeleteStoreHandler = async() => {
    setDeleting(true);
    await deleteStore(deleteModal.storeId);
    setDeleting(false);
    setDeleteModal({ storeId: null, isOpen: false });
    if(search){
      setSearch('');
      applyFilters({searchValue: ''}); 
    }
    else{
      setKey(moment().unix());
    }
  };

  const columnsStructure = [
    {
      accessorFn: (row) => row.name,
      id: "store.name",
      header: useFormatMessage('Stores.name'),
      cell: ({ row }) => (
        <>
          {checkAccess(`view store`) ? (
            <Link to={`/stores/${row.original.id}`}>
              {row.original.name}
            </Link>
          )
          :
            row.original.name
          }
        </>
      )
    },
    {
      accessorFn: (row) => row.createdAt,
      id: "store.createdAt",
      header: useFormatMessage('Stores.createdDate'),
      cell: (info) => {
        const value = info.getValue();

        return value ? moment(new Date(value)).format("DD.MM.YYYY") : null;
      }
    },
  ];

  const actionCell =  {
    accessorFn: (row) => row.id,
    id: "store.actions",
    header: '',
    cell: ({ row }) => (
      <div className="buttons is-right">
        <Link
          to={`/stores/${row.original.id}`}
          className="button is-small is-primary"
        >
          <span className="icon is-small">
            <i className="mdi mdi-pencil" />
          </span>
        </Link>
        {checkAccess(`delete store`) && (
          <button
            type="button"
            className="button is-small is-danger"
            onClick={() => onRemoveButtonClickHandler(row.original.id)}
          >
            <span className="icon is-small">
              <i className="mdi mdi-trash-can" />
            </span>
          </button>
        )}
      </div>
    )
  };

  if (checkAccess('view store')) columnsStructure.push(actionCell);

  const accessToAddStore = checkAccess('create store');

  const deleteMessage = useFormatMessage('Stores.delete');
  const confirmMessage = useFormatMessage('Stores.confirm');
  const permDeleteMessage = useFormatMessage('Stores.permDelete');
  const cancelMessage = useFormatMessage('Stores.cancel');

  const titleSection = useFormatMessage('Stores.stores');

  useEffect(() => {
    if (onParamsChange) {
      onParamsChange({ title: titleSection });
    }
  }, []);

  return (
    <>
      {deleteModal.isOpen && (
        <ConfirmationModal
          isActive={deleteModal.isOpen}
          isLoading={deleting}
          confirmButtonMessage={deleteMessage}
          title={confirmMessage}
          body={permDeleteMessage}
          cancelButtonMessage={cancelMessage}
          onConfirmation={onDeleteStoreHandler}
          onCancel={onCloseModalHandler}
        />
      )}
      
      {accessToAddStore &&
        <section className="hero is-hero-bar">
          <div className="hero-body">
            <div className="level">
              <div className="level-right">
                <div className="level-item">
                  <Link to={paths.ADD_STORE} className="button">
                    {useFormatMessage('Stores.newStore')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      }
      <section className="section is-main-section">
        <div className="card has-table has-mobile-sort-spaced">
          <header className="card-header">
            <p className={classNames('card-header-title', classes.tableHeader)}>
              <span>{useFormatMessage('Stores.search')}</span>
              <input
                type="text"
                className="input"
                value={search}
                onChange={searchHandle}
              />
            </p>
          </header>
          <TableNew
            key={key}
            columnsStructure={columnsStructure} 
            fetchDataFn={getStoreList}
            additionalFilter={additionalFilter}
          />
        </div>
      </section>
    </>
  );
};

export default Stores;
