import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';
import { firebaseError } from 'utils';
import firebase from 'firebase.js';

import {
  fetchCollection,
  fetchDocument,
  addDocument,
  deleteDocument,
  updateDocument,
  getCountDocuments
} from '../api';

export const STORES_FETCH_DATA_INIT = createAction('STORES_FETCH_DATA_INIT');
export const STORES_FETCH_DATA_SUCCESS = createAction(
  'STORES_FETCH_DATA_SUCCESS'
);
export const STORES_FETCH_DATA_FAIL = createAction('STORES_FETCH_DATA_FAIL');

export const STORES_DELETE_STORE_INIT = createAction('STORES_DELETE_STORE_INIT');
export const STORES_DELETE_STORE_SUCCESS = createAction(
  'STORES_DELETE_STORE_SUCCESS'
);
export const STORES_DELETE_STORE_FAIL = createAction('STORES_DELETE_STORE_FAIL');

export const STORES_CREATE_STORE_INIT = createAction('STORES_CREATE_STORE_INIT');
export const STORES_CREATE_STORE_SUCCESS = createAction(
  'STORES_CREATE_STORE_SUCCESS'
);
export const STORES_CREATE_STORE_FAIL = createAction('STORES_CREATE_STORE_FAIL');

export const STORES_MODIFY_STORE_INIT = createAction('STORES_MODIFY_STORE_INIT');
export const STORES_MODIFY_STORE_SUCCESS = createAction(
  'STORES_MODIFY_STORE_SUCCESS'
);
export const STORES_MODIFY_STORE_FAIL = createAction('STORES_MODIFY_STORE_FAIL');

export const STORES_CLEAN_UP = createAction('STORES_CLEAN_UP');

export const STORES_CLEAR_DATA_LOGOUT = createAction('STORES_CLEAR_DATA_LOGOUT');

export const fetchStores = (storeId = '') => {
  return async (dispatch, getState) => {
    dispatch(STORES_FETCH_DATA_INIT());

    const userQueryOptions = {
      queries: [
        {
          'attribute': 'role',
          'operator': '==',
          'value': 'Store'
        }
      ]
    };

    const usersAsStore = await fetchCollection('users', userQueryOptions);

    if (storeId) {
      let store;
      try {
        store = await fetchDocument('stores', storeId);
      } catch (error) {
        toastr.error('', error);
        return dispatch(STORES_FETCH_DATA_FAIL({ error }));
      }

      if (!store) {
        const errorMessage = 'Store not available';
        toastr.error('', errorMessage);
        return dispatch(STORES_FETCH_DATA_FAIL({ error: errorMessage }));
      }

      const stores = getState().stores.data;
      stores.push(store);

      return dispatch(
        STORES_FETCH_DATA_SUCCESS({
          data: stores,
          usersAsStore
        })
      );
    }

    let stores;

    try {
      stores = await fetchCollection('stores');
    } catch (error) {
      return dispatch(STORES_FETCH_DATA_FAIL({ error }));
    }

    if (!stores) {
      const errorMessage = 'Stores not found';
      toastr.info('', errorMessage);
      return dispatch(STORES_FETCH_DATA_FAIL({ error: errorMessage }));
    }

    return dispatch(
      STORES_FETCH_DATA_SUCCESS({
        data: stores,
        usersAsStore
      })
    );
  };
};


export const fetchStore = async(storeId) => {
  let store = null;

  try {
    store = await fetchDocument('stores', storeId);
  } catch (error) {
    toastr.error('', error);
  }

  if (!store) {
    const errorMessage = 'Store not available';
    toastr.error('', errorMessage);
  }

  return store;
};

export const getStoreList = async({ limit = '', cursorId = '', direction = '', sort = null, subQueries = null, observeCallback = null } = {}) => {
  const queries = [];

  const response = {
    data: [],
    rowCount: 0
  };

  const queryNextOptions = {
    queries,
    sort:[
      {
        'attribute': 'name',
        'order': 'asc'
      }
    ],
  };

  const queryPrevOptions = {
    queries,
    sort:[
      {
        'attribute': 'name',
        'order': 'asc'
      }
    ],
  };

  const queryCountOptions = {
    queries
  };

  const queryOptions = {
    queries,
    sort:[
      {
        'attribute': 'name',
        'order': 'asc'
      }
    ],
  };

  if (subQueries) {
    queryCountOptions.subQueries = subQueries;
    queryOptions.subQueries = subQueries;
    queryNextOptions.subQueries = subQueries;
    queryPrevOptions.subQueries = subQueries;
  }

  if (sort) {
    queryOptions.sort.push({sort});
    queryNextOptions.sort.push({sort});
    queryPrevOptions.sort.push({sort});
  }

  if (limit) {
    queryOptions.limit = limit;
    queryNextOptions.limit = limit;
    queryPrevOptions.limit = limit;
  }

  if (cursorId && direction) {
    queryOptions.direction = direction;
    queryOptions.cursorId = cursorId;
  }

  const observeStores = (updatedStores) => {
    if (observeCallback) {
      observeCallback(updatedStores);
    }
  };

  try{
    const count = await getCountDocuments('stores', queryCountOptions);
    response.rowCount = count;

    const stores = await fetchCollection('stores', queryOptions, observeStores);
    response.data = stores;

    if (stores && stores.length && limit) {
      queryNextOptions.direction = 'next';
      queryNextOptions.cursorId = stores[stores.length - 1].id;

      const storesNextX2 = await fetchCollection('stores', queryNextOptions);
      if (storesNextX2 && (storesNextX2.length === limit)) {
        response.cursorIdNextX2 = storesNextX2[limit - 1].id;
      }

      queryPrevOptions.direction = 'prev';
      queryPrevOptions.cursorId = stores[0].id;

      const storesPrevX2 = await fetchCollection('stores', queryPrevOptions);
      if (storesPrevX2 && (storesPrevX2.length === limit)) {
        response.cursorIdPrevX2 = storesPrevX2[0].id;
      }
    }
  }
  catch (error){
    console.log(error);
    toastr.error('', error);
  }

  return response;
};


const deleteLogo = (oldLogo) => {
  if (!oldLogo.includes('firebasestorage')) {
    return null;
  }
  const logoPath = oldLogo.split('stores%2F').pop().split('?alt=media').shift();
  return firebase.storage().ref(`stores/${logoPath}`).delete();
};

export const deleteStore = async(id) => {
  const store = await fetchDocument('stores', id);
  const { logoUrl } = store;

  const deleteLogoTask = logoUrl ? deleteLogo(logoUrl) : null;
  const deleteStoreTask = deleteDocument('stores', id);
  try {
    await Promise.all([deleteLogoTask, deleteStoreTask]);
    toastr.success('', 'The store was deleted.');
  } catch (error) {
    const errorMessage = firebaseError(error.code, 'is');
    toastr.error('', errorMessage);
  }
};

export const clearStoresDataLogout = () => {
  return (dispatch) => {
    dispatch(STORES_CLEAR_DATA_LOGOUT());
  };
};

const uploadLogo = (id, file) => {
  const storageRef = firebase.storage().ref();

  const fileExtension = file.name.split('.').pop();

  const fileName = `${id}.${fileExtension}`;

  return storageRef.child(`stores/${fileName}`).put(file);
};

const getLogoUrl = (id, file) => {
  const fileExtension = file.name.split('.').pop();

  const bucketUrl = `${process.env.REACT_APP_FIRE_BASE_STORAGE_API}`;

  return `${bucketUrl}/o/stores%2F${id}_200x200.${fileExtension}?alt=media`;
};

export const createStore = async({
  name,
  file,
  createdAt,
  merchantID,
  publicKey,
  privateKey,
  kennitala,
  sell,
  integratorID,
  orderExport,
  orderAPIKey,
  productsAPIKey,
  categoriesAPIKey,
  tagsAPIKey,
  alwaysInStock,
  collectionID,
  minStockQty = '',
  giftcardEmail,
  giftcardPassword,
  giftcardToken,
  ordersCompanyID = '',
  orderExportStatus,
  serviceFee = '',
  paymentFee = '',
  shippingFee
}) => {
  const {data: stores} = await getStoreList();

  const existStore = stores.find((storeItem) => storeItem.name === name );
  if (existStore) {
    const errorMessage = `Store with the name "${name}" exist!`;
    toastr.error('', errorMessage);
    throw new Error();
  }
  else{
    const storeData = {
      name,
      createdAt,
      merchantID,
      publicKey,
      privateKey,
      kennitala,
      sell,
      integratorID,
      orderExport,
      orderAPIKey,
      productsAPIKey,
      categoriesAPIKey,
      tagsAPIKey,
      alwaysInStock,
      collectionID,
      minStockQty,
      giftcardEmail,
      giftcardPassword,
      giftcardToken,
      ordersCompanyID,
      orderExportStatus,
      serviceFee,
      paymentFee,
      shippingFee
    };

    let response;
    
    try {
      response = await addDocument('stores', storeData);
      const { id } = response;

      let uploadLogoTask = null;
      let logoUrl = null;
      if (file) {
        logoUrl = getLogoUrl(id, file);
        uploadLogoTask = uploadLogo(id, file);
      }

      storeData.logoUrl = logoUrl;

      const createStoreDbTask = updateDocument('stores', id, storeData);

      await Promise.all([
        uploadLogoTask,
        createStoreDbTask
      ]);
      toastr.success('', 'Store created successfully');
    } catch (error) {
      const errorMessage = firebaseError(error.message, 'is');
      toastr.error('', errorMessage);
    }
  }
};

export const modifyStore = async({
  name,
  file,
  createdAt,
  merchantID,
  publicKey,
  privateKey,
  kennitala,
  integratorID,
  sell,
  orderExport,
  orderAPIKey,
  productsAPIKey,
  categoriesAPIKey,
  tagsAPIKey,
  alwaysInStock,
  collectionID,
  minStockQty = '',
  giftcardEmail,
  giftcardPassword,
  giftcardToken,
  ordersCompanyID = '',
  orderExportStatus,
  serviceFee = '',
  paymentFee = '',
  shippingFee = '',
  id
}) => {
  const {data: stores} = await getStoreList();
  const store = stores.find((thisStores) => thisStores.id === id);

  const existStore = stores.filter((storeItem) => storeItem.id !== id).find((storeItem) => storeItem.name === name );
  if (existStore) {
    const errorMessage = `Store with the name "${name}" exist!`;
    toastr.error('', errorMessage);
    throw new Error();
  }
  else{
    const { logoUrl } = store;
    let deleteLogoTask;
    let uploadLogoTask;
    let newLogoUrl = null;
    if (file) {
      newLogoUrl = getLogoUrl(id, file);
      deleteLogoTask = logoUrl && deleteLogo(logoUrl);
      uploadLogoTask = uploadLogo(id, file);
    }

    const storeData = {
      name,
      createdAt,
      merchantID,
      publicKey,
      privateKey,
      kennitala,
      integratorID,
      sell,
      orderExport,
      orderAPIKey,
      productsAPIKey,
      categoriesAPIKey,
      tagsAPIKey,
      logoUrl: newLogoUrl || logoUrl,
      alwaysInStock,
      minStockQty,
      collectionID,
      giftcardEmail,
      giftcardPassword,
      giftcardToken,
      ordersCompanyID,
      orderExportStatus,
      serviceFee,
      paymentFee,
      shippingFee
    };
    const updateStoreDbTask = updateDocument('stores', id, storeData);

    try {
      await Promise.all([deleteLogoTask, uploadLogoTask, updateStoreDbTask]);
      toastr.success('', 'Store updated successfully');
    } catch (error) {
      const errorMessage = firebaseError(error.code, 'is');
      toastr.error('', errorMessage);
    }
  }
};

export const storesCleanUp = () => (dispatch) => dispatch(STORES_CLEAN_UP());