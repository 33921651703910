import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';
import { firebaseError } from 'utils';

import {
  fetchCollection,
  updateDocument,
} from '../api';

export const SETTINGS_KUMEN_FETCH_DATA_INIT = createAction('SETTINGS_KUMEN_FETCH_DATA_INIT');
export const SETTINGS_KUMEN_FETCH_DATA_SUCCESS = createAction(
  'SETTINGS_KUMEN_FETCH_DATA_SUCCESS'
);
export const SETTINGS_KUMEN_FETCH_DATA_FAIL = createAction('SETTINGS_KUMEN_FETCH_DATA_FAIL');
export const SETTINGS_KUMEN_MODIFY_INIT = createAction('SETTINGS_KUMEN_MODIFY_INIT');
export const SETTINGS_KUMEN_MODIFY_SUCCESS = createAction(
  'SETTINGS_KUMEN_MODIFY_SUCCESS'
);
export const SETTINGS_KUMEN_MODIFY_FAIL = createAction('SETTINGS_KUMEN_MODIFY_FAIL');

export const SETTINGS_KUMEN_CLEAN_UP = createAction('SETTINGS_KUMEN_CLEAN_UP');

export const SETTINGS_KUMEN_CLEAR_DATA_LOGOUT = createAction('SETTINGS_KUMEN_CLEAR_DATA_LOGOUT');

export const fetchSettings = () => {
  return async (dispatch) => {
    dispatch(SETTINGS_KUMEN_FETCH_DATA_INIT());

    let settings;

    try {
      settings = await fetchCollection('settings_kumen');
    } catch (error) {
      return dispatch(SETTINGS_KUMEN_FETCH_DATA_FAIL({ error }));
    }

    if (!settings) {
      const errorMessage = 'Settings not found';
      toastr.info('', errorMessage);
      return dispatch(SETTINGS_KUMEN_FETCH_DATA_FAIL({ error: errorMessage }));
    }

    return dispatch(
      SETTINGS_KUMEN_FETCH_DATA_SUCCESS({
        data: settings,
      })
    );
  };
};


export const clearSettingsDataLogout = () => {
  return (dispatch) => {
    dispatch(SETTINGS_KUMEN_CLEAR_DATA_LOGOUT());
  };
};

export const modifySettings = (settings) => {
  return async (dispatch, getState) => {
    dispatch(SETTINGS_KUMEN_MODIFY_INIT());
    const { locale } = getState().preferences;

    const settingsData = [];

    try {
      if (settings) {
        Object.keys(settings).forEach(async(id) => {
          settingsData.push({id, value: settings[id]});
          await updateDocument('settings_kumen', id, {value: settings[id]} );
        });
      }
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        SETTINGS_KUMEN_MODIFY_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'Settings updated successfully');
    return dispatch(SETTINGS_KUMEN_MODIFY_SUCCESS({settings: settingsData}));
  };
};

export const settingsCleanUp = () => (dispatch) => dispatch(SETTINGS_KUMEN_CLEAN_UP());