import { createReducer } from 'redux-act';

import {
  COMPLEX_MAPPINGS_FETCH_DATA_INIT,
  COMPLEX_MAPPINGS_FETCH_DATA_SUCCESS,
  COMPLEX_MAPPINGS_FETCH_DATA_FAIL,
  COMPLEX_MAPPINGS_CLEAN_UP,
  COMPLEX_MAPPINGS_CLEAR_DATA_LOGOUT,
} from 'state/actions/complexMappings';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

export const complexMappingsReducer = createReducer(
  {
    [COMPLEX_MAPPINGS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [COMPLEX_MAPPINGS_FETCH_DATA_SUCCESS]: (state, { data }) => ({
      ...state,
      data,
      loading: false,
      error: null,
    }),
    [COMPLEX_MAPPINGS_FETCH_DATA_FAIL]: (state, { error }) => ({
      ...state,
      loading: false,
      error,
    }),
    [COMPLEX_MAPPINGS_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
    }),
    [COMPLEX_MAPPINGS_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
  },
  initialState
);
