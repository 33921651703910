import { toastr } from 'react-redux-toastr';

import { 
  writeBatch,
  getFirestore,
  doc
} from "firebase/firestore"; 

import {
  fetchCollection,
  getCountDocuments
} from '../api';

export const fetchNotifications = async ({commentType = '', limit = '', entityId = '', cursorId = '', direction = ''} = {}) => {
  let notifications = [];
  
  try {
    const queryOptions = {
      sort: [
        {
          'attribute': 'createdAt',
          'order': 'desc'
        }
      ],
      queries: []
    };

    if (commentType) {
      queryOptions.queries.push({
        'attribute': 'commentType',
        'operator': '==',
        'value': commentType
      });
    }

    if (entityId) {
      queryOptions.queries.push({
        'attribute': 'entityId',
        'operator': '==',
        'value': entityId
      });
    }

    if (limit) {
      queryOptions.limit = limit;
    }

    if (cursorId && direction) {
      queryOptions.cursorId = cursorId;
      queryOptions.direction = direction;
    }

    notifications = await fetchCollection('comments', queryOptions);
  } catch (error) {
    toastr.error('', error);
  }

  return notifications;
};

export const getNumberReadNotifications = async({uid, commentType = '', entityId = ''}) => {
  const queryOptions = {
    queries: [
      {
        'attribute': 'read',
        'operator': 'array-contains',
        'value': uid
      }
    ]
  };

  if (commentType) {
    queryOptions.queries.push({
      'attribute': 'commentType',
      'operator': '==',
      'value': commentType
    });
  }

  if (entityId) {
    queryOptions.queries.push({
      'attribute': 'entityId',
      'operator': '==',
      'value': entityId
    });
  }

  const count = await getCountDocuments('comments', queryOptions);

  return count;
};

export const getNumberNotifications = async({commentType = '', entityId = ''} = {}) => {
  const queryOptions = {
    queries: []
  };

  if (commentType) {
    queryOptions.queries.push({
      'attribute': 'commentType',
      'operator': '==',
      'value': commentType
    });
  }

  if (entityId) {
    queryOptions.queries.push({
      'attribute': 'entityId',
      'operator': '==',
      'value': entityId
    });
  }

  const count = await getCountDocuments('comments', queryOptions);

  return count;
};

export const allRead = async({uid, commentType = '', entityId = ''}) => {
  const queryOptions = {
    queries: []
  };

  if (commentType) {
    queryOptions.queries.push({
      'attribute': 'commentType',
      'operator': '==',
      'value': commentType
    });
  }

  if (entityId) {
    queryOptions.queries.push({
      'attribute': 'entityId',
      'operator': '==',
      'value': entityId
    });
  }

  try {
    const notifications = await fetchCollection('comments', queryOptions);

    const db = getFirestore();
    const batch = writeBatch(db);

    notifications.forEach((item) => {
      const itemRef = doc(db, "comments", item.id);
      
      if(!item.read || !item.read.includes(uid)){
        let read = [];
        if (!item.read) {
          read = [uid];
        }
        
        if (!item.read.includes(uid)) {
          read = item.read.concat([uid]);
        }

        batch.update(itemRef, {
          "read": read
        });
      }
    });

    await batch.commit();
  } catch (error) {
    console.log(error);
    toastr.error('', error);
  }
};