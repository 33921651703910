import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
import NavBar from '../Navigation/NavBar';
import Aside from '../Navigation/Aside';
import Footer from '../Navigation/Footer';
import classes from './Layout.module.scss';

const Layout = ({ children, paramsFromChild }) => {
  const [asideMobileActive, setAsideMobileActive] = useState(false);
  const location = useLocation();
  
  useEffect(() => {
    const savedValue = Cookies.get('expandedSidebar');

    if(savedValue === 'true'){
      document.documentElement.classList.add('has-aside-mobile-expanded');
      setAsideMobileActive(true);
    }
    else{
      document.documentElement.classList.remove('has-aside-mobile-expanded');
      setAsideMobileActive(false);
    }

  }, [location]);

  const handleMobileToggle = () => {
    let expandedValue = true;
    if(document.documentElement.classList.contains('has-aside-mobile-expanded')){
      expandedValue = false;
    }

    Cookies.set('expandedSidebar', expandedValue, { expires: 30 });

    document.documentElement.classList.toggle('has-aside-mobile-expanded');
    setAsideMobileActive(!asideMobileActive);
  };

  return (
    <>
      <NavBar
        title={paramsFromChild?.title}
        handleMobileToggle={handleMobileToggle}
        asideMobileActive={asideMobileActive}
      />
      <Aside />
      <div className={classes.layout}>{children}</div>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
