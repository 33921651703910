import React, { useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { checkAccess } from 'permissions';

import Layout from 'components/Layout';

const PrivateRoute = ({ path, component: Component, rule = null }) => {
  const [paramsFromChild, setParamsFromChild] = useState(null);
  const handleParamsFromChild = (params) => {
    setParamsFromChild(params);
  };

  const {access, redirect} = checkAccess(rule, true);

  return (
    <Layout paramsFromChild={paramsFromChild}>
      <Route
        exact
        path={path}
        render={() => (access ? <Component onParamsChange={handleParamsFromChild} /> : <Redirect to={ redirect } />)}
      />
    </Layout>
  );
};

PrivateRoute.propType = {
  path: PropTypes.string.isRequired,
  component: PropTypes.element.isRequired
};

export default PrivateRoute;
