import { createReducer } from 'redux-act';

import {
  MAPPINGS_FETCH_DATA_INIT,
  MAPPINGS_FETCH_DATA_SUCCESS,
  MAPPINGS_FETCH_DATA_FAIL,
  MAPPINGS_CREATE_MAPPING_INIT,
  MAPPINGS_CREATE_MAPPING_SUCCESS,
  MAPPINGS_CREATE_MAPPING_FAIL,
  MAPPINGS_MODIFY_MAPPING_INIT,
  MAPPINGS_MODIFY_MAPPING_SUCCESS,
  MAPPINGS_MODIFY_MAPPING_FAIL,
  MAPPINGS_CLEAN_UP,
  MAPPINGS_CLEAR_DATA_LOGOUT,
} from 'state/actions/mappings';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

export const mappingsReducer = createReducer(
  {
    [MAPPINGS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [MAPPINGS_FETCH_DATA_SUCCESS]: (state, { data }) => ({
      ...state,
      data,
      loading: false,
      error: null,
    }),
    [MAPPINGS_FETCH_DATA_FAIL]: (state, { error }) => ({
      ...state,
      loading: false,
      error,
    }),
    [MAPPINGS_CREATE_MAPPING_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [MAPPINGS_CREATE_MAPPING_SUCCESS]: (state, { mapping }) => {
      const { data } = state;

      return ({
        ...state,
        data: data.concat(mapping),
        loading: false,
        error: null,
        success: true,
      });
    },
    [MAPPINGS_CREATE_MAPPING_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [MAPPINGS_MODIFY_MAPPING_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [MAPPINGS_MODIFY_MAPPING_SUCCESS]: (state, { id, mapping }) => {
      const { data } = state;

      return ({
        ...state,
        data: !data
          ? []
          : data.map((elem) => {
              if (elem.id === id) {
                return {
                  companyId: mapping.companyId,
                  destination: mapping.destination,
                  source: mapping.source,
                  type: mapping.type,
                  id
                };
              }
              return elem;
            }),
        loading: false,
        error: null,
        success: true,
      });
    },
    [MAPPINGS_MODIFY_MAPPING_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [MAPPINGS_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
    }),
    [MAPPINGS_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
  },
  initialState
);
