import { createAction } from 'redux-act';

export const PREFERENCES_SET_LOCALE = createAction('PREFERENCES_SET_LOCALE');
export const PREFERENCES_CLEAR_DATA = createAction('PREFERENCES_CLEAR_DATA');

export const setUserLocale = locale => dispatch => {
  return dispatch(PREFERENCES_SET_LOCALE({ locale }));
};

export const clearPreferences = () => dispatch => {
  return dispatch(PREFERENCES_CLEAR_DATA());
};