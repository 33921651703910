import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { checkAccess } from 'permissions';

import { useFormatMessage } from 'hooks';
import paths from 'pages/Router/paths';
import NavLink from '../Link';

export const SubMenu = ({ label, children }) => {
  const [active, setActive] = useState(false);

  return (
    <li className={classNames({ 'is-active': active })}>
      <a
        className="has-icon has-dropdown-icon"
        onClick={() => setActive(!active)}
      >
        <span className="icon">
          <i className="mdi mdi-view-list" />
        </span>
        <span className="menu-item-label">{label}</span>
        <div className="dropdown-icon">
          <span className="icon">
            <i
              className={classNames(
                'mdi',
                { 'mdi-minus': active },
                { 'mdi-plus': !active }
              )}
            />
          </span>
        </div>
      </a>
      <ul>{children}</ul>
    </li>
  );
};

SubMenu.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
};

const Aside = ({ handleMobileToggle }) => {
  const usersMessage = useFormatMessage('Aside.users');

  const accessUserList = checkAccess('view user list');
  const accessOrderList = checkAccess('view order list');
  const accessStoresList = checkAccess('view stores list');
  const accessCouponsList = checkAccess('view coupons list');
  const accessSettings = checkAccess('view settings');
  const accessKumenSettings = checkAccess('edit kumen settings');
  const accessRestaurantsList = checkAccess('view restaurant list');
  const accessCategoriesList = checkAccess('view category list');
  
  return (
    <aside className="main-sidebar aside is-placed-left is-expanded">
      <div className="aside-tools">
        <div className="aside-tools-label">
          <span>
            <b>Kringlan</b>
          </span>
        </div>
      </div>
      <div className="menu is-menu-main">
        <ul className="menu-list">
          { accessUserList && (
            <li>
              <NavLink
                to={paths.USERS}
                className="has-icon"
                onClick={handleMobileToggle}
              >
                <span className="icon">
                  <i className="mdi mdi-account-supervisor" />
                </span>
                <span className="menu-item-label">{usersMessage}</span>
              </NavLink>
            </li>
          )}
          { accessOrderList && (
            <li>
              <NavLink
                to={paths.ORDERS_BOARD}
                className="has-icon"
                onClick={handleMobileToggle}
              >
                <span className="icon">
                  <i className="mdi mdi-cart" />
                </span>
                <span className="menu-item-label">{useFormatMessage('Aside.orders')}</span>
              </NavLink>
            </li>
          )}
          { accessStoresList && (
            <li>
              <NavLink
                to={paths.STORES}
                className="has-icon"
                onClick={handleMobileToggle}
              >
                <span className="icon">
                  <i className="mdi mdi-shopping" />
                </span>
                <span className="menu-item-label">{useFormatMessage('Aside.stores')}</span>
              </NavLink>
            </li>
          )}
          { accessCouponsList && (
            <li>
              <NavLink
                to={paths.COUPONS}
                className="has-icon"
                onClick={handleMobileToggle}
              >
                <span className="icon">
                  <i className="mdi mdi-ticket" />
                </span>
                <span className="menu-item-label">{useFormatMessage('Aside.coupons')}</span>
              </NavLink>
            </li>
          )}
          { accessCategoriesList && (
            <li>
              <NavLink
                to={paths.CATEGORIES}
                className="has-icon"
                onClick={handleMobileToggle}
              >
                <span className="icon">
                  <i className="mdi mdi-format-list-bulleted" />
                </span>
                <span className="menu-item-label">{useFormatMessage('Aside.categories')}</span>
              </NavLink>
            </li>
          )}
          { accessSettings && (
            <li>
              <NavLink
                to={paths.SETTINGS}
                className="has-icon"
                onClick={handleMobileToggle}
              >
                <span className="icon">
                  <i className="mdi mdi-settings" />
                </span>
                <span className="menu-item-label">{useFormatMessage('Aside.settings')}</span>
              </NavLink>
            </li>
          )}
        </ul>
      </div>
      {(accessKumenSettings || accessRestaurantsList) &&
        <>
          <div className="aside-tools">
            <div className="aside-tools-label">
              <span>
                <b>Kumen</b>
              </span>
            </div>
          </div>
          <div className="menu is-menu-main">
            <ul className="menu-list">
              {accessKumenSettings &&
                <li>
                  <NavLink
                    to={paths.SETTINGS_KUMEN}
                    className="has-icon"
                    onClick={handleMobileToggle}
                  >
                    <span className="icon">
                      <i className="mdi mdi-settings" />
                    </span>
                    <span className="menu-item-label">{useFormatMessage('Aside.settingsKumen')}</span>
                  </NavLink>
                </li>
              }
              {accessRestaurantsList &&
                <li>
                  <NavLink
                    to={paths.RESTAURANTS}
                    className="has-icon"
                    onClick={handleMobileToggle}
                  >
                    <span className="icon">
                      <i className="mdi mdi-food" />
                    </span>
                    <span className="menu-item-label">{useFormatMessage('Aside.restaurants')}</span>
                  </NavLink>
                </li>
              }
            </ul>
          </div>
        </>
      }
    </aside>
  );
};

Aside.propTypes = {
  handleMobileToggle: PropTypes.func,
};

export default Aside;
