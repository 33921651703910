import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';
import { firebaseError } from 'utils';

import {
  fetchCollection,
  fetchDocument,
  addDocument,
  deleteDocument,
  updateDocument,
} from '../api';

export const CATEGORIES_FETCH_DATA_INIT = createAction('CATEGORIES_FETCH_DATA_INIT');
export const CATEGORIES_FETCH_DATA_SUCCESS = createAction(
  'CATEGORIES_FETCH_DATA_SUCCESS'
);
export const CATEGORIES_FETCH_DATA_FAIL = createAction('CATEGORIES_FETCH_DATA_FAIL');

export const CATEGORIES_DELETE_CATEGORY_INIT = createAction('CATEGORIES_DELETE_CATEGORY_INIT');
export const CATEGORIES_DELETE_CATEGORY_SUCCESS = createAction(
  'CATEGORIES_DELETE_CATEGORY_SUCCESS'
);
export const CATEGORIES_DELETE_CATEGORY_FAIL = createAction('CATEGORIES_DELETE_CATEGORY_FAIL');

export const CATEGORIES_CREATE_CATEGORY_INIT = createAction('CATEGORIES_CREATE_CATEGORY_INIT');
export const CATEGORIES_CREATE_CATEGORY_SUCCESS = createAction(
  'CATEGORIES_CREATE_CATEGORY_SUCCESS'
);
export const CATEGORIES_CREATE_CATEGORY_FAIL = createAction('CATEGORIES_CREATE_CATEGORY_FAIL');

export const CATEGORIES_MODIFY_CATEGORY_INIT = createAction('CATEGORIES_MODIFY_CATEGORY_INIT');
export const CATEGORIES_MODIFY_CATEGORY_SUCCESS = createAction(
  'CATEGORIES_MODIFY_CATEGORY_SUCCESS'
);

export const CATEGORIES_MODIFY_CATEGORY_FAIL = createAction('CATEGORIES_MODIFY_CATEGORY_FAIL');

export const CATEGORIES_CLEAN_UP = createAction('CATEGORIES_CLEAN_UP');

export const CATEGORIES_CLEAR_DATA_LOGOUT = createAction('CATEGORIES_CLEAR_DATA_LOGOUT');

export const fetchCategories = (categoryId = '') => {
  return async (dispatch, getState) => {
    dispatch(CATEGORIES_FETCH_DATA_INIT());

    if (categoryId) {
      let category;
      
      try {
        category = await fetchDocument('categories', categoryId);
      } catch (error) {
        toastr.error('', error);
        return dispatch(CATEGORIES_FETCH_DATA_FAIL({ error }));
      }

      if (!category) {
        const errorMessage = 'Category not available';
        toastr.error('', errorMessage);
        return dispatch(CATEGORIES_FETCH_DATA_FAIL({ error: errorMessage }));
      }

      const categories = getState().categories.data;
      categories.push(category);

      return dispatch(
        CATEGORIES_FETCH_DATA_SUCCESS({
          data: categories
        })
      );
    }

    let categories;

    try {
      const queryOptions = {};

      categories = await fetchCollection('categories', queryOptions);
    } catch (error) {
      return dispatch(CATEGORIES_FETCH_DATA_FAIL({ error }));
    }

    if (!categories) {
      const errorMessage = 'Categories not found';
      toastr.info('', errorMessage);
      return dispatch(CATEGORIES_FETCH_DATA_FAIL({ error: errorMessage }));
    }

    return dispatch(
      CATEGORIES_FETCH_DATA_SUCCESS({
        data: categories
      })
    );
  };
};


export const deleteCategory = (id) => {
  return async (dispatch, getState) => {
    dispatch(CATEGORIES_DELETE_CATEGORY_INIT());

    const { locale } = getState().preferences;
    const categories = getState().categories.data;
    const childCategories = categories.filter((item) => item.parentCategory === id);

    try {
      await deleteDocument('categories', id);
      if(childCategories && childCategories.length > 0){
        const promises = [];
        childCategories.forEach(item => {
          const { id: categoryId } =  item;
          const categoryData = {...item};
          delete categoryData.id;
          categoryData.parentCategory = '';
          promises.push(updateDocument('categories', categoryId, categoryData));
          dispatch(CATEGORIES_MODIFY_CATEGORY_SUCCESS({ category: categoryData, id: categoryId }));
        });

        await Promise.all(promises);
        dispatch(CATEGORIES_CLEAN_UP());
      }
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        CATEGORIES_DELETE_CATEGORY_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'The category was deleted.');
    return dispatch(CATEGORIES_DELETE_CATEGORY_SUCCESS({ id }));
  };
};

export const clearCategoriesDataLogout = () => {
  return (dispatch) => {
    dispatch(CATEGORIES_CLEAR_DATA_LOGOUT());
  };
};


export const createCategory = ({
  name,
  parentCategory = '',
  integratorCategory = '',
  showOnMenu = false,
  createdAt,
  depth
}) => {
  return async (dispatch, getState) => {
    dispatch(CATEGORIES_CREATE_CATEGORY_INIT());

    const { locale } = getState().preferences;

    const categoryData = {
      name,
      parentCategory,
      integratorCategory,
      showOnMenu,
      createdAt,
      depth
    };

    let response;
    
    try {
      response = await addDocument('categories', categoryData);
    } catch (error) {
      const errorMessage = firebaseError(error.message, locale);
      toastr.error('', errorMessage);
      return dispatch(
        CATEGORIES_CREATE_CATEGORY_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'Category created successfully');
    return dispatch(CATEGORIES_CREATE_CATEGORY_SUCCESS({ category: {
        id: response.id,
        ...categoryData
      }
    }));
  };
};

export const modifyCategory = ({
  name,
  parentCategory = '',
  integratorCategory = '',
  showOnMenu = false,
  id,
  depth
}) => {
  return async (dispatch, getState) => {
    dispatch(CATEGORIES_MODIFY_CATEGORY_INIT());
    const { locale } = getState().preferences;
    const categories = getState().categories.data;

    const category = categories.find((thisCategory) => thisCategory.id === id);

    const { createdAt } = category;

    const categoryData = {
      name,
      parentCategory,
      integratorCategory,
      showOnMenu,
      createdAt,
      depth
    };

    try {
      await updateDocument('categories', id, categoryData);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        CATEGORIES_MODIFY_CATEGORY_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'Category updated successfully');
    return dispatch(CATEGORIES_MODIFY_CATEGORY_SUCCESS({ category: categoryData, id }));
  };
};

export const categoriesCleanUp = () => (dispatch) => dispatch(CATEGORIES_CLEAN_UP());