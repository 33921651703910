import React, { useEffect, useMemo } from 'react';
import { Link, useParams, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import Loader from 'components/Loader';
import * as yup from 'yup';
import CategoryForm from 'components/CategoryForm';
import { createCategory, modifyCategory, fetchCategories } from 'state/actions/categories';
import paths from 'pages/Router/paths';
import { useFormatMessage } from 'hooks';

const Category = ({ onParamsChange }) => {
  const { id } = useParams();

  const isEditing = useMemo(() => !!id, [id]);

  const { success, categoryData, error, categories} = useSelector(
    (state) => ({
      success: state.categories.success,
      categories: state.categories.data,
      categoryData: state.categories.data.find((category) => category.id === id),
      error: state.categories.error,
    }),
    shallowEqual
  );

  const schema = yup.object().shape({
    name: yup.string().test("is-unique-name", "Please set unique name", (value, ctx) => {
      let valid = false;
      if(value){
        valid = !categories.filter(item => {
          let result = true;
          if(id && item.id === id){
            result = false;
          }

          return result;
        })
        .some(item => item.name === value && item.parentCategory === ctx.parent.parentCategory);
      }
      return valid;
    }).required(),
    parentCategory: yup.string()
    .test("is-parent", "Can't set this parent", value => { 
      let valid = true;
      if(value && id){
        const selectedParent = categories.find(item => item.id === value);
        if(selectedParent){
          if(selectedParent.parentCategory === id){
            valid = false;
          }
          else{
            const childFirstLevel = categories.find(item => item.id === selectedParent.parentCategory);
            if(childFirstLevel && childFirstLevel.parentCategory === id){
              valid = false;
            }
          }
        }
      }

      return valid;
    }),
    createdAt: yup.string().required(),
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEditing) {
      
      if (!categoryData) {
        dispatch(fetchCategories(id));
      }
    }
  }, [isEditing, id, categoryData, dispatch]);

  const redirect = ((isEditing && error) || success) && (
    <Redirect to={paths.CATEGORIES} />
  );

  const goBackMessage = useFormatMessage('CategoryForm.goBack');

  let titleSection = useFormatMessage('Category.newCategory');
  if(isEditing){
    titleSection = useFormatMessage('Category.editCategory');
  }


  useEffect(() => {
    if (onParamsChange) {
      onParamsChange({ title: titleSection });
    }
  }, []);

  const calculateDepth = (parentId, allCategory, depth = 0) => {
    let currentDepth = depth;
    if(parentId){
      const parent = allCategory.find(item => item.id === parentId);
      if(parent){
        currentDepth = calculateDepth(parent.parentCategory, allCategory, depth + 1);
      }
    }

    return currentDepth;
  };

  const onSubmitHandler = (value) => {
    const newValue = { ...value };
    const depth = calculateDepth(newValue.parentCategory, categories);
    
    const newCategory = {
      ...newValue,
      createdAt: new Date(newValue.createdAt).toDateString(),
      depth,
      isEditing,
      id,
    };

    if (isEditing) {
      dispatch(modifyCategory(newCategory));
    } else {
      dispatch(createCategory(newCategory));
    }
  };

  return (
    <>
      {redirect}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <Link to={paths.CATEGORIES} className="button has-icon">
            <span className="icon">
              <i className="mdi mdi-arrow-left"/>
            </span>
            {goBackMessage}
          </Link>
        </div>
      </section>
      <section className="section is-main-section">
        {isEditing && !categoryData ? (
          <Loader />
        ) : (
          <CategoryForm
            isEditing={isEditing}
            category={
              isEditing
                ? categoryData
                : {
                    name: '',
                    parentCategory: '',
                    showOnMenu: false,
                    createdAt: new Date().toDateString(),
                  }
            }
            onSubmitHandler={onSubmitHandler}
            schema={schema}
          />
        )}
      </section>
    </>
  );
};

export default Category;
