import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import getSymbolFromCurrency from 'currency-symbol-map';
import moment from 'moment';
import { clearSnapshotVariable } from 'state/api';
import DatePicker from 'react-datepicker';
import { checkAccess } from 'permissions';
import { useFormatMessage } from 'hooks';
import TableNew from 'components/TableNew';
import { getOrderList } from 'state/actions/orders';
import { ORDER_STATUS } from 'utils';
import paths from '../Router/paths';

const Orders = ({ onParamsChange }) => {
  const history = useHistory();
  const [search, setSearch] = useState('');
  const [additionalFilter, setAdditionalFilter] = useState(null);
  const [filterStartDate, setFilterStartDate] = useState();
  const [filterEndDate, setFilterEndDate] = useState();

  const titleSection = useFormatMessage('Orders.orders');

  useEffect(() => {
    if (onParamsChange) {
      onParamsChange({ title: titleSection });
    }
  }, []);

  useEffect(() => {
    return () => clearSnapshotVariable('orders');
  }, []);

  const applyFilters = (params) => {
    let queryOptions = null;

    const {startDate = null, endDate = null, searchValue = null} = params;

    const dateQueryOptions = [];
    const searchQueryOptions = [];

    if(startDate){
      const startDateFormated = moment(startDate).set('hour', 0).set('minute', 0).set('second', 0).format("YYYY-MM-DDTHH:mm:ss[Z]");

      const startDateQueries = {
        'attribute': 'ServiceData.Source.ModDateTime',
        'operator': '>=',
        'value': startDateFormated
      };

      dateQueryOptions.push(startDateQueries);
    }

    if(endDate){
      const endDateFormated = moment(endDate).set('hour', 23).set('minute', 59).set('second', 59).format("YYYY-MM-DDTHH:mm:ss[Z]");

      const endDateQueries = {
        'attribute': 'ServiceData.Source.ModDateTime',
        'operator': '<=',
        'value': endDateFormated
      };

      dateQueryOptions.push(endDateQueries);
    }

    if(searchValue && searchValue.length >= 3){
      searchQueryOptions.push(
        [{
          'attribute': 'Billing.Firstname',
          'operator': '>=',
          'value': searchValue
        },
        {
          'attribute': 'Billing.Firstname',
          'operator': '<=',
          'value': `${searchValue}~`
        }]
      );

      searchQueryOptions.push(
        [{
          'attribute': 'ServiceData.Source.Identity',
          'operator': '>=',
          'value': searchValue
        },
        {
          'attribute': 'ServiceData.Source.Identity',
          'operator': '<=',
          'value': `${searchValue}~`
        }]
      );
    }

    if(searchQueryOptions.length > 0){
      searchQueryOptions.forEach((el, index) => {
        if(dateQueryOptions.length > 0){
          searchQueryOptions[index] = el.concat(dateQueryOptions);
        }
      });

      queryOptions = {
        queries: [],
        relationship: 'or'
      };

      searchQueryOptions.forEach(el => {
        queryOptions.queries.push({ queries: el, relationship: 'and' });
      });
    }
    else if(dateQueryOptions.length > 0){
      queryOptions = {
        queries: [],
        relationship: 'and'
      };

      dateQueryOptions.forEach(el => {
        queryOptions.queries.push({ queries: [el], relationship: 'and' });
      });
    }

    setAdditionalFilter(queryOptions);
  };

  const onChangeStartDate = (value) => {
    const startDate = value ? new Date(value) : '';
    setFilterStartDate(startDate);

    applyFilters({startDate, endDate: filterEndDate, searchValue: search});
  };

  const onChangeEndDate = (value) => {
    const endDate = value ? new Date(value) : '';
    setFilterEndDate(endDate);

    applyFilters({endDate, startDate: filterStartDate, searchValue: search});
  };

  const searchHandle = (e) => {
    const searchValue = e.target.value;
    setSearch(searchValue);

    applyFilters({searchValue, startDate: filterStartDate, endDate: filterEndDate}); 
  };

  const exportOrdersHandle = async() => {
    const queryParam = {};

    const fields = [
      "Order Id",
      "Date created",
      "Payment Status",
      "Delivery Status",
      "Billing Email",
      "Billing Name",
      "Billing Phone",
      "Billing SSID",
      "Billing Address Line 1",
      "Billing ZipCode",
      "Billing City",
      "Item Type",
      "Item Store",
      "Item SKU",
      "Item Name",
      "Item Qty",
      "Item Unit Price",
      "Item Discount Amount",
      "Item Total",
      "Item Status",
      "Payment Transaction Status"
    ];

    if(additionalFilter){
      queryParam.subQueries = additionalFilter;
    }

    const orders = await getOrderList(queryParam);

    if(orders && orders.data && orders.data.length > 0){
      const ordersData = [];

      orders.data.forEach(order => {
        const 
          { 
            ServiceData: {
            Source: {
              Identity,
              CreateDateTime,
              Status
            }
          },
          StandardFields: {
            DeliveryStatus,
            OrderType
          },
          Billing: {
            Email,
            Firstname,
            Phone,
            SSID,
            AddressLine1,
            ZipCode,
            City
          },
          ShippingLine: {
            ShipmentMethodCode,
            Subtotal: ShipmentTotal 
          },
          Items,
          Payments
        } = order;

        if (OrderType !== 'dev') {
          Items.forEach((item) => {
            const orderData = [];

            orderData.push(Identity.toString());
            orderData.push(CreateDateTime);
            orderData.push(Status);
            orderData.push(DeliveryStatus);
            
            orderData.push(Email);
            orderData.push(Firstname);
            orderData.push(Phone);
            orderData.push(SSID);
            orderData.push(AddressLine1);
            orderData.push(ZipCode.toString());
            orderData.push(City);

            orderData.push('line item');
            orderData.push(item.StoreName);
            orderData.push(item.SKU);
            orderData.push(item.Name);
            orderData.push(item.Qty);
            orderData.push(item.UnitPrice);
            orderData.push(item.RegularPrice - item.UnitPrice);
            orderData.push(item.Total);
            orderData.push(item.DeliveryStatus);

            const paymentOrderItem = Payments.find(paymentItem => paymentItem.Store === item.CompanyId);
            if (paymentOrderItem && paymentOrderItem.PaymentFields && paymentOrderItem.PaymentFields.TransactionStatus) {
              orderData.push(paymentOrderItem.PaymentFields.TransactionStatus);
            }

            ordersData.push(orderData);
          });

          const shipmentData = [];
          shipmentData.push(Identity.toString());
          shipmentData.push(CreateDateTime);
          shipmentData.push(Status);
          shipmentData.push(DeliveryStatus);
          
          shipmentData.push(Email);
          shipmentData.push(Firstname);
          shipmentData.push(Phone);
          shipmentData.push(SSID);
          shipmentData.push(AddressLine1);
          shipmentData.push(ZipCode.toString());
          shipmentData.push(City);

          shipmentData.push('shipping');
          shipmentData.push('');
          shipmentData.push('');
          shipmentData.push(ShipmentMethodCode);
          shipmentData.push('');
          shipmentData.push(ShipmentTotal);
          shipmentData.push(0);
          shipmentData.push(ShipmentTotal);
          shipmentData.push('');

          const shipmentPayment = Payments.find(paymentItem => paymentItem.Store === 'Kringlan');
          if (shipmentPayment && shipmentPayment.PaymentFields && shipmentPayment.PaymentFields.TransactionStatus) {
            shipmentData.push(shipmentPayment.PaymentFields.TransactionStatus);
          }

          ordersData.push(shipmentData);
        }
      });

      const data = [[...fields]].concat(ordersData);

      const csvString = data.map(row => row.map(field => `"${field.toString().replace(/"/g, '""')}"`).toString()).join("\r\n");
      const blob = new Blob([csvString], { type: 'text/csv' });

      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = 'orders.csv';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }
  };

  const columnsStructure = [
    {
      accessorFn: (row) => row.id,
      id: "order.id",
      header: useFormatMessage('Orders.id'),
      cell: ({ row }) => (
        <>
          { checkAccess('view order item') ? (
            <Link to={`/orders/${row.original.id}`}>
              { row.original.ServiceData.Source.Identity }
            </Link>
            ) : row.original.ServiceData.Source.Identity 
          }
        </>
      )
    },
    {
      accessorFn: (row) => row.Billing.Firstname,
      id: "order.Billing.Firstname",
      header: useFormatMessage('Orders.customerName'),
      cell: (info) => info.getValue() || null,
    },
    {
      accessorFn: (row) => row.Items,
      id: "order.Items",
      header: useFormatMessage('Orders.items'),
      cell: (info) => {
        const value = info.getValue();

        return (
          <>
            { value ? 
              value.reduce((total, product) => total + Number(product.Qty), 0 )
             : '0' }
          </>
        );
      }
    },
    {
      accessorFn: (row) => row.StandardFields,
      id: "order.grandTotal",
      header: useFormatMessage('Orders.grandTotal'),
      cell: (info) => {
        const value = info.getValue();

        return (
          <NumericFormat value={value.GrandTotal} displayType='text' thousandSeparator suffix={` ${getSymbolFromCurrency(value.Currency)}`} />
        );
      }
    },
    {
      accessorFn: (row) => row.ServiceData.Source.ModDateTime,
      id: "order.ModDateTime",
      header: useFormatMessage('Orders.modDate'),
      cell: (info) => {
        const value = info.getValue();

        return value ? moment(value).utc().format("DD.MM.YYYY HH:mm") : null;
      }
    },
    {
      accessorFn: (row) => row.ServiceData.Source.Status,
      id: "order.Status",
      header: useFormatMessage('Orders.paymentStatus'),
      cell: (info) => info.getValue() || null,
    },
    {
      accessorFn: (row) => row.StandardFields.DeliveryStatus,
      id: "order.deliveryStatus",
      header: useFormatMessage('Orders.deliveryStatus'),
      cell: (info) => {
        const value = info.getValue();

        return value ? ORDER_STATUS[value] : null;
      }
    },
    {
      accessorFn: (row) => row.StandardFields.ExportOrderStatus,
      id: "order.exportStatus",
      header: useFormatMessage('Orders.exportStatus'),
      cell: (info) => info.getValue() || null,
    },
  ];

  const actionColumn = {
    header: 'Actions',
    id: 'order.actions',
    accessorFn: (row) => row.id,
    cell: (info) => (
      <div className="buttons is-right">
        <Link
          to={`/orders/${info.getValue()}`}
          className="button is-small is-primary"
        >
          <span className="icon is-small">
            <i className="mdi mdi-pencil" />
          </span>
        </Link>
      </div>
    )
  };

  if (checkAccess('view order item')) columnsStructure.push(actionColumn);

  const onRowClickHandle = (param) => {
    history.push(`/orders/${param.original.id}`);
  };

  return (
    <>
      <section className="hero is-hero-bar">
        <div className="hero-body pb-0">
          <div className="level">
            <div className="level-left">
              <div className="level-item">
                <div className="tabs is-boxed">
                  <ul>
                    <li className="is-active">
                      <Link
                        to={paths.ORDERS}
                      >
                        <span className="icon is-small"><i className="mdi mdi-format-list-bulleted" aria-hidden="true"/></span>
                        <span>{useFormatMessage('Orders.viewList')}</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={paths.ORDERS_BOARD}
                      >
                        <span className="icon is-small"><i className="mdi mdi-view-dashboard" aria-hidden="true"/></span>
                        <span>{useFormatMessage('Orders.viewBoard')}</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section is-main-section">
        <div className="card has-table has-mobile-sort-spaced">
          <header className="card-header">
            <div className='card-header-title has-text-weight-normal'>
              <div className="field is-horizontal">
                <div className="field-body">

                  <div className="field is-horizontal is-align-items-center">
                    <div className="field-label">
                      <label htmlFor="search" className="label">
                        {useFormatMessage('Orders.search')}
                      </label>
                    </div>
                    <div className="field-body">
                      <div className="control">  
                        <input
                          id="search"
                          type="text"
                          minLength="3"
                          className="input"
                          value={search}
                          onChange={searchHandle}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal is-align-items-center">
                    <div className="field-body">
                      <div className="control">
                        <DatePicker
                          id="start-date"
                          name='filterStartDate'
                          onChange={value => onChangeStartDate(value)}
                          selected={filterStartDate}
                          placeholderText="Start Date"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal is-align-items-center">
                    <div className="field-body">
                      <div className="control">
                        <DatePicker
                          id="end-date"
                          name='filterEndDate'
                          onChange={value => onChangeEndDate(value)}
                          selected={filterEndDate}
                          placeholderText="End Date"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="field is-horizontal is-align-items-center">
                    <div className="field-body">
                      <div className="control">
                        <button
                          className="button is-link"
                          type="button"
                          onClick={exportOrdersHandle}
                        >Export</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
          <TableNew 
            columnsStructure={columnsStructure} 
            fetchDataFn={getOrderList}
            onRowClick={onRowClickHandle}
            additionalFilter={additionalFilter}
          />
        </div>
      </section>
    </>
  );
};

export default Orders;
