import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';
import { firebaseError, getApiUrlByHost } from 'utils';
import firebase from 'firebase.js';

import {
  fetchCollection,
  updateDocument,
} from '../api';

export const SETTINGS_FETCH_DATA_INIT = createAction('SETTINGS_FETCH_DATA_INIT');
export const SETTINGS_FETCH_DATA_SUCCESS = createAction(
  'SETTINGS_FETCH_DATA_SUCCESS'
);
export const SETTINGS_FETCH_DATA_FAIL = createAction('SETTINGS_FETCH_DATA_FAIL');
export const SETTINGS_MODIFY_INIT = createAction('SETTINGS_MODIFY_INIT');
export const SETTINGS_MODIFY_SUCCESS = createAction(
  'SETTINGS_MODIFY_SUCCESS'
);
export const SETTINGS_MODIFY_SETTING_SUCCESS = createAction(
  'SETTINGS_MODIFY_SETTING_SUCCESS'
);

export const SETTINGS_MODIFY_FAIL = createAction('SETTINGS_MODIFY_FAIL');

export const SETTINGS_CLEAN_UP = createAction('SETTINGS_CLEAN_UP');

export const SETTINGS_CLEAR_DATA_LOGOUT = createAction('SETTINGS_CLEAR_DATA_LOGOUT');

export const fetchSettings = () => {
  return async (dispatch) => {
    dispatch(SETTINGS_FETCH_DATA_INIT());

    let settings;

    try {
      settings = await fetchCollection('settings');
    } catch (error) {
      return dispatch(SETTINGS_FETCH_DATA_FAIL({ error }));
    }

    if (!settings) {
      const errorMessage = 'Settings not found';
      toastr.info('', errorMessage);
      return dispatch(SETTINGS_FETCH_DATA_FAIL({ error: errorMessage }));
    }

    return dispatch(
      SETTINGS_FETCH_DATA_SUCCESS({
        data: settings,
      })
    );
  };
};


export const clearSettingsDataLogout = () => {
  return (dispatch) => {
    dispatch(SETTINGS_CLEAR_DATA_LOGOUT());
  };
};

export const modifySettings = (settings) => {
  return async (dispatch, getState) => {
    dispatch(SETTINGS_MODIFY_INIT());
    const { locale } = getState().preferences;

    const settingsData = [];

    try {
      if (settings) {
        Object.keys(settings).forEach(async(id) => {
          settingsData.push({id, value: settings[id]});
          await updateDocument('settings', id, {value: settings[id]} );
        });
      }
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        SETTINGS_MODIFY_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'Settings updated successfully');
    return dispatch(SETTINGS_MODIFY_SUCCESS({settings: settingsData}));
  };
};

export const modifySetting = ({id, value}) => {
  return async (dispatch, getState) => {
    dispatch(SETTINGS_MODIFY_INIT());
    const { locale } = getState().preferences;
    const settingData = { value };

    try {
      await updateDocument('settings', id, settingData);
    } catch (error) {
      const errorMessage = firebaseError(error.code, locale);
      toastr.error('', errorMessage);
      return dispatch(
        SETTINGS_MODIFY_FAIL({
          error: errorMessage,
        })
      );
    }

    toastr.success('', 'Setting updated successfully');
    return dispatch(SETTINGS_MODIFY_SETTING_SUCCESS({ setting: settingData, id }));
  };
};

export const settingsCleanUp = () => (dispatch) => dispatch(SETTINGS_CLEAN_UP());

export const getSynonyms = async() => {
  let synonyms = '';
  const apiUrl = getApiUrlByHost();

  try {
    const getSynonymsHandle = firebase
      .functions()
      .httpsCallable('httpsGetSynonyms');

    synonyms = await getSynonymsHandle({apiUrl});
  }
  catch (error) {
    console.log(error);
    toastr.error('', 'Error get Synonyms');
  }

  return synonyms;
};

export const getGiftcardToken = async(email, password) => {
  let token = '';
  try {
    const getTokenHandle = firebase
      .functions()
      .httpsCallable('httpsGetGiftcardToken');

    const tokenResponse = await getTokenHandle({email, password});
    if (tokenResponse && tokenResponse.data) {
      token = tokenResponse.data;
    }
  }
  catch (error) {
    console.log(error);
    toastr.error('', 'Error get Token');
  }

  return token;
};

export const setSynonyms = async(text) => {
  const apiUrl = getApiUrlByHost();

  try {
    const setSynonymsHandle = firebase
      .functions()
      .httpsCallable('httpsSetSynonyms');
    
    const response = await setSynonymsHandle({apiUrl, text});

    if(response.data.status === 'success'){
      toastr.success('', 'Synonyms updated successfully');
    }
  }
  catch (error) {
    console.log(error);
    toastr.error('', 'Error set Synonyms');
  }
};