import React, {useEffect} from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import * as yup from 'yup';
import { Link } from 'react-router-dom';
import paths from 'pages/Router/paths';
import { useFormatMessage } from 'hooks';
import UserForm from 'components/UserForm';
import { modifyUser } from 'state/actions/users';
import ChangePassword from 'components/ChangePassword';

const schema = yup.object().shape({
  name: yup.string().required(),
  isAdmin: yup.boolean().notRequired(),
  location: yup.string().notRequired(),
  createdAt: yup.string().required(),
});

const Profile = ({ onParamsChange }) => {
  const { userData } = useSelector(
    (state) => ({
      userData: state.auth.userData,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const onSubmitHandler = (value) => {
    const additionalData = { ...userData?.additionalData } || {};
    if(value.stores && value.stores.length > 0){
      const userStores = value.stores?.map((store) => store.value);
      additionalData.stores = userStores;
    }

    const newUser = {
      ...value,
      additionalData,
      file: value?.file[0] || null,
      isEditing: true,
      isProfile: true,
      id: userData.id,
    };

    dispatch(modifyUser(newUser));
  };

  const goBackMessage = useFormatMessage('UserForm.goBack');
  const titleSection = useFormatMessage('Profile.profile');

  useEffect(() => {
    if (onParamsChange) {
      onParamsChange({ title: titleSection });
    }
  }, []);

  return (
    <>
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <Link to={paths.USERS} className="button has-icon">
            <span className="icon">
              <i className="mdi mdi-arrow-left"/>
            </span>
            {goBackMessage}
          </Link>
        </div>
      </section>
      <section className="section is-main-section">
        <UserForm
          isEditing
          isProfile
          user={userData}
          onSubmitHandler={onSubmitHandler}
          schema={schema}
        />
        <ChangePassword />
      </section>
    </>
  );
};

export default Profile;
