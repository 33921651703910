import { 
  collection,
  where,
  and,
  or,
  orderBy,
  limit,
  limitToLast,
  query,
  getFirestore,
  getDocs,
  deleteDoc,
  doc,
  onSnapshot,
  setDoc,
  addDoc,
  updateDoc,
  getDoc,
  getCountFromServer,
  startAfter,
  endBefore
} from "firebase/firestore";

const getFirestoreRef = (path) => {
  const db = getFirestore();

  return collection(db, path);
};

let snapshotCollectionsItem = [];

export const fetchDocument = async (collectionName, id, callback = null) => {
  const db = getFirestore();
  const docRef = doc(db, collectionName, id);
  
  if (callback) {
    if (typeof snapshotCollectionsItem[collectionName] === 'undefined') {
      snapshotCollectionsItem[collectionName] = [];
    }

    if (!snapshotCollectionsItem[collectionName][id]) {
      snapshotCollectionsItem[collectionName][id] = onSnapshot(docRef, (docSnapshot) => {
        let orderData = null;
        if (docSnapshot.exists())
          orderData = {id: docSnapshot.id, ...docSnapshot.data()};

        callback(orderData);
      }, err => {
        // eslint-disable-next-line
        console.log(`Encountered error: ${err}`);
      });
    }
  }

  const docSnap = await getDoc(docRef);
  if (!docSnap.exists()) {
    return null;
  }

  return { id: docSnap.id, ...docSnap.data() };
};

let snapshotCollections = [];

export const queryBuilder = ({queries, relationship}) => {
  const queryOptions = [];
  let response = null;

  queries.forEach((el) => {
    if (el.queries && el.relationship) {
      const subQueryOptions = queryBuilder(el);
      queryOptions.push(subQueryOptions);
    }
    else{
      const { attribute, operator, value } = el;
      queryOptions.push(where(attribute, operator, value));
    }
  });

  if (relationship === 'or') {
    response = or(...queryOptions);
  }

  if (relationship === 'and') {
    response = and(...queryOptions);
  }

  return response;
};

export const fetchCollection = async (collectionName, options = {}, callback = null) => {  
  const data = [];
  const baseQuery = getFirestoreRef(collectionName);

  let queryOptions = [];

  const mainQueryOptions = [];
  if (options.queries) {
    const { queries } = options;
    queries.forEach(({ attribute, operator, value }) => {
      mainQueryOptions.push(where(attribute, operator, value));
    });
  }

  let subQueryOptions = null;
  if (options.subQueries) {
    const { subQueries } = options;
    subQueryOptions = queryBuilder(subQueries);
  }

  if (mainQueryOptions.length || subQueryOptions) {
    if (mainQueryOptions.length && subQueryOptions) {
      queryOptions.push(and(...mainQueryOptions, subQueryOptions));
    }
    else if(mainQueryOptions.length){
      queryOptions = queryOptions.concat(mainQueryOptions);
    }
    else if (subQueryOptions) {
      queryOptions = queryOptions.concat(subQueryOptions);
    }
  }

  if (options.sort) {
    const { sort } = options;

    sort.forEach(({ attribute, order }) => {
      queryOptions.push(orderBy(attribute, order));
    });
  }

  if (options.cursorId && options.direction) {
    const { cursorId, direction } = options;
    const docSnap = await getDoc(doc(baseQuery, cursorId));
    
    if (direction === 'next') {
      queryOptions.push(startAfter(docSnap));
    }

    if (direction === 'prev') {
      queryOptions.push(endBefore(docSnap));
    }
  }

  if (options.limit) {
    const { limit: limitOption } = options;

    if (options.direction && options.direction === 'prev') {
      queryOptions.push(limitToLast(limitOption));
    }
    else{
      queryOptions.push(limit(limitOption));
    }
  }

  const q = query(baseQuery, ...queryOptions);

  if (callback && !snapshotCollections[collectionName] ) {
    snapshotCollections[collectionName] = onSnapshot(q, 
      (querySnapshot) => {
        const ordersData = [];
        querySnapshot.forEach((docItem) => {
          ordersData.push({ id: docItem.id, ...docItem.data() });
        });
        callback(ordersData);
      },
      (error) => {
        // eslint-disable-next-line
        console.log(`Encountered error: ${error}`);
      });
  }
  
  (await getDocs(q)).forEach((docItem) =>
    data.push({ id: docItem.id, ...docItem.data() })
  );

  return data;
};

export const deleteDocument = (collectionName, id) => {
  const db = getFirestore();
  return deleteDoc(doc(db, collectionName, id));
};

export const createDocument = (collectionName, id, values) => {
  const db = getFirestore();
  return setDoc(doc(db, collectionName, id), values);
};

export const addDocument = (collectionName, values) => {
  const db = getFirestore();
  return addDoc(collection(db, collectionName), values);
};

export const updateDocument = (collectionName, id, values) => {
  const db = getFirestore();
  return updateDoc(doc(db, collectionName, id), values);
};

export const clearSnapshotVariable = (name) => {
  if (snapshotCollections && snapshotCollections[name]) {
    snapshotCollections[name]();
    delete snapshotCollections[name];
  }

  if (snapshotCollectionsItem && snapshotCollectionsItem[name]) {
    snapshotCollectionsItem[name]();
    delete snapshotCollectionsItem[name];
  }
};

export const clearSnapshotVariables = () => {
  if (snapshotCollections) {
    Object.keys(snapshotCollections).forEach(functionKey => {
      snapshotCollections[functionKey]();
    });
  }

  if (snapshotCollectionsItem) {
    Object.keys(snapshotCollectionsItem).forEach(functionKey => {
      snapshotCollectionsItem[functionKey]();
    });
  }


  snapshotCollectionsItem = [];
  snapshotCollections = [];
};

export const getCountDocuments = async(collectionName, options = {}) => {
  const baseQuery = getFirestoreRef(collectionName);

  let queryOptions = [];

  const mainQueryOptions = [];
  if (options.queries) {
    const { queries } = options;
    queries.forEach(({ attribute, operator, value }) => {
      mainQueryOptions.push(where(attribute, operator, value));
    });
  }

  let subQueryOptions = null;
  if (options.subQueries) {
    const { subQueries } = options;
    subQueryOptions = queryBuilder(subQueries);
  }

  if (mainQueryOptions.length || subQueryOptions) {
    if (mainQueryOptions.length && subQueryOptions) {
      queryOptions.push(and(...mainQueryOptions, subQueryOptions));
    }
    else if(mainQueryOptions.length){
      queryOptions = queryOptions.concat(mainQueryOptions);
    }
    else if (subQueryOptions) {
      queryOptions = queryOptions.concat(subQueryOptions);
    }
  }

  if (options.sort) {
    const { sort } = options;

    sort.forEach(({ attribute, order }) => {
      queryOptions.push(orderBy(attribute, order));
    });
  }

  if (options.limit) {
    const { limit: limitOption } = options;
    queryOptions.push(limit(limitOption));
  }

  const q = query(baseQuery, ...queryOptions);
  const snapshot = await getCountFromServer(q);

  return snapshot.data().count;
};