import { createReducer } from 'redux-act';

import {
  INTEGRATOR_COLORS_FETCH_DATA_INIT,
  INTEGRATOR_COLORS_FETCH_DATA_SUCCESS,
  INTEGRATOR_COLORS_FETCH_DATA_FAIL,
  INTEGRATOR_COLORS_CLEAN_UP,
  INTEGRATOR_COLORS_CLEAR_DATA_LOGOUT,
} from 'state/actions/integratorColors';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

export const integratorColorsReducer = createReducer(
  {
    [INTEGRATOR_COLORS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [INTEGRATOR_COLORS_FETCH_DATA_SUCCESS]: (state, { data }) => ({
      ...state,
      data,
      loading: false,
      error: null,
    }),
    [INTEGRATOR_COLORS_FETCH_DATA_FAIL]: (state, { error }) => ({
      ...state,
      loading: false,
      error,
    }),
    [INTEGRATOR_COLORS_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
    }),
    [INTEGRATOR_COLORS_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
  },
  initialState
);
