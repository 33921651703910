import { createReducer } from 'redux-act';

import {
  COUPONS_FETCH_DATA_INIT,
  COUPONS_FETCH_DATA_SUCCESS,
  COUPONS_FETCH_DATA_FAIL,
  COUPONS_DELETE_COUPON_INIT,
  COUPONS_DELETE_COUPON_SUCCESS,
  COUPONS_DELETE_COUPON_FAIL,
  COUPONS_CREATE_COUPON_INIT,
  COUPONS_CREATE_COUPON_SUCCESS,
  COUPONS_CREATE_COUPON_FAIL,
  COUPONS_MODIFY_COUPON_INIT,
  COUPONS_MODIFY_COUPON_SUCCESS,
  COUPONS_MODIFY_COUPON_FAIL,
  COUPONS_MODIFY_ACTIVATIONS_INIT,
  COUPONS_MODIFY_ACTIVATIONS_FAIL,
  COUPONS_MODIFY_ACTIVATIONS_SUCCESS,
  COUPONS_CLEAN_UP,
  COUPONS_CLEAR_DATA_LOGOUT,
} from 'state/actions/coupons';

const initialState = {
  data: [],
  usersCoupons: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

export const couponsReducer = createReducer(
  {
    [COUPONS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [COUPONS_FETCH_DATA_SUCCESS]: (state, { data, usersCoupons }) => ({
      ...state,
      data,
      usersCoupons,
      loading: false,
      error: null,
    }),
    [COUPONS_FETCH_DATA_FAIL]: (state, { error }) => ({
      ...state,
      loading: false,
      error,
    }),
    [COUPONS_DELETE_COUPON_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [COUPONS_DELETE_COUPON_SUCCESS]: (state, { id }) => {
      const { data } = state;

      return ({
        ...state,
        data: data.filter((elem) => elem.id !== id),
        loading: false,
        error: null,
        deleted: true,
      });
    },
    [COUPONS_DELETE_COUPON_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [COUPONS_CREATE_COUPON_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [COUPONS_CREATE_COUPON_SUCCESS]: (state, { coupon }) => {
      const { data } = state;

      return ({
        ...state,
        data: data.concat(coupon),
        loading: false,
        error: null,
        success: true,
      });
    },
    [COUPONS_CREATE_COUPON_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [COUPONS_MODIFY_COUPON_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [COUPONS_MODIFY_ACTIVATIONS_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [COUPONS_MODIFY_COUPON_SUCCESS]: (state, { id, coupon }) => {
      const { data } = state;

      return ({
        ...state,
        data: !data
          ? []
          : data.map((elem) => {
              if (elem.id === id) {
                return {
                  name: coupon.name,
                  storeName: coupon.storeName,
                  store: coupon.store,
                  id,
                  startDate: coupon.startDate,
                  endDate: coupon.endDate,
                  description: coupon.description,
                  logoUrl: coupon.logoUrl,
                  createdAt: coupon.createdAt,
                  unlimited: coupon?.unlimited ? coupon.unlimited : false
                };
              }
              return elem;
            }),
        loading: false,
        error: null,
        success: true,
      });
    },
    [COUPONS_MODIFY_COUPON_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [COUPONS_MODIFY_ACTIVATIONS_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [COUPONS_MODIFY_ACTIVATIONS_SUCCESS]: (state, { userId, userCouponsData }) => {
      const { usersCoupons } = state;

      return ({
        ...state,
        usersCoupons: !usersCoupons
          ? []
          : usersCoupons.map((elem) => {
              if (elem.id === userId) {
                return {
                  coupons: userCouponsData.coupons,
                  id: userId
                };
              }
              return elem;
            }),
        loading: false,
        error: null
      });
    },
    [COUPONS_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
    }),
    [COUPONS_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
  },
  initialState
);
