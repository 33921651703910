import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import Login from 'pages/Login';
import Home from 'pages/Home';
import Users from 'pages/Users';
import Profile from 'pages/Profile';
import Orders from 'pages/Orders';
import Stores from 'pages/Stores';
import Coupons from 'pages/Coupons';
import OrdersBoard from 'pages/OrdersBoard';
import ResetPassword from 'pages/ResetPassword';
import NotFound from 'pages/NotFound';
import User from 'pages/User';
import Order from 'pages/Order';
import Store from 'pages/Store';
import StoreCategories from 'pages/StoreCategories';
import StoreTags from 'pages/StoreTags';
import StoreComplexMapping from 'pages/StoreComplexMapping';
import Coupon from 'pages/Coupon';
import Section from 'pages/Section';
import ColorsGrouping from 'pages/ColorsGrouping';
import SizesGrouping from 'pages/SizesGrouping';
import Settings from 'pages/Settings';
import SettingsKumen from 'pages/Kumen/Settings';
import Restaurants from 'pages/Kumen/Restaurants';
import Restaurant from 'pages/Kumen/Restaurant';
import Categories from 'pages/Categories';
import Category from 'pages/Category';
import paths from './paths';
import PrivateRoute from './PrivateRoute';

const RouterComponent = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={paths.LOGIN} component={Login} />
        <Route exact path={paths.RESET_PASSWORD} component={ResetPassword} />
        <PrivateRoute path={paths.ADD_USER} component={User} />
        <PrivateRoute path={paths.MODIFY_USER} component={User} rule="edit user"/>
        <PrivateRoute path={paths.USERS} component={Users} rule="view user list"/>
        <PrivateRoute path={paths.PROFILE} component={Profile} />
        <PrivateRoute path={paths.ORDERS_BOARD} component={OrdersBoard} rule="view order board"/>
        <PrivateRoute path={paths.MODIFY_ORDER} component={Order} rule="view order item"/>
        <PrivateRoute path={paths.ORDERS} component={Orders} rule="view order list"/>
        <PrivateRoute path={paths.ADD_STORE} component={Store} rule="create store"/>
        <PrivateRoute path={paths.STORE_CATEGORIES} component={StoreCategories} rule="categories mapping"/>
        <PrivateRoute path={paths.STORE_TAGS} component={StoreTags} rule="tags mapping"/>
        <PrivateRoute path={paths.STORE_COMPLEX_MAPPING} component={StoreComplexMapping} rule="complex mapping"/>
        <PrivateRoute path={paths.MODIFY_STORE} component={Store} rule="edit store"/>
        <PrivateRoute path={paths.STORES} component={Stores} rule="view stores list"/>
        <PrivateRoute path={paths.ADD_COUPON} component={Coupon} rule="create coupon"/>
        <PrivateRoute path={paths.MODIFY_COUPON} component={Coupon} rule="edit coupon"/>
        <PrivateRoute path={paths.COUPONS} component={Coupons} rule="view coupons list"/>
        <PrivateRoute path={paths.COLORS_GROUPING} component={ColorsGrouping} rule="view settings"/>
        <PrivateRoute path={paths.SIZES_GROUPING} component={SizesGrouping} rule="view settings"/>
        <PrivateRoute path={paths.SETTINGS} component={Settings} rule="view settings"/>
        <PrivateRoute path={paths.SETTINGS_KUMEN} component={SettingsKumen} rule="edit kumen settings"/>
        <PrivateRoute path={paths.ADD_RESTAURANT} component={Restaurant} rule="create restaurant" />
        <PrivateRoute path={paths.MODIFY_RESTAURANT} component={Restaurant} rule="edit restaurant"/>
        <PrivateRoute path={paths.RESTAURANTS} component={Restaurants} rule="view restaurant list"/>
        <PrivateRoute path={paths.ADD_CATEGORY} component={Category} rule="create category" />
        <PrivateRoute path={paths.MODIFY_CATEGORY} component={Category} rule="edit category"/>
        <PrivateRoute path={paths.CATEGORIES} component={Categories} rule="view category list"/>
        <PrivateRoute path={paths.SECTION} component={Section} />
        <PrivateRoute path={paths.ROOT} component={Home} />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default RouterComponent;
