import { createReducer } from 'redux-act';

import {
  RESTAURANTS_FETCH_DATA_INIT,
  RESTAURANTS_FETCH_DATA_SUCCESS,
  RESTAURANTS_FETCH_DATA_FAIL,
  RESTAURANTS_DELETE_RESTAURANT_INIT,
  RESTAURANTS_DELETE_RESTAURANT_SUCCESS,
  RESTAURANTS_DELETE_RESTAURANT_FAIL,
  RESTAURANTS_CREATE_RESTAURANT_INIT,
  RESTAURANTS_CREATE_RESTAURANT_SUCCESS,
  RESTAURANTS_CREATE_RESTAURANT_FAIL,
  RESTAURANTS_MODIFY_RESTAURANT_INIT,
  RESTAURANTS_MODIFY_RESTAURANT_SUCCESS,
  RESTAURANTS_MODIFY_RESTAURANT_FAIL,
  RESTAURANTS_CLEAN_UP,
  RESTAURANTS_CLEAR_DATA_LOGOUT,
} from 'state/actions/restaurants';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

export const restaurantsReducer = createReducer(
  {
    [RESTAURANTS_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [RESTAURANTS_FETCH_DATA_SUCCESS]: (state, { data }) => ({
      ...state,
      data,
      loading: false,
      error: null,
    }),
    [RESTAURANTS_FETCH_DATA_FAIL]: (state, { error }) => ({
      ...state,
      loading: false,
      error,
    }),
    [RESTAURANTS_DELETE_RESTAURANT_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [RESTAURANTS_DELETE_RESTAURANT_SUCCESS]: (state, { id }) => {
      const { data } = state;

      return ({
        ...state,
        data: data.filter((elem) => elem.id !== id),
        loading: false,
        error: null,
        deleted: true,
      });
    },
    [RESTAURANTS_DELETE_RESTAURANT_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [RESTAURANTS_CREATE_RESTAURANT_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [RESTAURANTS_CREATE_RESTAURANT_SUCCESS]: (state, { restaurant }) => {
      const { data } = state;

      return ({
        ...state,
        data: data.concat(restaurant),
        loading: false,
        error: null,
        success: true,
      });
    },
    [RESTAURANTS_CREATE_RESTAURANT_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [RESTAURANTS_MODIFY_RESTAURANT_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [RESTAURANTS_MODIFY_RESTAURANT_SUCCESS]: (state, { id, restaurant }) => {
      const { data } = state;

      return ({
        ...state,
        data: !data
          ? []
          : data.map((elem) => {
              if (elem.id === id) {
                return {
                  name: restaurant.name,
                  order: restaurant.order,
                  menu: restaurant.menu,
                  id,
                  logoUrl: restaurant.logoUrl,
                  bgUrl: restaurant.bgUrl,
                  createdAt: restaurant.createdAt,
                };
              }
              return elem;
            }),
        loading: false,
        error: null,
        success: true,
      });
    },
    [RESTAURANTS_MODIFY_RESTAURANT_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [RESTAURANTS_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
    }),
    [RESTAURANTS_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
  },
  initialState
);
