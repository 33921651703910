/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import classNames from 'classnames';
import { yupResolver } from '@hookform/resolvers';
import Select from "react-select";
import { checkAccess } from 'permissions';
import { couponsCleanUp } from 'state/actions/coupons';
import { useFormatMessage } from 'hooks';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import ErrorMessage from 'components/ErrorMessage';
import paths from 'pages/Router/paths';

import './CouponForm.scss';

const CouponForm = ({ isEditing, coupon, onSubmitHandler, schema }) => {
  const { loading, success, stores } = useSelector(
    (state) => ({
      loading: state.coupons.loading,
      success: state.coupons.success,
      stores: state.stores.data,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const { register, handleSubmit, errors, control, watch, setValue } = useForm({
    defaultValues: {
      ...coupon
    },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (success) {
      setValue('file', null);
    }
    return () => dispatch(couponsCleanUp());
  }, [dispatch, success, setValue]);

  let imagePreviewUrl =
    watch('file') && watch('file')[0]
      ? URL.createObjectURL(watch('file')[0])
      : coupon.logoUrl;

  if(imagePreviewUrl){
    const imagePreviewUrlObj = new URL(imagePreviewUrl);
    if(imagePreviewUrlObj.protocol !== 'blob:'){
      const urlSearchParams = imagePreviewUrlObj.searchParams;
      urlSearchParams.set('version', Date.now());
      imagePreviewUrlObj.search = urlSearchParams.toString();
      imagePreviewUrl = imagePreviewUrlObj.toString();
    }
  }

  const usedCount = coupon.usedCount ? coupon.usedCount : 0;
  
  const pickAnotherFileMessage = useFormatMessage('CouponForm.pickAnotherFile');
  const pickFileMessage = useFormatMessage('CouponForm.pickFile');

  const infoLabel = useFormatMessage('CouponForm.couponInfo');
  const previewLabel = useFormatMessage('CouponForm.couponPreview');
  const cantChangeStartDateMsg = useFormatMessage('CouponForm.cantChangeStartDate');

  const permissionToEditCoupon = checkAccess('edit coupon');
  let disabledStartDate = false;
  let disabledEndDate = false;

  const currentDate = new Date();
  const couponStartDate = new Date(coupon.startDate);
  const couponEndDate = new Date(coupon.endDate);

  if(isEditing){
    if(couponStartDate < currentDate){
      disabledStartDate = true;
    }
    if(couponEndDate < currentDate){
      disabledEndDate = true;
    }
  }

  return (
    <>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-account-edit default" />
                </span>
                { infoLabel }
              </p>
            </header>
            <div className="card-content">
              <form onSubmit={handleSubmit(onSubmitHandler)}>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('CouponForm.name')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          name="name"
                          id="name"
                          className={classNames('input', {
                            'is-danger': errors.name,
                          })}
                          ref={register}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {errors.name && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}
                
                <hr />

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('CouponForm.storeName')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          name="storeName"
                          id="storeName"
                          className={classNames('input', {
                            'is-danger': errors.storeName,
                          })}
                          ref={register}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {errors.storeName && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">{ useFormatMessage('UserForm.store') }</label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <div className="is-block">
                          <Controller                            
                            name="store"
                            control={control}
                            render={({ onChange, ref, value }) => (
                              <Select
                                isClearable
                                inputRef={ref}
                                options={stores?.map((store) => ({value: store.id, label: store.name}))}
                                onChange={val => onChange(val?.value || '')}
                                value={stores?.filter((store) => store.id === value).map((store) => ({value: store.id, label: store.name}))}
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {errors.store && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage text={errors.store.message} />
                    </div>
                  </div>
                )}

                <hr />
                
                {disabledStartDate && !disabledEndDate &&
                  <div className="notification is-warning">
                    <p>{cantChangeStartDateMsg}</p>
                  </div>
                }

                {disabledStartDate && disabledEndDate &&
                  <div className="notification is-warning">
                    <p>{useFormatMessage('CouponForm.cantChangeDate')}</p>
                  </div>
                }

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('CouponForm.startDate')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <Controller
                        control={control}
                        name="startDate"
                        render={({ onChange, name, value }) => (
                          <DatePicker
                            disabled={disabledStartDate}
                            name={name}
                            onChange={onChange}
                            selected={value ? new Date(value) : ''}
                            minDate={new Date()}
                            className="input"
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                {errors.startDate && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage text={errors.startDate.message} />
                    </div>
                  </div>
                )}

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('CouponForm.endDate')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <Controller
                        control={control}
                        name="endDate"
                        render={({ onChange, name, value }) => (
                          <DatePicker
                            disabled={disabledEndDate}
                            name={name}
                            onChange={onChange}
                            selected={value ? new Date(value) : ''}
                            minDate={new Date()}
                            className="input"
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
                {errors.endDate && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage text={errors.endDate.message} />
                    </div>
                  </div>
                )}

                <hr />

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('CouponForm.description')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <textarea
                        className="textarea"
                        name="description"
                        id="description"
                        ref={register}
                      />
                    </div>
                  </div>
                </div>

                <div className="field has-check is-horizontal">
                    <div className="field-label">
                      <label className="label">{useFormatMessage('CouponForm.unlimited')}</label>
                    </div>
                    <div className="field-body">
                      <div className="field">
                        <div className="field">
                          <div className="control">
                            <label className="b-checkbox checkbox">
                              <input
                                type="checkbox"
                                name="unlimited"
                                ref={register}
                              />
                              <span className="check is-primary" />
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                <hr />

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('CouponForm.logo')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="file has-name">
                        <label className="file-label">
                          <input
                            className="file-input"
                            type="file"
                            name="file"
                            ref={register}
                            accept="image/*"
                          />
                          <span className="file-cta">
                            <span className="file-icon">
                              <i className="mdi mdi-upload" />
                            </span>
                            <span className="file-label">
                              {watch('file') && watch('file').file
                                ? pickAnotherFileMessage
                                : pickFileMessage}
                            </span>
                          </span>
                          <span className="file-name">
                            {watch('file') && watch('file')[0]?.name}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <hr />
                 
                <div className="field is-horizontal">
                  <div className="field-label" />
                  <div className="field-body">
                    <div className="field">
                      <div className="field is-grouped">
                        { permissionToEditCoupon &&
                          <>
                            <div className="control">
                              <button
                                type="submit"
                                className={`button blue-button ${
                                  loading && 'is-loading'
                                }`}
                              >
                                <span>{useFormatMessage('CouponForm.submit')}</span>
                              </button>
                            </div>

                            {isEditing &&
                              <div className="control">
                                <a
                                  href={`${paths.ADD_COUPON}?clonedId=${coupon.id}`}
                                  className={`button blue-button ${
                                    loading && 'is-loading'
                                  }`}
                                >
                                  <span>{useFormatMessage('CouponForm.clone')}</span>
                                </a>
                              </div>
                            }
                          </>
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <input 
                  type="hidden"
                  name="createdAt"
                  ref={register} />
              </form>
            </div>
          </div>
        </div>
        <div className="tile is-parent preview">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-account default" />
                </span>
                { previewLabel }
              </p>
            </header>
            <div className="card-content">
              {imagePreviewUrl && (
                <>
                  <div className="is-coupon-logo image has-max-width is-aligned-center">
                    <img
                      className="coupon-logo"
                      src={imagePreviewUrl}
                      alt="Coupon logo preview"
                    />
                  </div>
                  <hr />
                </>
              )}

              <div className="field">
                <label className="label">
                  {useFormatMessage('CouponForm.name')}
                </label>
                <div className="control is-clearfix">
                  <input
                    data-testid="name"
                    type="text"
                    readOnly="readOnly"
                    className="input is-static"
                    value={watch('name')}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">
                  {useFormatMessage('CouponForm.storeName')}
                </label>
                <div className="control is-clearfix">
                  <input
                    type="text"
                    readOnly="readOnly"
                    className="input is-static"
                    value={watch('storeName')}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">
                  {useFormatMessage('CouponForm.usedCount')}
                </label>
                <div className="control is-clearfix">
                  {usedCount}
                </div>
              </div>

              <hr/>

              <div className="field">
                <label className="label">
                  {useFormatMessage('CouponForm.description')}
                </label>
                <div className="control is-clearfix">
                  {watch('description')}
                </div>
              </div>

              <div className="field">
                <label className="label">
                  {useFormatMessage('CouponForm.unlimited')}
                </label>
                <div className="control is-clearfix">
                  {watch('unlimited') ? useFormatMessage('CouponForm.yes') : useFormatMessage('CouponForm.no')}
                </div>
              </div>

              <hr/>
              <div className="field">
                <label className="label">
                  {useFormatMessage('CouponForm.startDate')}
                </label>
                <div className="control is-clearfix">
                  {moment(new Date(watch('startDate'))).format("DD.MM.YYYY")}
                </div>
              </div>

              <div className="field">
                <label className="label">
                  {useFormatMessage('CouponForm.endDate')}
                </label>
                <div className="control is-clearfix">
                  {moment(new Date(watch('endDate'))).format("DD.MM.YYYY")}
                </div>
              </div>

              <div className="field">
                <label className="label">
                  {useFormatMessage('CouponForm.created')}
                </label>
                <div className="control is-clearfix" data-testid="date">
                  <p className="date">
                    {moment(new Date(watch('createdAt'))).format("DD.MM.YYYY")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

CouponForm.propTypes = {
  coupon: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    storeName: PropTypes.string,
    store: PropTypes.string,
    logoUrl: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    description: PropTypes.string,
    unlimited: PropTypes.bool,
  }).isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  schema: PropTypes.object.isRequired,
};

export default CouponForm;