import React, { useEffect, useMemo, useState } from 'react';
import { Link, useParams, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import Loader from 'components/Loader';
import * as yup from 'yup';
import CouponList from 'components/CouponList';
import StoreForm from 'components/StoreForm';
import { createStore, modifyStore, fetchStore } from 'state/actions/stores';
import { fetchCoupons, couponsCleanUp } from 'state/actions/coupons';
import paths from 'pages/Router/paths';
import { useFormatMessage } from 'hooks';

const Store = ({ onParamsChange }) => {
  const { id } = useParams();
  const [isRedirect, setIsRedirect] = useState(false);
  const [storeData, setStoreData] = useState(null);
  const isEditing = useMemo(() => !!id, [id]);

  const { coupons} = useSelector(
    (state) => ({
      coupons: state.coupons.data,
    }),
    shallowEqual
  );

  const schema = yup.object().shape({
    name: yup.string().required(),
    createdAt: yup.string().required(),
    integratorID: yup.string(),
    kennitala: yup.string(),
    merchantID: yup.string().when("sell", {
      is: true,
      then: yup.string().required()
    }),
    publicKey: yup.string().when("sell", {
      is: true,
      then: yup.string().required()
    }),
    privateKey: yup.string().when("sell", {
      is: true,
      then: yup.string().required()
    }),
    sell: yup.boolean(),
    orderExport: yup.boolean(),
    orderAPIKey: yup.string().when("orderExport", {
      is: true,
      then: yup.string().required()
    }),
    productsAPIKey: yup.string(),
    categoriesAPIKey: yup.string(),
    tagsAPIKey: yup.string(),
    alwaysInStock: yup.boolean(),
    collectionID: yup.string(),
    ordersCompanyID: yup.string(),
    minStockQty: yup.number().transform( cv => Number.isNaN(cv) ? undefined : cv).positive().integer(),
    giftcardEmail: yup.string().email().when("sell", {
      is: true,
      then: yup.string().email().required()
    }),
    giftcardPassword: yup.string().when("sell", {
      is: true,
      then: yup.string().required()
    }),
    giftcardToken: yup.string().when("sell", {
      is: true,
      then: yup.string().required()
    }),
    orderExportStatus: yup.string(),
    serviceFee: yup.number()
      .typeError("Field must be a number")
      .nullable()
      .moreThan(0, "Floor area cannot be negative")
      .transform((_, val) => (val !== "" ? Number(val) : null)),
    paymentFee: yup.number()
      .typeError("Field must be a number")
      .nullable()
      .moreThan(0, "Floor area cannot be negative")
      .transform((_, val) => (val !== "" ? Number(val) : null)),
    shippingFee: yup.number()
      .typeError("Field must be a number")
      .nullable()
      .moreThan(0, "Floor area cannot be negative")
      .transform((_, val) => (val !== "" ? Number(val) : null)),
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (isEditing) {
      if (!coupons) {
        dispatch(fetchCoupons());
      }
    }

    return () => dispatch(couponsCleanUp());
  }, [isEditing, id, storeData, dispatch, coupons]);


  useEffect(() => {
    const fetchData = async(storeId) => {
      try {
        const result = await fetchStore(storeId);
        if(result){
          if(!result.orderExportStatus){
            result.orderExportStatus = 'ready';
          }
          setStoreData(result);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    if(id){
      fetchData(id);
    }  

  }, []);

  const couponsData = coupons.filter(item => item.store === id);

  const redirect = (isRedirect) && (
    <Redirect to={paths.STORES} />
  );

  const goBackMessage = useFormatMessage('StoreForm.goBack');
  const categoriesMappingMessage = useFormatMessage('StoreForm.categoriesMapping');
  const tagsMappingMessage = useFormatMessage('StoreForm.tagsMapping');
  const complexMappingMessage = useFormatMessage('StoreForm.complexMapping');
  const uncategorizedProductsMessage = useFormatMessage('StoreForm.uncategorizedProducts');

  let titleSection = useFormatMessage('Store.newStore');
  if(isEditing){
    titleSection = useFormatMessage('Store.editStore');
  }

  useEffect(() => {
    if (onParamsChange) {
      onParamsChange({ title: titleSection });
    }
  }, []);

  const onSubmitHandler = async(value) => {
    const newStore = {
      ...value,
      createdAt: new Date(value.createdAt).toDateString(),
      file: value?.file[0] || null,
      isEditing,
      id,
    };

    if (isEditing) {
      try{
        await modifyStore(newStore);
        setIsRedirect(true);
      }
      catch{
        setIsRedirect(false);
      }
    } else {
      try{
        await createStore(newStore);
        setIsRedirect(true);
      }
      catch{
        setIsRedirect(false);
      }
    }
  };

  return (
    <>
      {redirect}
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <Link to={paths.STORES} className="button has-icon">
            <span className="icon">
              <i className="mdi mdi-arrow-left"/>
            </span>
            {goBackMessage}
          </Link>
          {isEditing &&
            <>
              <Link to={`${paths.STORES}/${id}/categories`} className="button has-icon ml-2">
                <span className="icon">
                  <i className="mdi mdi-format-list-bulleted"/>
                </span>
                {categoriesMappingMessage}
              </Link>

              <Link to={`${paths.STORES}/${id}/tags`} className="button has-icon ml-2">
                <span className="icon">
                  <i className="mdi mdi-format-list-bulleted"/>
                </span>
                {tagsMappingMessage}
              </Link>

              <Link to={`${paths.STORES}/${id}/complex-mapping`} className="button has-icon ml-2">
                <span className="icon">
                  <i className="mdi mdi-format-list-bulleted"/>
                </span>
                {complexMappingMessage}
              </Link>
              <a 
                href={`https://www.kringlan.is/vorur?parentCategory=uncategorized&stores=${storeData?.name}`} 
                className="button has-icon ml-2"
                target='_blank'
                rel='noopener noreferrer'
              >
                <span className="icon">
                  <i className="mdi mdi-format-list-bulleted"/>
                </span>
                {uncategorizedProductsMessage}
              </a>
            </>
          }
        </div>
      </section>
      <section className="section is-main-section">
        {isEditing && !storeData ? (
          <Loader />
        ) : (
          <StoreForm
            isEditing={isEditing}
            store={
              isEditing
                ? storeData
                : {
                    name: '',
                    integratorID: '',
                    kennitala: '',
                    merchantID: '',
                    publicKey: '',
                    privateKey: '',
                    sell: false,
                    orderExport: false,
                    orderAPIKey: '',
                    ordersCompanyID: '',
                    productsAPIKey: '',
                    categoriesAPIKey: '',
                    tagsAPIKey: '',
                    createdAt: new Date().toDateString(),
                    alwaysInStock: false,
                    collectionID: '',
                    minStockQty: '',
                    giftcardEmail: '',
                    giftcardPassword: '',
                    giftcardToken: '',
                    orderExportStatus: 'ready'
                  }
            }
            onSubmitHandler={onSubmitHandler}
            schema={schema}
          />
        )}
        {coupons && isEditing &&
          <CouponList coupons={couponsData} />
        }
      </section>
    </>
  );
};

export default Store;
