import React, { useEffect, useState, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useFormatMessage } from 'hooks';
import paths from 'pages/Router/paths';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { fetchIntegratorColors } from 'state/actions/integratorColors';
import { fetchSettings, modifySetting } from 'state/actions/settings';
import { useForm, useFieldArray, Controller } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import Select from "react-select";
import classNames from 'classnames';
import classes from './ColorsGrouping.module.scss';

const ColorsGrouping = ({ onParamsChange }) => {
  const { integratorColors, integratorColorsLoading, settingsData, settingsLoading } = useSelector(
    (state) => ({
      integratorColors: state.integratorColors.data,
      integratorColorsLoading: state.integratorColors.loading,
      settingsData: state.settings.data,
      settingsLoading: state.settings.loading,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const [isLoadedData, setIsLoadedData] = useState(false);
  const [savingColors, setSavingColors] = useState(false);

  useEffect(() => {
    dispatch(fetchIntegratorColors())
    .then(() => dispatch(fetchSettings())
      .then(() => setIsLoadedData(true))
    );
  }, []);

  const defaultValues = settingsData.find(setting => setting.id === 'colors')?.value || [];
  let otherColors = [...integratorColors];

  if(defaultValues && defaultValues.length > 0 ){
    let selectedColors = [];
    defaultValues.forEach(item => {
      selectedColors = selectedColors.concat(item.colors);
    });

    otherColors = integratorColors.filter(item => !selectedColors.includes(item.id));
  }

  const schema = yup.object().shape({
    groups: yup.array().of(
      yup.object().shape({
        title: yup.string().required(),
        hex_code: yup.string().required().min(6).max(6),
        colors: yup.array().min(1).of(yup.string().required())
      }),
    ),
  });

  const { control, handleSubmit, errors, reset } = useForm({
    resolver: yupResolver(schema),
    defaultValues
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "groups"
  });

  useEffect(() => {
    if (!integratorColorsLoading && !settingsLoading && isLoadedData) {
      if(defaultValues && defaultValues.length > 0){
        reset({groups:defaultValues});
      }
    }
  }, [integratorColorsLoading, settingsLoading, isLoadedData]);

  const titleField = useFormatMessage('ColorsGrouping.titleField');
  const hexCodeField = useFormatMessage('ColorsGrouping.hexCodeField');
  const goBackLabel = useFormatMessage('goBack');
  const submitLabel = useFormatMessage('submit');
  const addLabel = useFormatMessage('ColorsGrouping.addGroup');

  const titleSection = useFormatMessage('ColorsGrouping.title');

  useEffect(() => {
    if (onParamsChange) {
      onParamsChange({ title: titleSection });
    }
  }, []);

  const handleSubmitForm = (values) => {
    const value = values.groups ? [...values.groups] : [];
    setSavingColors(true);

    dispatch(modifySetting({value, id: 'colors'})).then(() => { 
      reset({groups: value});
      setSavingColors(false);
    });
  };

  return (
    <>
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <Link to={`${paths.SETTINGS}`} className="button has-icon">
            <span className="icon">
              <i className="mdi mdi-arrow-left"/>
            </span>
            {goBackLabel}
          </Link>
        </div>
      </section>
      <section className="section is-main-section">
        <div className="tile is-ancestor">
          <div className="tile is-parent">
            <div className="card tile is-child">
              <div className="card-content">
                <form onSubmit={handleSubmit(handleSubmitForm)}>
                  {fields.map((item, index) => (
                    <Fragment key={item.id}>
                      <div className={classNames("columns")}>

                        <div className="column is-2">
                          <div className="field">
                            <div className="control is-flex">
                              <div style={{background: item.hex_code ? `#${item.hex_code}` : 'transparent'}} className={classNames(classes.colorPreview, "mr-1")}/>
                              <Controller
                                as={<input />}
                                name={`groups[${index}].hex_code`}
                                control={control}
                                placeholder={hexCodeField}
                                defaultValue={item.hex_code}
                                className={classNames('input', {
                                  'is-danger': errors?.groups?.[index]?.hex_code,
                                })}
                              />
                            </div>
                          </div>

                          <div className="field">
                            <div className="control">
                              <Controller
                                as={<input />}
                                name={`groups[${index}].title`}
                                control={control}
                                placeholder={titleField}
                                defaultValue={item.title}
                                className={classNames('input', {
                                  'is-danger': errors?.groups?.[index]?.title,
                                })}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="column is-9">
                          <div className="field">
                            <div className="control">
                              <Controller                            
                                name={`groups[${index}].colors`}
                                control={control}
                                defaultValue={item.colors}
                                render={({ onChange, ref, value }) => (
                                  <Select
                                    styles={{
                                      control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: errors?.groups?.[index]?.colors ? 'red' : baseStyles.borderColor,
                                      }),
                                    }}
                                    isLoading={integratorColorsLoading}
                                    isClearable
                                    isMulti
                                    ref={ref}
                                    options={integratorColors?.map((color) => ({value: color.id, label: color.name}))}
                                    onChange={values => onChange(values.map((val => (val?.value || ''))))}
                                    value={integratorColors?.filter((color) => value.includes(color.id)).map((color) => ({value: color.id, label: color.name}))}
                                  />
                                )}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="column is-1">
                          <div className="field is-horizontal mt-1">
                            <div className="field-body">
                              <div className="control">
                                <button
                                  disabled={savingColors || !isLoadedData}
                                  type="button"
                                  className="button is-small is-danger"
                                  onClick={() => remove(index)}
                                >
                                  <span className="icon is-small">
                                    <i className="mdi mdi-trash-can" />
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr/>
                    </Fragment>
                  ))}

                  <div className="field mb-5">
                    <button
                      disabled={savingColors || !isLoadedData}
                      className="button"
                      type="button"
                      onClick={() => append({ title: "", hex_code: "", colors: "" })}
                    >
                      {addLabel}
                    </button>
                  </div>
                  <div className={classNames(classes.otherColorsContainer, "columns")}>
                    <div className="column is-1">
                      <div className={classNames(classes.colorPreview, classes.otherColor, "mr-1 mb-2")}/>
                      <div><p>Aðrir</p></div>
                    </div>
                    <div className="column is-11">
                      <div className={classNames(classes.otherColors)}>
                        {otherColors.map(color => (<span key={color.id}>{color.name}</span>))}
                      </div>
                    </div>
                  </div>

                  <hr/>

                  <div className="field">
                    <button
                      disabled={savingColors || !isLoadedData}
                      className="button blue-button"
                      type="submit" 
                    >
                      {submitLabel}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ColorsGrouping;
