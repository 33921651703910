/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {useEffect, useState} from 'react';
import { useFormatMessage } from 'hooks';
import { convertDateZeroHour } from 'utils';
import { fetchUsers } from 'state/actions/users';
import { removeCouponActivation } from 'state/actions/coupons';
import moment from 'moment';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import DatePicker from 'react-datepicker';
import classNames from 'classnames';
import './CouponActivations.scss';

const CouponActivations = ({ usersCoupons, couponIds }) => {
  const { usersList, loading } = useSelector(
    (state) => ({
      usersList: state.users.data,
      loading: state.coupons.loading,
    }),
    shallowEqual
  );

  const [filterStartDate, setFilterStartDate] = useState();
  const [filterEndDate, setFilterEndDate] = useState();

  let data = usersCoupons.reduce((activations, userActivations) => {
    const user = usersList.find(elem => elem.id === userActivations.id);

    const activationsByUser = userActivations.coupons.reduce((allUserActivation, activation) => {
      if(couponIds.includes(activation.couponId)){
        const item = {
          ...activation,
          userEmail: user?.email,
          userId: userActivations.id
        };
        return allUserActivation.concat(item);
      }

      return allUserActivation;
    }, []);

    return activations.concat(activationsByUser);
  }, []);

  data = filterStartDate ?
    data.filter(item => convertDateZeroHour(item.activateDate) >= convertDateZeroHour(filterStartDate))
  : data;

  data = filterEndDate ?
    data.filter(item => convertDateZeroHour(item.activateDate) <= convertDateZeroHour(filterEndDate))
  : data;

  const activationsCount = data.length;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUsers());

  }, [dispatch]);

  const resetActivation = (userId, activationDate, couponId) => {
    dispatch(removeCouponActivation(userId, activationDate, couponId));
  };


  const onChangeStartDate = (value) => {
    const startDate = value ? new Date(value) : '';
    setFilterStartDate(startDate);
  };

  const onChangeEndDate = (value) => {
    const endDate = value ? new Date(value) : '';
    setFilterEndDate(endDate);
  };

  const title = useFormatMessage('CouponForm.activations');
  const reset = useFormatMessage('CouponForm.reset');

  return (
    <div className={classNames('tile is-ancestor', { 'activations-loading': loading })}>
      <div className="tile is-parent">
        <div className="card tile is-child">
          <header className="card-header">
            <p className="card-header-title">
              <span className="icon">
                <i className="mdi mdi-account-check default" />
              </span>
              {title}
            </p>

            <div className="field is-grouped card-header-icon">
              <div className="control">
                <DatePicker
                  name='filterStartDate'
                  onChange={value => onChangeStartDate(value)}
                  selected={filterStartDate}
                  placeholderText="Start Date"
                />
              </div>
              <div className="control">
                <DatePicker
                  name='filterEndDate'
                  onChange={value => onChangeEndDate(value)}
                  selected={filterEndDate}
                  placeholderText="End Date"
                />
              </div>
              <p className="control">Count: { activationsCount }</p>
            </div>
          </header>
          <div className="card-content">
            <table className="table">
              <tbody>
                {data && data.length > 0 ? (
                  data.map((item) => (
                      <tr key={item.couponId + item.activateDate}>
                        <td>{item.userEmail}</td>
                        <td>{moment(item.activateDate).format("DD.MM.YYYY HH:MM")}</td>
                        <td><button onClick={() => resetActivation(item.userId, item.activateDate, item.couponId)} className="button is-danger">{reset}</button></td>
                      </tr>
                    )
                  ))
                  : 
                  (
                    <tr>
                      <td>
                        <p>No activations found</p>
                      </td>
                    </tr>
                  )
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>  
  );
};

export default CouponActivations;