import React, { useMemo, useState, useEffect } from "react";
import { useReactTable, flexRender, getCoreRowModel } from "@tanstack/react-table";
import Loader from 'components/Loader';
import './TableNew.css';

const defaultRowClick = () => null;

const TableNew = ({ columnsStructure, fetchDataFn, onRowClick = defaultRowClick, additionalFilter = null }) => {
  const columns = useMemo(
    () => columnsStructure,
    []
  );

  const defaultData = useMemo(() => [], []);

  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);

  const defaultPagination = {
    oldPageIndex: 0,
    pageIndex: 0,
    pageSize: 50,
  };

  const [pagination, setPagination] = useState(defaultPagination);

  useEffect(() => {
    let isSubscribed = true;

    const fetchData = async () => {
      setLoading(true);
      const {oldPageIndex, pageIndex, pageSize} = pagination;
      const queryParam = {
        limit: pageSize
      };

      if(data && data.data && oldPageIndex !== pageIndex){
        const direction = oldPageIndex > pageIndex ? 'prev' : 'next';
        let cursorId = '';
        queryParam.direction = direction;

        const isX2Cursor = Math.abs(oldPageIndex - pageIndex) === 2;

        if(direction === 'prev'){
          if(isX2Cursor){
            cursorId = data.cursorIdPrevX2;
          }
          else{
            cursorId = data.data[0].id;
          }
        }
        else if(direction === 'next'){
          if(isX2Cursor){
            cursorId = data.cursorIdNextX2;
          }
          else{
            cursorId = data.data[data.data.length - 1].id;
          }
        }

        queryParam.cursorId = cursorId;
      }

      if(additionalFilter){
        queryParam.subQueries = additionalFilter;
      }

      const dataResponse = await fetchDataFn(queryParam);
      if (isSubscribed) {
        setData(dataResponse);
        setLoading(false);
      }
    };

    fetchData()
      .catch(console.error);

    return () => { isSubscribed = false; };
  }, [pagination]);


  useEffect(() => {
    setPagination(defaultPagination);
  }, [additionalFilter]);

  const table = useReactTable({
    data: data?.data ?? defaultData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    rowCount: data?.rowCount,
    onPaginationChange: (updater) => {
      setPagination(old => {
        const newPaginationValue = updater instanceof Function ? updater(old) : updater;

        return {...newPaginationValue, oldPageIndex: old.pageIndex};
      });
    },
    state: {
      pagination
    }
  });

  return (
    <>
      <div className ="b-table">
        <div className="table-wrapper is-relative">
          {loading && <Loader fullSize overwrite/>}
          <table className="table has-mobile-cards is-hoverable">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th key={header.id} colSpan={header.colSpan}>
                      <div className="th-wrap">
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id} onClick={() => onRowClick(row)}>
                  {row.getVisibleCells().map((cell) => (
                    <td 
                      key={cell.id}
                      data-label={cell.column.columnDef.header}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
            <tfoot>
              {table.getFooterGroups().map((footerGroup) => (
                <tr key={footerGroup.id}>
                  {footerGroup.headers.map((header) => (
                    <th key={header.id} colSpan={header.colSpan}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.footer,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </tfoot>
          </table>
          <div className="level p-4">
            <div className="level-left">
              <nav className="pagination">
                <button
                  type="button"
                  className="pagination-link"
                  onClick={() => table.previousPage()}
                  disabled={!table.getCanPreviousPage()}
                >
                  <span className="icon" aria-hidden="true">
                    <i className="mdi mdi-chevron-left mdi-24px" />
                  </span>
                </button>

                {table.getState().pagination.pageIndex - 2 >= 0 &&
                  <button
                    type="button"
                    onClick={() => table.setPageIndex(table.getState().pagination.pageIndex - 2)}
                    className="pagination-link"
                  >
                    {table.getState().pagination.pageIndex - 1}
                  </button>
                }

                {table.getState().pagination.pageIndex - 1 >= 0 &&
                  <button
                    type="button"
                    className="pagination-link"
                    onClick={() => table.previousPage()}
                  >
                    {table.getState().pagination.pageIndex}
                  </button>
                }

                <button
                  type="button"
                  className="pagination-link"
                  disabled
                >
                  {table.getState().pagination.pageIndex + 1}
                </button>

                {table.getState().pagination.pageIndex + 2 <= table.getPageCount() &&
                  <button
                    type="button"
                    className="pagination-link"
                    onClick={() => table.nextPage()}
                  >
                    {table.getState().pagination.pageIndex + 2}
                  </button>
                }

                {table.getState().pagination.pageIndex + 3 <= table.getPageCount() &&
                  <button
                    type="button"
                    className="pagination-link"
                    onClick={() => table.setPageIndex(table.getState().pagination.pageIndex + 2)}
                  >
                    {table.getState().pagination.pageIndex + 3}
                  </button>
                }

                <span className="px-2"> ... {table.getPageCount()}</span>

                <button
                  type="button"
                  className="pagination-link"
                  onClick={() => table.nextPage()}
                  disabled={!table.getCanNextPage()}
                >
                  <span className="icon" aria-hidden="true">
                    <i className="mdi mdi-chevron-right mdi-24px" />
                  </span>
                </button>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TableNew;