/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgressbar,
  buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const ProgressBar = (props) => {
  const {
    percentage
  } = props;

  let color = '#23d160';

  if(percentage > 50){
    color = '#ffdd57';
  }

  if(percentage > 80){
    color = '#ff3860';
  }

  return (
    <CircularProgressbar
      value={percentage}
      strokeWidth={50}
      styles={buildStyles({
        strokeLinecap: "butt",
        pathColor: color,
        trailColor: "#d6d6d6"
      })}
    />
  );
};


ProgressBar.propTypes = {
  percentage: PropTypes.number,
};

ProgressBar.defaultProps = {
  percentage: 0,
};

export default ProgressBar;
