import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  fetchCollection,
  fetchDocument
} from '../api';

export const COMPLEX_MAPPINGS_FETCH_DATA_INIT = createAction('COMPLEX_MAPPINGS_FETCH_DATA_INIT');
export const COMPLEX_MAPPINGS_FETCH_DATA_SUCCESS = createAction(
  'COMPLEX_MAPPINGS_FETCH_DATA_SUCCESS'
);
export const COMPLEX_MAPPINGS_FETCH_DATA_FAIL = createAction('COMPLEX_MAPPINGS_FETCH_DATA_FAIL');
export const COMPLEX_MAPPINGS_CLEAN_UP = createAction('COMPLEX_MAPPINGS_CLEAN_UP');
export const COMPLEX_MAPPINGS_CLEAR_DATA_LOGOUT = createAction('COMPLEX_MAPPINGS_CLEAR_DATA_LOGOUT');

export const fetchComplexMappings = ({mappingId = '', companyId = ''}) => {
  return async (dispatch, getState) => {
    dispatch(COMPLEX_MAPPINGS_FETCH_DATA_INIT());

    if (mappingId) {
      let mapping;
      
      try {
        mapping = await fetchDocument('complex_mappings', mappingId);
      } catch (error) {
        toastr.error('', error);
        return dispatch(COMPLEX_MAPPINGS_FETCH_DATA_FAIL({ error }));
      }

      if (!mapping) {
        const errorMessage = 'Mapping not available';
        toastr.error('', errorMessage);
        return dispatch(COMPLEX_MAPPINGS_FETCH_DATA_FAIL({ error: errorMessage }));
      }

      const mappings = getState().complexMappings.data;
      mappings.push(mapping);

      return dispatch(
        COMPLEX_MAPPINGS_FETCH_DATA_SUCCESS({
          data: mappings
        })
      );
    }

    const observeOrders = (updatedMappings) => {
      return dispatch(
        COMPLEX_MAPPINGS_FETCH_DATA_SUCCESS({
          data: updatedMappings,
        })
      );
    };

    let mappings;

    try {
      let queryOptions = {};

      if (companyId) {
        queryOptions = {
          'queries': [{
            'attribute': 'companyId',
            'operator': '==',
            'value': companyId
          }]
        };
      }

      mappings = await fetchCollection('complex_mappings', queryOptions, observeOrders);
    } catch (error) {
      console.log(error);
      return dispatch(COMPLEX_MAPPINGS_FETCH_DATA_FAIL({ error }));
    }

    if (!mappings) {
      const errorMessage = 'Mappings not found';
      toastr.info('', errorMessage);
    }

    return dispatch(
      COMPLEX_MAPPINGS_FETCH_DATA_SUCCESS({
        data: mappings
      })
    );
  };
};

export const saveComplexMapping = (storeId, mappings) => {
  return async (dispatch, getState) => {
    const store = getState().stores.data.find(item => item.id === storeId);
    let integratorID = null;
    if (store && store.integratorID) {
      integratorID = store.integratorID;

      try{
        const firebaseApiFunctions = getFunctions();
        const saveComplexMappings = httpsCallable(firebaseApiFunctions, 'httpsSaveComplexMapping');
        
        await saveComplexMappings({integratorID, mappings});
      }
      catch (error){
        return dispatch(COMPLEX_MAPPINGS_FETCH_DATA_FAIL({ error }));
      }
    }
    else{
      const errorMessage = "Store settings aren't correct";
      toastr.error('', errorMessage);
      return dispatch(COMPLEX_MAPPINGS_FETCH_DATA_FAIL({ error: errorMessage }));
    }

    return dispatch(fetchComplexMappings({companyId: integratorID}));
  };
};

export const clearComplexMappingsDataLogout = () => {
  return (dispatch) => {
    dispatch(COMPLEX_MAPPINGS_CLEAR_DATA_LOGOUT());
  };
};

export const complexMappingsCleanUp = () => (dispatch) => dispatch(COMPLEX_MAPPINGS_CLEAN_UP());