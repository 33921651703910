export default {
  ROOT: '/',
  LOGIN: '/login',
  USERS: '/users',
  ADD_USER: '/users/new',
  MODIFY_USER: '/users/:id',
  PROFILE: '/profile',
  ORDERS: '/orders',
  ORDERS_BOARD: '/orders/board',
  MODIFY_ORDER: '/orders/:id',
  STORES: '/stores',
  ADD_STORE: '/stores/new',
  MODIFY_STORE: '/stores/:id',
  STORE_CATEGORIES: '/stores/:id/categories',
  STORE_TAGS: '/stores/:id/tags',
  STORE_COMPLEX_MAPPING: '/stores/:id/complex-mapping',
  COUPONS: '/coupons',
  ADD_COUPON: '/coupons/new',
  MODIFY_COUPON: '/coupons/:id',
  RESET_PASSWORD: '/recover-password',
  SECTION: '/section',
  SETTINGS: '/settings',
  COLORS_GROUPING: '/settings/colors-grouping',
  SIZES_GROUPING: '/settings/sizes-grouping',
  SETTINGS_KUMEN:'/kumen/settings',
  RESTAURANTS: '/restaurants',
  ADD_RESTAURANT: '/restaurants/new',
  MODIFY_RESTAURANT: '/restaurants/:id',
  CATEGORIES: '/categories',
  ADD_CATEGORY: '/categories/new',
  MODIFY_CATEGORY: '/categories/:id',
};
