/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import MoonLoader from 'react-spinners/MoonLoader';
import classNames from 'classnames';
import classes from './Loader.module.scss';

const Loader = ({fullSize = false, overwrite = false}) => {
  return (
    <div className={classNames(classes.wrapper, {[classes.fullSize]:fullSize, [classes.overwrite]:overwrite})}>
      <div className={classNames(classes.loader)}>
        <MoonLoader color="#133b7b" size={80} />
      </div>
    </div>
  );
};

export default Loader;