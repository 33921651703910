import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { reducer as toastrReducer } from 'react-redux-toastr';

import { authReducer } from './auth';
import { usersReducer } from './users';
import { ordersReducer } from './orders';
import { storesReducer } from './stores';
import { couponsReducer } from './coupons';
import { settingsReducer } from './settings';
import { settingsKumenReducer } from './settingsKumen';
import { restaurantsReducer } from './restaurants';
import { preferencesReducer } from './preferences';
import { categoriesReducer } from './categories';
import { integratorCategoriesReducer } from './integratorCategories';
import { integratorTagsReducer } from './integratorTags';
import { mappingsReducer } from './mappings';
import { complexMappingsReducer } from './complexMappings';
import { colorsGroupsReducer } from './colorsGroups';
import { integratorColorsReducer } from './integratorColors';
import { integratorSizesReducer } from './integratorSizes';

export default combineReducers({
  auth: persistReducer(
    {
      key: 'auth',
      storage,
      blacklist: ['error', 'loading'],
    },
    authReducer
  ),
  preferences: persistReducer(
    { key: 'preferences', storage },
    preferencesReducer
  ),
  users: persistReducer(
    {
      key: 'users',
      storage,
      blacklist: ['error', 'loading'],
    },
    usersReducer
  ),
  orders: persistReducer(
    {
      key: 'orders',
      storage,
      blacklist: ['error', 'loading'],
    },
    ordersReducer
  ),
  stores: persistReducer(
    {
      key: 'stores',
      storage,
      blacklist: ['error', 'loading'],
    },
    storesReducer
  ),
  coupons: persistReducer(
    {
      key: 'coupons',
      storage,
      blacklist: ['error', 'loading'],
    },
    couponsReducer
  ),
  settings: persistReducer(
    {
      key: 'settings',
      storage,
      blacklist: ['error', 'loading'],
    },
    settingsReducer
  ),
  settingsKumen: persistReducer(
    {
      key: 'settingsKumen',
      storage,
      blacklist: ['error', 'loading'],
    },
    settingsKumenReducer
  ),
  restaurants: persistReducer(
    {
      key: 'restaurants',
      storage,
      blacklist: ['error', 'loading'],
    },
    restaurantsReducer
  ),
  categories: persistReducer(
    {
      key: 'categories',
      storage,
      blacklist: ['error', 'loading'],
    },
    categoriesReducer
  ),
  integratorCategories: persistReducer(
    {
      key: 'integratorCategories',
      storage,
      blacklist: ['error', 'loading'],
    },
    integratorCategoriesReducer
  ),
  integratorTags: persistReducer(
    {
      key: 'integratorTags',
      storage,
      blacklist: ['error', 'loading'],
    },
    integratorTagsReducer
  ),
  mappings: persistReducer(
    {
      key: 'mappings',
      storage,
      blacklist: ['error', 'loading'],
    },
    mappingsReducer
  ),
  complexMappings: persistReducer(
    {
      key: 'complexMappings',
      storage,
      blacklist: ['error', 'loading'],
    },
    complexMappingsReducer
  ),
  colorsGroups: persistReducer(
    {
      key: 'colorsGroups',
      storage,
      blacklist: ['error', 'loading'],
    },
    colorsGroupsReducer
  ),
  integratorColors: persistReducer(
    {
      key: 'integratorColors',
      storage,
      blacklist: ['error', 'loading'],
    },
    integratorColorsReducer
  ),
  integratorSizes: persistReducer(
    {
      key: 'integratorSizes',
      storage,
      blacklist: ['error', 'loading'],
    },
    integratorSizesReducer
  ),
  toastr: toastrReducer,
});
