import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';
import firebase from 'firebase.js';
import {
  fetchCollection,
  fetchDocument
} from '../api';

import { fetchMappings, MAPPINGS_FETCH_DATA_FAIL } from './mappings';

export const INTEGRATOR_TAGS_FETCH_DATA_INIT = createAction('INTEGRATOR_TAGS_FETCH_DATA_INIT');
export const INTEGRATOR_TAGS_FETCH_DATA_SUCCESS = createAction(
  'INTEGRATOR_TAGS_FETCH_DATA_SUCCESS'
);
export const INTEGRATOR_TAGS_FETCH_DATA_FAIL = createAction('INTEGRATOR_TAGS_FETCH_DATA_FAIL');
export const INTEGRATOR_TAGS_CLEAN_UP = createAction('INTEGRATOR_TAGS_CLEAN_UP');
export const INTEGRATOR_TAGS_CLEAR_DATA_LOGOUT = createAction('INTEGRATOR_TAGS_CLEAR_DATA_LOGOUT');

export const fetchIntegratorTags = ({tagId = '', companyId = ''}) => {
  return async (dispatch, getState) => {
    dispatch(INTEGRATOR_TAGS_FETCH_DATA_INIT());

    if (tagId) {
      let tag;
      
      try {
        tag = await fetchDocument('integrator_tags', tagId);
      } catch (error) {
        toastr.error('', error);
        return dispatch(INTEGRATOR_TAGS_FETCH_DATA_FAIL({ error }));
      }

      if (!tag) {
        const errorMessage = 'Integrator tag not available';
        toastr.error('', errorMessage);
        return dispatch(INTEGRATOR_TAGS_FETCH_DATA_FAIL({ error: errorMessage }));
      }

      const tags = getState().integratorTags.data;
      tags.push(tag);

      return dispatch(
        INTEGRATOR_TAGS_FETCH_DATA_SUCCESS({
          data: tags
        })
      );
    }

    let tags;

    try {
      let queryOptions = {};

      if (companyId) {
        queryOptions = {
          'queries': [{
            'attribute': 'companyId',
            'operator': '==',
            'value': companyId
          }]
        };
      }

      tags = await fetchCollection('integrator_tags', queryOptions);

    } catch (error) {
      return dispatch(INTEGRATOR_TAGS_FETCH_DATA_FAIL({ error }));
    }

    if (!tags) {
      const errorMessage = 'Integrator tags not found';
      toastr.info('', errorMessage);
    }

    return dispatch(
      INTEGRATOR_TAGS_FETCH_DATA_SUCCESS({
        data: tags
      })
    );
  };
};

export const importIntegratorTags = (storeId) => {
  return async (dispatch, getState) => {
    const store = getState().stores.data.find(item => item.id === storeId);
    let tags = getState().integratorTags.data;

    if (store && store.integratorID && store.tagsAPIKey) {

      const {integratorID, tagsAPIKey} = store;

      try{
        const importTags = firebase
        .functions()
        .httpsCallable('httpsImportIntegratorTags');

        const importTagsResponse = await importTags({integratorID, tagsAPIKey});

        if (importTagsResponse.data && importTagsResponse.data.status === 'success') {
          tags = await fetchCollection('integrator_tags');

          const successMessage = `Imported ${importTagsResponse.data.count} tags`;
          toastr.info('', successMessage);
        }
      }
      catch (error){
        return dispatch(INTEGRATOR_TAGS_FETCH_DATA_FAIL({ error }));
      }
    }
    else{
      const errorMessage = "IntegratorID and Tags API Key is required";
      toastr.error('', errorMessage);
      return dispatch(INTEGRATOR_TAGS_FETCH_DATA_FAIL({ error: errorMessage }));
    }

    return dispatch(
      INTEGRATOR_TAGS_FETCH_DATA_SUCCESS({
        data: tags
      })
    );
  };
};

export const saveMappingTags = (storeId, mappings) => {
  return async (dispatch, getState) => {
    const store = getState().stores.data.find(item => item.id === storeId);
    let integratorID = null;
    if (store && store.integratorID) {
      integratorID = store.integratorID;

      try{
        const saveMappings = firebase
        .functions()
        .httpsCallable('httpsSaveMapping');

        await saveMappings({integratorID, type: 'tag', mappings});
      }
      catch (error){
        return dispatch(MAPPINGS_FETCH_DATA_FAIL({ error }));
      }
    }
    else{
      const errorMessage = "Store settings aren't correct";
      toastr.error('', errorMessage);
      return dispatch(MAPPINGS_FETCH_DATA_FAIL({ error: errorMessage }));
    }

    return dispatch(fetchMappings({companyId: integratorID}));
  };
};

export const clearTagsDataLogout = () => {
  return (dispatch) => {
    dispatch(INTEGRATOR_TAGS_CLEAR_DATA_LOGOUT());
  };
};

export const tagsCleanUp = () => (dispatch) => dispatch(INTEGRATOR_TAGS_CLEAN_UP());