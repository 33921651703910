import { createIntl, createIntlCache } from 'react-intl';
import firebase from 'firebase.js';

import english from 'languages/en';
import icelandic from 'languages/is';
import en from 'assets/en.png';
import is from 'assets/is.png';

export const FIREBASE_RESPONSE = {
  EMAIL_IN_USE: 'auth/email-already-exists',
  EMAIL_INVALID: 'auth/invalid-email',
  EMAIL_NOT_FOUND: 'auth/user-not-found',
  PASSWORD_INVALID: 'auth/wrong-password',
  USER_DISABLED: 'auth/user-disabled',
  TOO_MANY_REQUESTS: 'auth/too-many-requests',
  EXPIRED_ACTION_CODE: 'auth/expired-action-code',
  INVALID_ACTION_CODE: 'auth/invalid-action-code',
  QUOTA_EXCEEDED_STORAGE: 'storage/quota-exceeded',
  UNAUTHENTICATED_STORAGE: 'storage/unauthenticated',
  UNAUTHORIZED_STORAGE: 'storage/unauthorized',
};

export const ORDER_STATUS = {
  todo:'Todo',
  in_picking: 'In picking',
  dispatched: 'Dispatched',
  received: 'Received by service desk',
  ready: 'Ready',
  delivered: 'Delivered',
  cancelled: 'Cancelled'
};

export const ORDER_ITEM_STATUS = {
  todo:'Todo',
  picked: 'Picked',
  missing: 'Missing',
  received: 'Received',
  canceled: 'Canceled'
};

export const messages = {
  en: english,
  is: icelandic
};

const getIntlContext = (locale) => {
  const cache = createIntlCache();
  return createIntl(
    {
      locale,
      messages: messages[locale],
    },
    cache
  );
};

export const firebaseError = (error, locale) => {
  const intl = getIntlContext(locale);
  return intl.formatMessage({
    id: error,
    defaultMessage: messages[locale]['utils.default'],
  });
};

export const availableLocales = Object.keys(messages);

export const browserLocale = navigator.language.split(/[-_]/)[0];

export const flags = {
  en,
  is
};

export const uiConfig = (onSignInSuccessHandler, onSignInFailHandler) => {
  return {
    callbacks: {
      signInSuccessWithAuthResult: onSignInSuccessHandler,
      signInFailure: onSignInFailHandler,
    },
    signInFlow: 'popup',
    signInSuccessUrl: '/home',
    signInOptions: [
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        fullLabel: 'Continue with Google',
        scopes: [
          'https://www.googleapis.com/auth/user.addresses.read',
          'https://www.googleapis.com/auth/userinfo.email',
        ],
      },
      {
        provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        fullLabel: 'Continue with Facebook',
        scopes: ['email'],
      },
      { provider: 'microsoft.com', fullLabel: 'Continue with Microsoft' },
    ],
  };
};

export const convertDateZeroHour = (value) => {
  const date = new Date(value);

  return date.setHours(0, 0, 0, 0);
};

export const sortCategories = (a, b) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

export const nestCategories = (categories, target = '', prefixDepth = 0) => {
  let result = [];
  const depth = prefixDepth + 1;
  categories.sort(sortCategories).filter(({ parentCategory }) => parentCategory === target).forEach(category => {
    result.push({...category, prefixDepth});
    const subCategories = nestCategories(categories, category.id, depth);
    
    if(subCategories && subCategories.length){
      result = result.concat(subCategories);
    };
  });

  return result;
};

export const nestIntegratorCategories = (categories, target = '0') => {
  let result = [];

  categories.sort(sortCategories).filter(({ parentId }) => parentId === target).forEach(category => {
    result.push({...category });
    const subCategories = nestIntegratorCategories(categories, category.categoryId);
    
    if(subCategories && subCategories.length){
      result = result.concat(subCategories);
    };
  });

  return result;
};

export const nestIntegratorTags = (tags, target = '0') => {
  let result = [];

  tags.sort(sortCategories).filter(({ parentId }) => parentId === target).forEach(tag => {
    result.push({...tag });
    const subTags = nestIntegratorTags(tags, tag.tagId);
    
    if(subTags && subTags.length){
      result = result.concat(subTags);
    };
  });

  return result;
};

export const getFirebaseCategoryTree = (categories, parentId) => {
  let result = [];
  if(parentId){
    const parent = categories.find(item => item.id === parentId);
    if (parent) {
      result.push(parent);
      const subTree = getFirebaseCategoryTree(categories, parent.parentCategory);

      if(subTree && subTree.length){
        result = result.concat(subTree);
      };
    }
  }

  return result;
};

export const getIntegratorCategoryTree = (categories, parentId) => {
  let result = [];
  if(parentId){
    const parent = categories.find(item => item.categoryId === parentId);
    if (parent) {
      result.push(parent);
      const subTree = getIntegratorCategoryTree(categories, parent.parentId);

      if(subTree && subTree.length){
        result = result.concat(subTree);
      };
    }
  }

  return result;
};

export const DEV_USERS = [
  'em@tactica.is',
  'haddi@tactica.is',
  'kristofer@tactica.is'
];

export const getApiUrlByHost = () => {
  let apiUrl = 'https://dev.kringlan.is';
  const { hostname } = window.location;

  if (hostname === 'stjornbord.kringlan.is') {
    apiUrl = 'https://www.kringlan.is';
  }

  return apiUrl;
};