import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { getIntegratorCategoryTree, getFirebaseCategoryTree } from 'utils';
import styles from './PopupSelect.module.scss';

const PopupSelect = (props) => {
  const { integratorCategories, firebaseCategories } = useSelector(
    (state) => ({
      integratorCategories: state.integratorCategories.data,
      firebaseCategories: state.categories.data,
    }),
    shallowEqual
  );

  const { children, innerProps, innerRef, getStyles, getValue, selectProps:{ classNames } } = props;
  const defaultStyles = getStyles('control', props);
  const currentValues = getValue();
  let currentValue = null;

  if(currentValues && currentValues.length > 0){
    const categoryId = currentValues[0].value;
    if(classNames === 'integrator-categories'){
      const integratorCategoryTree = getIntegratorCategoryTree(integratorCategories, categoryId);
      currentValue = integratorCategoryTree.map(item => (item.name)).reverse().join('->');
    }
    if(classNames === 'firebase-categories'){
      const firebaseCategoryTree = getFirebaseCategoryTree(firebaseCategories, categoryId);
      currentValue = firebaseCategoryTree.map(item => (item.name)).reverse().join('->');
    }
  }

  return(
    <div style={defaultStyles} className={styles.control} ref={innerRef} {...innerProps}>
      {currentValue &&
        <div className={styles.popup}>{currentValue}</div>
      }

      {children}
    </div>
  );

};

export default PopupSelect;