import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';
import {
  fetchCollection,
  fetchDocument
} from '../api';

export const COLORS_GROUPS_FETCH_DATA_INIT = createAction('COLORS_GROUPS_FETCH_DATA_INIT');
export const COLORS_GROUPS_FETCH_DATA_SUCCESS = createAction(
  'COLORS_GROUPS_FETCH_DATA_SUCCESS'
);
export const COLORS_GROUPS_FETCH_DATA_FAIL = createAction('COLORS_GROUPS_FETCH_DATA_FAIL');
export const COLORS_GROUPS_CLEAN_UP = createAction('COLORS_GROUPS_CLEAN_UP');
export const COLORS_GROUPS_CLEAR_DATA_LOGOUT = createAction('COLORS_GROUPS_CLEAR_DATA_LOGOUT');

export const fetchColorsGroup = (groupId = '') => {
  return async (dispatch, getState) => {
    dispatch(COLORS_GROUPS_FETCH_DATA_INIT());

    if (groupId) {
      let group;
      
      try {
        group = await fetchDocument('colors_groups', groupId);
      } catch (error) {
        toastr.error('', error);
        return dispatch(COLORS_GROUPS_FETCH_DATA_FAIL({ error }));
      }

      if (!group) {
        const errorMessage = 'Group not available';
        toastr.error('', errorMessage);
        return dispatch(COLORS_GROUPS_FETCH_DATA_FAIL({ error: errorMessage }));
      }

      const groups = getState().colorsGroups.data;
      groups.push(group);

      return dispatch(
        COLORS_GROUPS_FETCH_DATA_SUCCESS({
          data: groups
        })
      );
    }

    const observeOrders = (updatedGroups) => {
      return dispatch(
        COLORS_GROUPS_FETCH_DATA_SUCCESS({
          data: updatedGroups,
        })
      );
    };

    let groups;

    try {
      const queryOptions = {};

      groups = await fetchCollection('colors_groups', queryOptions, observeOrders);

    } catch (error) {
      return dispatch(COLORS_GROUPS_FETCH_DATA_FAIL({ error }));
    }

    if (!groups) {
      const errorMessage = 'Groups not found';
      toastr.info('', errorMessage);
    }

    return dispatch(
      COLORS_GROUPS_FETCH_DATA_SUCCESS({
        data: groups
      })
    );
  };
};

export const clearСolorsGroupsDataLogout = () => {
  return (dispatch) => {
    dispatch(COLORS_GROUPS_CLEAR_DATA_LOGOUT());
  };
};

export const colorsGroupsCleanUp = () => (dispatch) => dispatch(COLORS_GROUPS_CLEAN_UP());