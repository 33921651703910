/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { useForm, useFieldArray } from 'react-hook-form';
import classNames from 'classnames';
import { yupResolver } from '@hookform/resolvers';
import { checkAccess } from 'permissions';
import { restaurantsCleanUp } from 'state/actions/restaurants';
import { useFormatMessage } from 'hooks';
import moment from 'moment';
import ErrorMessage from 'components/ErrorMessage';

import './RestaurantForm.scss';

const RestaurantForm = ({ restaurant, onSubmitHandler, schema }) => {
  const { loading, success } = useSelector(
    (state) => ({
      loading: state.restaurants.loading,
      success: state.restaurants.success,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const { register, handleSubmit, errors, watch, setValue, control } = useForm({
    defaultValues: {
      ...restaurant,
      deleteLogo: false,
      deleteBg: false
    },
    resolver: yupResolver(schema),
  });

  const {
    fields,
    append,
    remove
  } = useFieldArray({
    control,
    name: "menu"
  });

  const deleteMenuItem = (index) => {
    if(fields.length === 1){
      setValue('menu[0].name', '');
      setValue('menu[0].linkName', '');
      setValue('menu[0].linkUrl', '');
    }
    else{
      remove(index);
    } 
  };

  useEffect(() => {
    if (success) {
      setValue('logoFile', null);
      setValue('bgFile', null);
    }
    return () => dispatch(restaurantsCleanUp());
  }, [dispatch, success, setValue]);

  const [logoFileName, setLogoFileName] = useState(restaurant.logoUrl);
  const [bgFileName, setBgFileName] = useState(restaurant.bgUrl);

  const currentLogoFile = watch('logoFile');
  const currentBgFile = watch('bgFile');

  useEffect(() => {
    if(currentLogoFile && currentLogoFile[0]){
      setLogoFileName(currentLogoFile[0].name);
      setValue('deleteLogo', 'false');
    }  
  }, [currentLogoFile]);

  useEffect(() => {
    if(currentBgFile && currentBgFile[0]){
      setBgFileName(currentBgFile[0].name);
      setValue('deleteBg', 'false');
    }  
  }, [currentBgFile]);

  let logoPreviewUrl =
    currentLogoFile && currentLogoFile[0]
      ? URL.createObjectURL(currentLogoFile[0])
      : restaurant.logoUrl;

  if(logoPreviewUrl){
    const logoPreviewUrlObj = new URL(logoPreviewUrl);
    if(logoPreviewUrlObj.protocol !== 'blob:'){
      const urlSearchParams = logoPreviewUrlObj.searchParams;
      urlSearchParams.set('version', Date.now());
      logoPreviewUrlObj.search = urlSearchParams.toString();
      logoPreviewUrl = logoPreviewUrlObj.toString();
    }
  }

  let bgPreviewUrl =
    currentBgFile && currentBgFile[0]
      ? URL.createObjectURL(currentBgFile[0])
      : restaurant.bgUrl;

  if(bgPreviewUrl){
    const bgPreviewUrlObj = new URL(bgPreviewUrl);
    if(bgPreviewUrlObj.protocol !== 'blob:'){
      const urlSearchParams = bgPreviewUrlObj.searchParams;
      urlSearchParams.set('version', Date.now());
      bgPreviewUrlObj.search = urlSearchParams.toString();
      bgPreviewUrl = bgPreviewUrlObj.toString();
    }
  }

  const pickAnotherFileMessage = useFormatMessage('RestaurantForm.pickAnotherFile');
  const pickFileMessage = useFormatMessage('RestaurantForm.pickFile');

  const infoLabel = useFormatMessage('RestaurantForm.restaurantInfo');
  const previewLabel = useFormatMessage('RestaurantForm.restaurantPreview');
  const labelOrder = useFormatMessage('RestaurantForm.order');
  const labelMenu = useFormatMessage('RestaurantForm.menu');
  const labelMenuName = useFormatMessage('RestaurantForm.menuName');
  const labelMenuLinkName = useFormatMessage('RestaurantForm.menuLinkName');
  const labelMenuLinkUrl = useFormatMessage('RestaurantForm.menuLinkUrl');
  const labelAddMenu = useFormatMessage('RestaurantForm.addMenu');
  const labelLogo = useFormatMessage('RestaurantForm.logo');
  const labelBg = useFormatMessage('RestaurantForm.bg');

  const permissionToEditRestaurant = checkAccess('edit restaurant');

  const removeLogoFile = () => {
    setValue('logoFile', null);
    setValue('deleteLogo', 'true');
    setLogoFileName('');
  };

  const removeBgFile = () => {
    setValue('bgFile', null);
    setValue('deleteBg', 'true');
    setBgFileName('');
  };

  return (
    <>
      <div className="tile is-ancestor">
        <div className="tile is-parent">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-information default" />
                </span>
                { infoLabel }
              </p>
            </header>
            <div className="card-content">
              <form onSubmit={handleSubmit(onSubmitHandler)}>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {useFormatMessage('RestaurantForm.name')}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          name="name"
                          id="name"
                          className={classNames('input', {
                            'is-danger': errors.name,
                          })}
                          ref={register}
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {errors.name && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}
                
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {labelOrder}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="control">
                        <input
                          name="order"
                          id="order"
                          className={classNames('input', {
                            'is-danger': errors.order,
                          })}
                          ref={register}
                          type="number"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {errors.order && (
                  <div className="field is-horizontal">
                    <div className="field-label is-normal" />
                    <div className="field-body">
                      <ErrorMessage />
                    </div>
                  </div>
                )}

                <hr />
                
                <h3 className="title is-6">{labelMenu}</h3>
                {fields.map((item, index) => {
                  return (
                    <div key={item.id} className="is-flex is-justify-content-space-between is-align-items-center mb-6">
                      <div className="is-flex-grow-1">
                        <div className="field is-horizontal">
                          <div className="field-label is-normal">
                            <label className="label">
                              {labelMenuName}
                            </label>
                          </div>
                          <div className="field-body">
                            <div className="field">
                              <div className="control">
                                <input
                                  name={`menu[${index}].name`}
                                  ref={register()}
                                  defaultValue={item.name}
                                  className={classNames('input', {
                                    'is-danger': errors?.menu?.[index]?.name,
                                  })}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="field is-horizontal">
                          <div className="field-label is-normal">
                            <label className="label">
                              {labelMenuLinkName}
                            </label>
                          </div>
                          <div className="field-body">
                            <div className="field">
                              <div className="control">
                                <input
                                  name={`menu[${index}].linkName`}
                                  ref={register()}
                                  defaultValue={item.linkName}
                                  className={classNames('input', {
                                    'is-danger': errors?.menu?.[index]?.linkName,
                                  })}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="field is-horizontal">
                          <div className="field-label is-normal">
                            <label className="label">
                              {labelMenuLinkUrl}
                            </label>
                          </div>
                          <div className="field-body">
                            <div className="field">
                              <div className="control">
                                <input
                                  name={`menu[${index}].linkUrl`}
                                  ref={register()}
                                  defaultValue={item.linkUrl}
                                  className={classNames('input', {
                                    'is-danger': errors?.menu?.[index]?.linkUrl,
                                  })}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button type="button" className="button is-danger ml-3" onClick={() => deleteMenuItem(index)}>
                        <span className="icon">
                          <i className="mdi mdi-trash-can default" />
                        </span>
                      </button>
                    </div>
                  );
                })}

                {fields && fields.length < 3 &&
                  <div className="field is-horizontal">
                    <div className="field-label" />
                    <div className="field-body">
                      <div className="field">
                        <div className="field is-grouped">
                          <div className="control">
                            <button type="button" className="button is-primary" onClick={() => append({name:"", linkName:"", linkUrl: ""})}>
                              {labelAddMenu}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }

                <hr />

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {labelLogo}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="file has-name">
                        <label className="file-label">
                          <input
                            className="file-input"
                            type="file"
                            name="logoFile"
                            ref={register}
                            accept="image/*"
                          />
                          <span className="file-cta">
                            <span className="file-icon">
                              <i className="mdi mdi-upload" />
                            </span>
                            <span className="file-label">
                              {logoFileName
                                ? pickAnotherFileMessage
                                : pickFileMessage}
                            </span>
                          </span>
                          <span className="file-name" title={logoFileName}>
                            {logoFileName && logoFileName}
                          </span>
                          <button type="button" className="button is-danger ml-3" onClick={removeLogoFile}>
                            <span className="icon">
                              <i className="mdi mdi-trash-can default" />
                            </span>
                          </button>

                          <input
                            type="hidden"
                            name='deleteLogo'
                            ref={register()}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <hr />

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">
                      {labelBg}
                    </label>
                  </div>
                  <div className="field-body">
                    <div className="field">
                      <div className="file has-name">
                        <label className="file-label">
                          <input
                            className="file-input"
                            type="file"
                            name="bgFile"
                            ref={register}
                            accept="image/*"
                          />
                          <span className="file-cta">
                            <span className="file-icon">
                              <i className="mdi mdi-upload" />
                            </span>
                            <span className="file-label">
                              {bgFileName
                                ? pickAnotherFileMessage
                                : pickFileMessage}
                            </span>
                          </span>
                          <span className="file-name" title={bgFileName}>
                            {bgFileName && bgFileName}
                          </span>
                          <button type="button" className="button is-danger ml-3" onClick={removeBgFile}>
                            <span className="icon">
                              <i className="mdi mdi-trash-can default" />
                            </span>
                          </button>

                          <input
                            type="hidden"
                            name='deleteBg'
                            ref={register()}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <hr />
                 
                <div className="field is-horizontal">
                  <div className="field-label" />
                  <div className="field-body">
                    <div className="field">
                      <div className="field is-grouped">
                        { permissionToEditRestaurant &&
                          <div className="control">
                            <button
                              type="submit"
                              className={`button blue-button ${
                                loading && 'is-loading'
                              }`}
                            >
                              <span>{useFormatMessage('RestaurantForm.submit')}</span>
                            </button>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <input 
                  type="hidden"
                  name="createdAt"
                  ref={register} />
              </form>
            </div>
          </div>
        </div>
        <div className="tile is-parent preview">
          <div className="card tile is-child">
            <header className="card-header">
              <p className="card-header-title">
                <span className="icon">
                  <i className="mdi mdi-information-outline default" />
                </span>
                { previewLabel }
              </p>
            </header>
            <div className="card-content">
              <div className="field">
                <label className="label">
                  {useFormatMessage('RestaurantForm.name')}
                </label>
                <div className="control is-clearfix">
                  <input
                    data-testid="name"
                    type="text"
                    readOnly="readOnly"
                    className="input is-static"
                    value={watch('name')}
                  />
                </div>
              </div>

              <hr />

              {logoPreviewUrl && logoFileName && (
                <>
                  <h3 className="title is-6">{labelLogo}</h3>
                  <div className="is-restaurant-logo image has-max-width is-aligned-center">
                    <img
                      className="restaurant-logo"
                      src={logoPreviewUrl}
                      alt="Restaurant logo preview"
                    />
                  </div>
                  <hr />
                </>
              )}

              {bgPreviewUrl && bgFileName && (
                <>
                  <h3 className="title is-6">{labelBg}</h3>
                  <div className="is-restaurant-logo image has-max-width is-aligned-center">
                    <img
                      className="restaurant-logo"
                      src={bgPreviewUrl}
                      alt="Restaurant bg preview"
                    />
                  </div>
                  <hr />
                </>
              )}

              <div className="field">
                <label className="label">
                  {useFormatMessage('RestaurantForm.created')}
                </label>
                <div className="control is-clearfix" data-testid="date">
                  <p className="date">
                    {moment(new Date(watch('createdAt'))).format("DD.MM.YYYY")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

RestaurantForm.propTypes = {
  restaurant: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    logoUrl: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
  }).isRequired,
  onSubmitHandler: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  schema: PropTypes.object.isRequired,
};

export default RestaurantForm;