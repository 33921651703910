import { createReducer } from 'redux-act';

import { PREFERENCES_SET_LOCALE, PREFERENCES_CLEAR_DATA } from 'state/actions/preferences';

const initialState = {
  locale: null
};

export const preferencesReducer = createReducer(
  {
    [PREFERENCES_SET_LOCALE]: (state, payload) => ({
      ...state,
      locale: payload.locale
    }),
    [PREFERENCES_CLEAR_DATA]: () => ({
      ...initialState,
    }),
  },
  initialState
);
