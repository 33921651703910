import { createAction } from 'redux-act';
import { toastr } from 'react-redux-toastr';
import { firebaseError } from 'utils';
import {
  fetchCollection,
  fetchDocument,
  addDocument,
  updateDocument
} from '../api';

export const MAPPINGS_FETCH_DATA_INIT = createAction('MAPPINGS_FETCH_DATA_INIT');
export const MAPPINGS_FETCH_DATA_SUCCESS = createAction(
  'MAPPINGS_FETCH_DATA_SUCCESS'
);
export const MAPPINGS_FETCH_DATA_FAIL = createAction('MAPPINGS_FETCH_DATA_FAIL');

export const MAPPINGS_CREATE_MAPPING_INIT = createAction('MAPPINGS_CREATE_MAPPING_INIT');
export const MAPPINGS_CREATE_MAPPING_SUCCESS = createAction(
  'MAPPINGS_CREATE_MAPPING_SUCCESS'
);
export const MAPPINGS_CREATE_MAPPING_FAIL = createAction('MAPPINGS_CREATE_MAPPING_FAIL');

export const MAPPINGS_MODIFY_MAPPING_INIT = createAction('MAPPINGS_MODIFY_MAPPING_INIT');
export const MAPPINGS_MODIFY_MAPPING_SUCCESS = createAction(
  'MAPPINGS_MODIFY_MAPPING_SUCCESS'
);
export const MAPPINGS_MODIFY_MAPPING_FAIL = createAction('MAPPINGS_MODIFY_MAPPING_FAIL');

export const MAPPINGS_CLEAN_UP = createAction('MAPPINGS_CLEAN_UP');
export const MAPPINGS_CLEAR_DATA_LOGOUT = createAction('MAPPINGS_CLEAR_DATA_LOGOUT');

export const fetchMappings = ({mappingId = '', companyId = ''}) => {
  return async (dispatch, getState) => {
    dispatch(MAPPINGS_FETCH_DATA_INIT());

    if (mappingId) {
      let mapping;
      
      try {
        mapping = await fetchDocument('mappings', mappingId);
      } catch (error) {
        toastr.error('', error);
        return dispatch(MAPPINGS_FETCH_DATA_FAIL({ error }));
      }

      if (!mapping) {
        const errorMessage = 'Mapping not available';
        toastr.error('', errorMessage);
        return dispatch(MAPPINGS_FETCH_DATA_FAIL({ error: errorMessage }));
      }

      const mappings = getState().mappings.data;
      mappings.push(mapping);

      return dispatch(
        MAPPINGS_FETCH_DATA_SUCCESS({
          data: mappings
        })
      );
    }

    const observeOrders = (updatedMappings) => {
      return dispatch(
        MAPPINGS_FETCH_DATA_SUCCESS({
          data: updatedMappings,
        })
      );
    };

    let mappings;

    try {
      let queryOptions = {};
      
      if (companyId) {
        queryOptions = {
          'queries': [{
            'attribute': 'companyId',
            'operator': '==',
            'value': companyId
          }]
        };
      }

      mappings = await fetchCollection('mappings', queryOptions, observeOrders);

    } catch (error) {
      return dispatch(MAPPINGS_FETCH_DATA_FAIL({ error }));
    }

    if (!mappings) {
      const errorMessage = 'Mappings not found';
      toastr.info('', errorMessage);
    }

    return dispatch(
      MAPPINGS_FETCH_DATA_SUCCESS({
        data: mappings
      })
    );
  };
};

export const getDefaultMappingCategory = async(companyId = '') => {
  let mapping;

  if (companyId) {
    try {
      const queryOptions = {
        queries: [
          {
            'attribute': 'companyId',
            'operator': '==',
            'value': companyId
          },
          {
            'attribute': 'source',
            'operator': '==',
            'value': ''
          }
        ]
      };

      const mappingCollection = await fetchCollection('mappings', queryOptions);
      if (mappingCollection && mappingCollection[0]) {
        const [ mappingData ] = mappingCollection;
        mapping = {...mappingData};
      }
    } catch (error) {
      console.log('error', error);
      toastr.error('', error);
    }
  }

  return mapping;
};

export const addDefaultMappingCategory = (destination, companyId, mappingId = '') => {
  return async (dispatch, getState) => {
    const mappingData = {
      destination,
      companyId,
      type: 'category',
      source: ''
    };

    let result;

    if (mappingId) {
      dispatch(MAPPINGS_MODIFY_MAPPING_INIT());
    }
    else{
      dispatch(MAPPINGS_CREATE_MAPPING_INIT());
    }
  
    const { locale } = getState().preferences;
    
    try {
      if (mappingId) {
        await updateDocument('mappings', mappingId, mappingData);

        result = dispatch(MAPPINGS_MODIFY_MAPPING_SUCCESS({ mapping: mappingData, mappingId }));
      }
      else{
        const response = await addDocument('mappings', mappingData);

        result = dispatch(MAPPINGS_CREATE_MAPPING_SUCCESS({ mapping: {
            id: response.id,
            ...mappingData
          }
        }));
      }
    } catch (error) {
      const errorMessage = firebaseError(error.message, locale);
      toastr.error('', errorMessage);
      return dispatch(
        MAPPINGS_CREATE_MAPPING_FAIL({
          error: errorMessage,
        })
      );
    }

    return result;
  };
};

export const clearMappingsDataLogout = () => {
  return (dispatch) => {
    dispatch(MAPPINGS_CLEAR_DATA_LOGOUT());
  };
};

export const mappingsCleanUp = () => (dispatch) => dispatch(MAPPINGS_CLEAN_UP());