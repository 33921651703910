import { createReducer } from 'redux-act';

import {
  CATEGORIES_FETCH_DATA_INIT,
  CATEGORIES_FETCH_DATA_SUCCESS,
  CATEGORIES_FETCH_DATA_FAIL,
  CATEGORIES_DELETE_CATEGORY_INIT,
  CATEGORIES_DELETE_CATEGORY_SUCCESS,
  CATEGORIES_DELETE_CATEGORY_FAIL,
  CATEGORIES_CREATE_CATEGORY_INIT,
  CATEGORIES_CREATE_CATEGORY_SUCCESS,
  CATEGORIES_CREATE_CATEGORY_FAIL,
  CATEGORIES_MODIFY_CATEGORY_INIT,
  CATEGORIES_MODIFY_CATEGORY_SUCCESS,
  CATEGORIES_MODIFY_CATEGORY_FAIL,
  CATEGORIES_CLEAN_UP,
  CATEGORIES_CLEAR_DATA_LOGOUT,
} from 'state/actions/categories';

const initialState = {
  data: [],
  loading: false,
  error: null,
  success: false,
  deleted: false,
};

export const categoriesReducer = createReducer(
  {
    [CATEGORIES_FETCH_DATA_INIT]: () => ({
      ...initialState,
      loading: true,
    }),
    [CATEGORIES_FETCH_DATA_SUCCESS]: (state, { data }) => ({
      ...state,
      data,
      loading: false,
      error: null,
    }),
    [CATEGORIES_FETCH_DATA_FAIL]: (state, { error }) => ({
      ...state,
      loading: false,
      error,
    }),
    [CATEGORIES_DELETE_CATEGORY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [CATEGORIES_DELETE_CATEGORY_SUCCESS]: (state, { id }) => {
      const { data } = state;

      return ({
        ...state,
        data: data.filter((elem) => elem.id !== id),
        loading: false,
        error: null,
        deleted: true,
      });
    },
    [CATEGORIES_DELETE_CATEGORY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [CATEGORIES_CREATE_CATEGORY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [CATEGORIES_CREATE_CATEGORY_SUCCESS]: (state, { category }) => {
      const { data } = state;

      return ({
        ...state,
        data: data.concat(category),
        loading: false,
        error: null,
        success: true,
      });
    },
    [CATEGORIES_CREATE_CATEGORY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [CATEGORIES_MODIFY_CATEGORY_INIT]: (state) => ({
      ...state,
      loading: true,
    }),
    [CATEGORIES_MODIFY_CATEGORY_SUCCESS]: (state, { id, category }) => {
      const { data } = state;

      return ({
        ...state,
        data: !data
          ? []
          : data.map((elem) => {
              if (elem.id === id) {
                return {
                  name: category.name,
                  parentCategory: category.parentCategory,
                  integratorCategory: category.integratorCategory,
                  showOnMenu: category.showOnMenu,
                  id,
                  createdAt: category.createdAt,
                };
              }
              return elem;
            }),
        loading: false,
        error: null,
        success: true,
      });
    },
    [CATEGORIES_MODIFY_CATEGORY_FAIL]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload.error,
    }),
    [CATEGORIES_CLEAN_UP]: (state) => ({
      ...state,
      loading: false,
      error: null,
      success: false,
      deleted: false,
    }),
    [CATEGORIES_CLEAR_DATA_LOGOUT]: () => ({
      ...initialState,
    }),
  },
  initialState
);
