import React, { useEffect } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import Loader from 'components/Loader';
import * as yup from 'yup';
import paths from 'pages/Router/paths';
import SettingsForm from 'components/SettingsForm';
import SynonymsForm from 'components/SynonymsForm';
import OpeningHoursForm from 'components/OpeningHoursForm';
import { fetchSettings, modifySettings } from 'state/actions/settings';
import { useFormatMessage } from 'hooks';

const Settings = ({ onParamsChange }) => {
  const { settingsData } = useSelector(
    (state) => ({
      settingsData: state.settings.data,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const schema = yup.object().shape({
    merchantID: yup.string().required(),
    publicKey: yup.string().required(),
    privateKey: yup.string().required(),
    kringlanSSN: yup.string().required(),
    orderCancelledDelay: yup.number().positive().required(),
    giftcardEmail: yup.string().email().required(),
    giftcardPassword: yup.string().required(),
    giftcardToken: yup.string().required(),
    giftcardHoldInterval: yup.number().positive().required(),
    pickupOrderReminderDelay: yup.number().positive(),
    serviceFee: yup.number().positive(),
    paymentFee: yup.number().positive(),
    shippingFee: yup.number().positive(),
  });

  useEffect(() => {
    dispatch(fetchSettings());
  }, [dispatch]);

  const onSubmitHandler = (value) => {
    const updatedSettings = {
      ...value
    };

    dispatch(modifySettings(updatedSettings));
  };

  const colorsGroupingLabel = useFormatMessage('Settings.colorsGrouping');
  const sizesGroupingLabel = useFormatMessage('Settings.sizesGrouping');

  const titleSection = useFormatMessage('Settings.title');

  useEffect(() => {
    if (onParamsChange) {
      onParamsChange({ title: titleSection });
    }
  }, []);

  return (
    <>
      <section className="hero is-hero-bar">
        <div className="hero-body">
          <Link to={paths.COLORS_GROUPING} className="button has-icon">
            <span className="icon">
              <i className="mdi mdi-format-list-bulleted"/>
            </span>
            {colorsGroupingLabel}
          </Link>

          <Link to={paths.SIZES_GROUPING} className="button has-icon ml-2">
            <span className="icon">
              <i className="mdi mdi-format-list-bulleted"/>
            </span>
            {sizesGroupingLabel}
          </Link>
        </div>
      </section>
      <section className="section is-main-section">
        {!settingsData ? (
          <Loader />
        ) : (
          <SettingsForm
            settings={settingsData}
            onSubmitHandler={onSubmitHandler}
            schema={schema}
          />
        )}
      </section>
      <section className="section is-main-section">
        <SynonymsForm />
      </section>
      <section className="section is-main-section">
        {!settingsData ? (
          <Loader />
        ) : (
          <OpeningHoursForm
            settings={settingsData}
          />
        )}
      </section>
    </>
  );
};

export default Settings;